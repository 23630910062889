import { useTranslation } from "react-i18next";
import ButtonOLD from "../../components/buttons/ButtonOLD";
import Popup from "../../hoc/Popup";
import "./style.scss";

type Props = {
    showPopup: boolean;
    onClose: () => void;
};

function TourAccessPopup(props: Props) {
    const { t } = useTranslation();

    return (
        <Popup showPopup={props.showPopup} onClose={props.onClose} width="40vw">
            <div className="tour-access-popup">
                <h2 className="text-sm">{t("popup.tourAccess.title")}</h2>
                <p className="text-xs content-text">
                    {t("popup.tourAccess.description")}
                </p>
                <p className="text-xs">{t("popup.tourAccess.getInTouch")}</p>
                <div className="links">
                    <a href="tel:010-880 07 84">
                        <ButtonOLD
                            label={t("popup.tourAccess.call")}
                            color="secondary"
                            short
                            width="150px"
                        />
                    </a>
                    <a href="mailto:order@alrik.com">
                        <ButtonOLD
                            label={t("popup.tourAccess.email")}
                            color="secondary"
                            short
                            width="150px"
                        />
                    </a>
                </div>
            </div>
        </Popup>
    );
}

export default TourAccessPopup;
