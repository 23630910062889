import { useQuery } from "@tanstack/react-query";
import { getPublicOrder } from "../../api/order";

function usePublicOrder(publicOrderId: string) {
    const {
        data: publicOrder,
        status,
        refetch,
        isFetching,
    } = useQuery({
        queryKey: ["public-order", publicOrderId],
        queryFn: async ({ queryKey: [, publicOrderId] }) => {
            const { data } = await getPublicOrder(publicOrderId!);
            return data;
        },
        enabled: !!publicOrderId,
    });

    switch (status) {
        case "success":
            return {
                publicOrder,
                status,
                refetch,
                isFetching,
            };
        case "pending":
            return {
                publicOrder,
                status,
                refetch,
                isFetching,
            };
        case "error":
            return {
                publicOrder,
                status,
                refetch,
                isFetching,
            };
    }
}

export default usePublicOrder;
