import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, isAxiosError } from "axios";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { addDriverToLocations, inviteDriver } from "../../api/driver";
import ButtonOLD from "../../components/buttons/ButtonOLD";
import DropdownOLD from "../../components/inputs/DropdownOLD";
import InputOLD from "../../components/inputs/InputOLD";
import Popup from "../../hoc/Popup";
import useFuelTypes from "../../hooks/data/useFuleTypes";
import {
    addDriverToLocationForm,
    AddDriverToLocationForm,
    DriverForm,
    driverForm,
} from "../../schemas/form";
import { isEnvironment, onFormError } from "../../shared/utility/misc";
import { API_ERROR } from "../../shared/values/enums";
import "./style.scss";

type Props = {
    showPopup: boolean;
    onClose: () => void;
};

function AddDriverPopup(props: Props) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { fuleTypes, status: fuelTypesStatus } = useFuelTypes();

    const [isSettingUpNewDriver, setIsSettingUpNewDriver] = useState(false);

    const {
        handleSubmit: handleAddDriverSubmit,
        control: controlAddDriver,
        reset: resetAddDriver,
    } = useForm<AddDriverToLocationForm>({
        resolver: zodResolver(addDriverToLocationForm),
        defaultValues: {
            driverPhone: "",
        },
    });

    const { handleSubmit, control, reset, setValue } = useForm<DriverForm>({
        resolver: zodResolver(driverForm),
        defaultValues: {
            phone: "",
            hourlyCost: "",
            fuelTypeId: null,
            enabled: false,
            fuelConsumption: "",
            firstName: "",
            lastName: "",
        },
    });

    const {
        mutate: addDriverToLocationsHandler,
        isPending: isAddingDriverToLocations,
    } = useMutation({
        mutationFn: async (driverPhone: string) => {
            await addDriverToLocations(driverPhone);
        },
        onSuccess: () => {
            toast.success(t("successMessage.driverAdded"));
            queryClient.invalidateQueries({
                queryKey: ["drivers"],
                type: "all",
            });
            props.onClose();
        },
        onError: (error: AxiosError, driverPhone) => {
            console.log(error);

            if (error.response?.status === 404) {
                toast.success(t("successMessage.driverDosentExist"), {
                    duration: 7000,
                });
                setValue("phone", driverPhone);
                setIsSettingUpNewDriver(true);
                return;
            }

            toast.error(t("errorMessage.unknown"));
        },
    });

    const { mutate: inviteDriverHandler, isPending: isInvitingDriver } =
        useMutation({
            mutationFn: async (data: DriverForm) => {
                const formattedFuelConsumption =
                    data.fuelConsumption?.replace(",", ".") || "";
                const formattedHourlyCost =
                    data.hourlyCost?.replace(",", ".") || "";

                await inviteDriver({
                    ...data,
                    fuelConsumption: isNaN(+formattedFuelConsumption)
                        ? "0"
                        : formattedFuelConsumption,
                    hourlyCost: isNaN(+formattedHourlyCost)
                        ? "0"
                        : formattedHourlyCost,
                });
            },
            onSuccess: () => {
                toast.success(t("successMessages.driverAdded"));
                queryClient.invalidateQueries({
                    queryKey: ["drivers"],
                    type: "all",
                });
                props.onClose();
            },
            onError: (error) => {
                if (isAxiosError(error)) {
                    const axiosError = error as AxiosError<{ detail: string }>;
                    if (
                        axiosError.response?.data.detail ===
                        API_ERROR.DriverAlreadyExists
                    ) {
                        toast.error(t("errorMessage.driverAlreadyExists"));
                        return;
                    }
                }
                toast.error(t("errorMessage.unknown"));
            },
        });

    useEffect(() => {
        reset();
        resetAddDriver();
        setIsSettingUpNewDriver(false);
    }, [props.showPopup, reset, resetAddDriver]);

    return (
        <Popup
            showPopup={props.showPopup}
            onClose={props.onClose}
            title={t("popup.driver.title")}
        >
            <div className="add-driver-popup">
                {!isSettingUpNewDriver ? (
                    <>
                        <Controller
                            key="driver-phone"
                            name="driverPhone"
                            control={controlAddDriver}
                            render={({ field: { value, onChange } }) => (
                                <InputOLD
                                    type="tel"
                                    value={value}
                                    onChange={onChange}
                                    label={t("popup.driver.phoneLabel")}
                                    placeholder={t(
                                        "popup.driver.plusPhonePlaceholder"
                                    )}
                                />
                            )}
                        />

                        <ButtonOLD
                            label={t("popup.driver.addLabel")}
                            color="secondary"
                            onClick={handleAddDriverSubmit(
                                (data) =>
                                    addDriverToLocationsHandler(
                                        data.driverPhone
                                    ),
                                onFormError
                            )}
                            isLoading={isAddingDriverToLocations}
                            width="100%"
                        />
                    </>
                ) : (
                    <>
                        <Controller
                            key="phone"
                            name="phone"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <InputOLD
                                    type="tel"
                                    value={value}
                                    onChange={onChange}
                                    label={t("popup.driver.phoneLabel")}
                                    placeholder={t(
                                        "popup.driver.plusPhonePlaceholder"
                                    )}
                                />
                            )}
                        />
                        <Controller
                            name="firstName"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <InputOLD
                                    type="text"
                                    value={value}
                                    onChange={onChange}
                                    label={t("popup.driver.firstNameLabel")}
                                />
                            )}
                        />
                        <Controller
                            name="lastName"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <InputOLD
                                    type="text"
                                    value={value}
                                    onChange={onChange}
                                    label={t("popup.driver.lastNameLabel")}
                                />
                            )}
                        />
                        <Controller
                            name="hourlyCost"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <InputOLD
                                    type="text"
                                    value={value}
                                    onChange={onChange}
                                    label={t("popup.driver.hourlyCostLabel")}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="fuelTypeId"
                            render={({ field: { value, onChange } }) => (
                                <DropdownOLD
                                    value={value?.toString() || null}
                                    onSelect={(o) => onChange(+o.value)}
                                    label={t("popup.driver.fuleTypeLabel")}
                                    options={
                                        fuleTypes?.map((f) => ({
                                            value: f.id.toString(),
                                            label: f.name,
                                        })) || []
                                    }
                                    isLoading={fuelTypesStatus === "pending"}
                                    isSearchable
                                />
                            )}
                        />
                        <Controller
                            name="fuelConsumption"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <InputOLD
                                    type="text"
                                    value={value}
                                    onChange={onChange}
                                    label={t(
                                        "popup.driver.fuleConsumptionLabel"
                                    )}
                                />
                            )}
                        />
                        <ButtonOLD
                            label={t("popup.driver.submitLabel")}
                            color="secondary"
                            onClick={handleSubmit(
                                (data) => inviteDriverHandler(data),
                                onFormError
                            )}
                            isLoading={isInvitingDriver}
                        />
                    </>
                )}
            </div>
        </Popup>
    );
}

export default AddDriverPopup;
