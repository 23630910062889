import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Modal from "../../../hoc/Modal";
import { NavItem } from "../../../shared/types/internal";
import "./style.scss";

type Props = {
    to: string;
    label: string;
    subNavItems?: NavItem[];
    onClick?: (e: React.MouseEvent) => void;
};

function NavButton(props: Props) {
    const { pathname } = useLocation();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(false);
    }, [pathname]);

    return (
        <div className="nav-button-wrapper">
            {props.subNavItems && props.subNavItems.length > 0 ? (
                <Modal
                    buttonElement={(ref) => (
                        <button
                            type="button"
                            className="expandable-nav-button"
                            ref={ref}
                            onClick={() => setIsOpen((state) => !state)}
                            style={
                                pathname.includes(props.to)
                                    ? {
                                          color: "var(--color-neutral-900",
                                          fontWeight: 600,
                                      }
                                    : {}
                            }
                        >
                            <p className="text-sm">{props.label}</p>
                            <FontAwesomeIcon
                                icon={faChevronDown}
                                size="lg"
                                color="var(--color-neutral-400)"
                            />
                        </button>
                    )}
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    align="right"
                >
                    {props.subNavItems.map((subNav) => (
                        <NavLink
                            key={subNav.id}
                            to={subNav.path}
                            onClick={props.onClick}
                        >
                            {({ isActive }) => (
                                <button
                                    className="nav-button"
                                    type="button"
                                    style={
                                        isActive
                                            ? {
                                                  fontWeight: 600,
                                                  color: "var(--color-neutral-900)",
                                              }
                                            : {}
                                    }
                                >
                                    <p className="text-sm">{subNav.label}</p>
                                </button>
                            )}
                        </NavLink>
                    ))}
                </Modal>
            ) : (
                <NavLink to={props.to} onClick={props.onClick}>
                    {({ isActive }) => (
                        <button
                            type="button"
                            className="nav-button"
                            style={
                                isActive
                                    ? {
                                          fontWeight: 600,
                                          color: "var(--color-neutral-900)",
                                      }
                                    : {}
                            }
                        >
                            <p className="text-sm">{props.label}</p>
                        </button>
                    )}
                </NavLink>
            )}
        </div>
    );
}

export default NavButton;
