import { useQuery } from "@tanstack/react-query";
import { getFilenameUrl } from "../../api/tours";

function useFileUrl(filename: string) {
    const {
        data: fileData,
        status,
        refetch,
        isFetching,
    } = useQuery({
        queryKey: ["file-url", filename],
        queryFn: async ({ queryKey: [, filename] }) => {
            try {
                const res = await getFilenameUrl(filename);
                return res.data;
            } catch (error) {
                return null;
            }
        },
        enabled: !!filename,
        staleTime: 0,
    });

    switch (status) {
        case "success":
            return {
                fileData,
                status,
                refetch,
                isFetching,
            };
        case "pending":
            return {
                fileData,
                status,
                refetch,
                isFetching,
            };
        case "error":
            return {
                fileData,
                status,
                refetch,
                isFetching,
            };
    }
}

export default useFileUrl;
