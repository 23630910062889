import { addDays, isAfter, startOfTomorrow } from "date-fns";
import { CreateStopDraftData, StopDraft, StopDraftsTour } from "../types/api";
import { KeyNumber, OrderStatus } from "../types/internal";
import { STOP_DRAFT_TYPE } from "../values/enums";
import { dateToString, stringToDate } from "./date";

export function checkForGroupsAround({
    stop,
    stopAbove,
    stopBelow,
}: {
    stop?: StopDraft;
    stopAbove?: StopDraft;
    stopBelow?: StopDraft;
}) {
    if (!stop) {
        return {
            above: false,
            below: false,
        };
    }

    if (stop.motion_tools_stop_group === null) {
        return {
            above: false,
            below: false,
        };
    }

    return {
        above:
            stop.stop_type_id === stopAbove?.stop_type_id &&
            stop.motion_tools_stop_group === stopAbove?.motion_tools_stop_group,

        below:
            stop.stop_type_id === stopBelow?.stop_type_id &&
            stop.motion_tools_stop_group === stopBelow?.motion_tools_stop_group,
    };
}

export function getStopDraftStatus({
    stopDraft,
    orderStatus,
}: {
    stopDraft: StopDraft;
    orderStatus?: OrderStatus;
}) {
    if (!orderStatus) {
        return {
            showStatus: false,
            isStopCompleted: false,
            statusText: "",
            canStopBeMoved: true,
        };
    }

    const statusTextMap = {
        created: "orderHistory.statusCreated",
        verified: "orderHistory.statusVerified",
        "in-progress": "orderHistory.statusInProgress",
        "next-stop": "orderHistory.statusNextStop",
        unloading: "orderHistory.statusUnloading",
        fulfilled: "orderHistory.statusFulfilled",
        "at-pickup":
            stopDraft.stop_type_id === STOP_DRAFT_TYPE.Pickup
                ? "orderHistory.statusAtPickup"
                : "orderHistory.statusVerified",
    };

    const statusText = statusTextMap[orderStatus] || "";

    let showStatus = false;
    let isStopCompleted = false;
    let canStopBeMoved = true;

    const isPickupStop = stopDraft.stop_type_id === STOP_DRAFT_TYPE.Pickup;

    if (isPickupStop) {
        showStatus = ["at-pickup"].includes(orderStatus);
        isStopCompleted = [
            "in-progress",
            "next-stop",
            "unloading",
            "fulfilled",
        ].includes(orderStatus);
    } else {
        showStatus = ["in-progress", "next-stop", "unloading"].includes(
            orderStatus
        );
        isStopCompleted = ["fulfilled"].includes(orderStatus);
    }

    if (isPickupStop) {
        canStopBeMoved = !isStopCompleted && orderStatus !== "at-pickup";
    } else {
        canStopBeMoved = !isStopCompleted && orderStatus !== "unloading";
    }

    return {
        isStopCompleted,
        showStatus,
        statusText,
        canStopBeMoved,
    };
}

export function getStopStatus(stop: StopDraft) {
    const stopStatus = {
        isCompleted: false,
        hasBeenStarted: false,
    };

    if (stop.completed_at) {
        stopStatus.isCompleted = true;
        stopStatus.hasBeenStarted = true;
    }

    if (stop.stop_type_id === STOP_DRAFT_TYPE.Pickup) {
        stopStatus.hasBeenStarted = !!stop.order?.pickup_stop_started_at;
    } else {
        stopStatus.hasBeenStarted = !!stop.order?.dropoff_stop_started_at;
    }

    return stopStatus;
}

export function cardHoverHandler({
    groupId,
    columnId,
    columnSelector,
}: {
    groupId: number;
    columnId?: string;
    columnSelector?: string;
}) {
    const column = columnId
        ? document.querySelector(`[data-column-id="${columnId}"]`)
        : columnSelector
        ? document.querySelector(columnSelector)
        : null;

    if (!column) return;

    const cards = column.querySelectorAll<HTMLElement>(
        ".stop-draft-card, .stop-card"
    );

    for (let i = 0; i < cards.length; i++) {
        const card = cards[i];

        if (groupId === -1) {
            card.classList.remove("connected");
            card.classList.remove("not-connected");
            continue;
        }

        if (card.getAttribute("data-group-id") !== groupId.toString()) {
            card.classList.remove("connected");
            card.classList.add("not-connected");
        } else {
            card.classList.add("connected");
            card.classList.remove("not-connected");
        }
    }
}

export function generateDemoStopDrafts(addressOptions?: {
    from?: string[];
    to?: string[];
    region?: string;
}) {
    const today = new Date();
    const tomorrow = startOfTomorrow();
    const todayPlus2 = addDays(today, 2);
    const todayPlus3 = addDays(today, 3);

    let orders = [
        {
            order: 1,
            from: "Alrik Technologies, Storgatan, Stockholm",
            to: "Hornsgatan 45, Stockholm",
            orderNumber: 15342341,
            customerAtDropoff: "Alrik",
            date: dateToString(today),
            time: "12:00",
            cargoContent: "Ett paket. Parkera vid ingång B.",
            weight: 300,
            tag: "Express",
        },
        {
            order: 2,
            from: "Alrik Technologies, Storgatan, Stockholm",
            to: "Vintergatan 21, 172 69 Sundbyberg, Sverige",
            orderNumber: 12332423,
            customerAtDropoff: "Brommabyggaren",
            date: dateToString(today),
            time: "14:00",
            weight: 2000,
            tag: "Under dagen",
            cargoContent: "En pall. Taklyft.",
        },
        {
            order: 3,
            from: "Alrik Technologies, Storgatan, Stockholm",
            to: "Midgårdsvägen 9, 136 38 Handen, Sverige",
            orderNumber: 559091023,
            customerAtDropoff: "Favoritkunden",
            date: dateToString(today),
            time: "13:00",
            weight: 1500,
            tag: "Under dagen",
            cargoContent: "Två paket och en pall.",
        },
        {
            order: 4,
            from: "Alrik Technologies, Storgatan, Stockholm",
            to: "Vasagatan 13, 761 31 Norrtälje, Sverige",
            orderNumber: 38839202,
            customerAtDropoff: "Storbyggaren",
            date: dateToString(today),
            time: "15:00",
            weight: 760,
            tag: "Morgonpass",
            cargoContent: "Två pallar.",
        },
        {
            order: 5,
            from: "Alrik Technologies, Storgatan, Stockholm",
            to: "Tingvallavägen 28C, 195 35 Märsta, Sverige",
            orderNumber: 15342341,
            customerAtDropoff: "Alrik",
            date: dateToString(tomorrow),
            time: "12:00",
            weight: 3000,
            tag: "Express",
            cargoContent: "Ett paket. Parkera vid ingång B.",
        },
        {
            order: 6,
            from: "Alrik Technologies, Storgatan, Stockholm",
            to: "Märstavägen 20, 193 40 Sigtuna, Sverige",
            orderNumber: 12332423,
            customerAtDropoff: "Brommabyggaren",
            date: dateToString(tomorrow),
            time: "14:00",
            weight: 400,
            tag: "Under dagen",
            cargoContent: "En pall. Taklyft.",
        },
        {
            order: 7,
            from: "Alrik Technologies, Storgatan, Stockholm",
            to: "Vasavägen 250A, 192 69 Sollentuna, Sverige",
            orderNumber: 559091023,
            customerAtDropoff: "Favoritkunden",
            date: dateToString(tomorrow),
            time: "13:00",
            weight: 300,
            tag: "Under dagen",
            cargoContent: "Två paket och en pall.",
        },
        {
            order: 8,
            from: "Alrik Technologies, Storgatan, Stockholm",
            to: "Snösätravägen, 124 59 Bandhagen, Sverige",
            orderNumber: 559091023,
            customerAtDropoff: "Alrik",
            date: dateToString(todayPlus2),
            time: "15:00",
            weight: 2000,
            tag: "Morgonpass",
            cargoContent: "Två pallar.",
        },
        {
            order: 9,
            from: "Alrik Technologies, Storgatan, Stockholm",
            to: "Dalgränd 6, 135 40 Tyresö, Sverige",
            orderNumber: 38839202,
            customerAtDropoff: "Brommabyggaren",
            date: dateToString(todayPlus2),
            time: "12:00",
            weight: 1500,
            tag: "Express",
            cargoContent: "Ett paket. Parkera vid ingång B.",
        },
        {
            order: 10,
            from: "Alrik Technologies, Storgatan, Stockholm",
            to: "Buskuddsvägen 16, 185 95 Vaxholm, Sverige",
            orderNumber: 15342341,
            customerAtDropoff: "Favoritkunden",
            date: dateToString(todayPlus2),
            time: "14:00",
            weight: 760,
            tag: "Under dagen",
            cargoContent: "En pall. Taklyft.",
        },
        {
            order: 11,
            from: "Alrik Technologies, Storgatan, Stockholm",
            to: "Vikingshillsvägen 35, 132 37 Saltsjö-boo, Sverige",
            orderNumber: 12332423,
            customerAtDropoff: "Storbyggaren",
            date: dateToString(todayPlus2),
            time: "13:00",
            weight: 3000,
            tag: "Under dagen",
            cargoContent: "Två paket och en pall.",
        },
        {
            order: 12,
            from: "Alrik Technologies, Storgatan, Stockholm",
            to: "Storsvängen 151, 129 44 Hägersten, Sverige",
            orderNumber: 559091023,
            customerAtDropoff: "Alrik",
            date: dateToString(todayPlus2),
            time: "15:00",
            weight: 400,
            tag: "Morgonpass",
            cargoContent: "Två pallar.",
        },
        {
            order: 13,
            from: "Alrik Technologies, Storgatan, Stockholm",
            to: "Tomtbergavägen 115, 145 75 Norsborg, Sverige",
            orderNumber: 559091023,
            customerAtDropoff: "Brommabyggaren",
            date: dateToString(todayPlus2),
            time: "15:00",
            weight: 300,
            tag: "Express",
            cargoContent: "Två pallar.",
        },
        {
            order: 14,
            from: "Alrik Technologies, Storgatan, Stockholm",
            to: "Gårdsfogdevägen 18A, 168 67 Bromma, Sverige",
            orderNumber: 559091023,
            customerAtDropoff: "Favoritkunden",
            date: dateToString(todayPlus3),
            time: "12:00",
            weight: 2000,
            tag: "Under dagen",
            cargoContent: "Ett paket. Parkera vid ingång B.",
        },
        {
            order: 15,
            from: "Alrik Technologies, Storgatan, Stockholm",
            to: "Äventyrsparken, Kalvhagsvägen 1, 181 31 Lidingö, Sverige",
            orderNumber: 38839202,
            customerAtDropoff: "Storbyggaren",
            date: dateToString(todayPlus3),
            time: "14:00",
            weight: 1500,
            tag: "Under dagen",
            cargoContent: "En pall. Taklyft.",
        },
        {
            order: 16,
            from: "Alrik Technologies, Storgatan, Stockholm",
            to: "Vasavägen 37, 181 42 Lidingö, Sverige",
            orderNumber: 15342341,
            customerAtDropoff: "Alrik",
            date: dateToString(todayPlus3),
            time: "13:00",
            weight: 760,
            tag: "Morgonpass",
            cargoContent: "Två paket och en pall.",
        },
        {
            order: 17,
            from: "Alrik Technologies, Storgatan, Stockholm",
            to: "Åkargränd 3, 149 30 Nynäshamn, Sverige",
            orderNumber: 12332423,
            customerAtDropoff: "Brommabyggaren",
            date: dateToString(todayPlus3),
            time: "15:00",
            weight: 3000,
            tag: "Express",
            cargoContent: "Två pallar.",
        },
        {
            order: 18,
            from: "Alrik Technologies, Storgatan, Stockholm",
            to: "Nybrogatan 79B, 114 41 Stockholm, Sverige",
            orderNumber: 559091023,
            customerAtDropoff: "Favoritkunden",
            date: dateToString(todayPlus3),
            time: "15:00",
            weight: 400,
            tag: "Under dagen",
            cargoContent: "Två pallar.",
        },
    ];

    if (addressOptions?.region === "GB") {
        orders = [
            {
                order: 1,
                from: "45 Romford Road, London E15 4LY, UK",
                to: "Carburton St, London W1W 5EE, United Kingdom",
                orderNumber: 15342341,
                customerAtDropoff: "Alrik",
                date: dateToString(today),
                time: "12:00",
                weight: 300,
                tag: "Express",
                cargoContent: "Two pallets of plaster.",
            },
            {
                order: 2,
                from: "45 Romford Road, London E15 4LY, UK",
                to: "51 High St N, London E6 1HZ, United Kingdom",
                orderNumber: 12332423,
                customerAtDropoff: "Brommabyggaren",
                date: dateToString(today),
                time: "14:00",
                weight: 2000,
                tag: "During the day",
                cargoContent: "One package of wood.",
            },
            {
                order: 3,
                from: "45 Romford Road, London E15 4LY, UK",
                to: "Whalebone Ln N, Romford RM6 6SB, United Kingdom",
                orderNumber: 55909102,
                customerAtDropoff: "Favoritkunden",
                date: dateToString(today),
                time: "13:00",
                weight: 1500,
                tag: "Morning",
                cargoContent: "Two packages of glue joint.",
            },
            {
                order: 4,
                from: "45 Romford Road, London E15 4LY, UK",
                to: "18 Heathway, Woodford, Woodford Green IG8 7RG, United Kingdom",
                orderNumber: 38839202,
                customerAtDropoff: "Storbyggaren",
                date: dateToString(today),
                time: "15:00",
                weight: 760,
                tag: "Afternoon",
                cargoContent: "Two pallets of plaster.",
            },
            {
                order: 5,
                from: "Southey Rd, London SW19 1NU, United Kingdom",
                to: "45 Romford Road, London E15 4LY, UK",
                orderNumber: 15342341,
                customerAtDropoff: "Alrik",
                date: dateToString(today),
                time: "12:00",
                weight: 3000,
                tag: "Morning",
                cargoContent: "One package of wood.",
            },
            {
                order: 6,
                from: "5 Stable St, London N1C 4AB, United Kingdom",
                to: "45 Romford Road, London E15 4LY, UK",
                orderNumber: 12332423,
                customerAtDropoff: "Brommabyggaren",
                date: dateToString(today),
                time: "14:00",
                weight: 400,
                tag: "During the day",
                cargoContent: "Two packages of glue joint.",
            },
            {
                order: 7,
                from: "194 Southgate Rd, London N1 3HT, United Kingdom",
                to: "26 Blackhorse Rd, London E17 7BE, United Kingdom",
                orderNumber: 55909104,
                customerAtDropoff: "Favoritkunden",
                date: dateToString(today),
                time: "13:00",
                weight: 300,
                tag: "Morning",
                cargoContent: "Two pallets of plaster.",
            },
            {
                order: 8,
                from: "Beigel Bake Brick Lane Bakery, Brick Lane, London E1 6SB, UK",
                to: "Severndroog Castle, London SE18 3RT, UK",
                orderNumber: 15342341,
                customerAtDropoff: "Alrik",
                date: dateToString(today),
                time: "15:00",
                weight: 2000,
                tag: "Afternoon",
                cargoContent: "One package of wood.",
            },
            {
                order: 9,
                from: "1C Portland Pl, London W1B 1JA, United Kingdom",
                to: "119 Consort Rd, London SE15 3RU, United Kingdom",
                orderNumber: 12332423,
                customerAtDropoff: "Brommabyggaren",
                date: dateToString(today),
                time: "12:00",
                weight: 1500,
                tag: "Morning",
                cargoContent: "Two packages of glue joint.",
            },
            {
                order: 10,
                from: "45 Romford Road, London E15 4LY, UK",
                to: "Hertford House, Manchester Square, London W1U 3BN, United Kingdom",
                orderNumber: 55909104,
                customerAtDropoff: "Favoritkunden",
                date: dateToString(today),
                time: "14:00",
                weight: 760,
                tag: "During the day",
                cargoContent: "Two pallets of plaster.",
            },
            {
                order: 11,
                from: "45 Romford Road, London E15 4LY, UK",
                to: "72 Honor Oak Park, London SE23 1DY, United Kingdom",
                orderNumber: 15342341,
                customerAtDropoff: "Alrik",
                date: dateToString(tomorrow),
                time: "13:00",
                weight: 3000,
                tag: "Morning",
                cargoContent: "One package of wood.",
            },
            {
                order: 12,
                from: "45 Romford Road, London E15 4LY, UK",
                to: "Warwick Square, Pimlico, London SW1V 2AD, United Kingdom",
                orderNumber: 12332423,
                customerAtDropoff: "Brommabyggaren",
                date: dateToString(tomorrow),
                time: "15:00",
                weight: 400,
                tag: "Afternoon",
                cargoContent: "Two packages of glue joint.",
            },
            {
                order: 13,
                from: "52 Cambridge St, Pimlico, London SW1V 4JD, United Kingdom",
                to: "45 Romford Road, London E15 4LY, UK",
                orderNumber: 55909104,
                customerAtDropoff: "Favoritkunden",
                date: dateToString(tomorrow),
                time: "13:00",
                weight: 300,
                tag: "Morning",
                cargoContent: "Two pallets of plaster.",
            },
            {
                order: 14,
                from: "45 Romford Road, London E15 4LY, UK",
                to: "Drayton Park, London N5 1NT, United Kingdom",
                orderNumber: 55909102,
                customerAtDropoff: "Storbyggaren",
                date: dateToString(todayPlus2),
                time: "15:00",
                weight: 2000,
                tag: "During the day",
                cargoContent: "One package of wood.",
            },
            {
                order: 15,
                from: "45 Romford Road, London E15 4LY, UK",
                to: "510C St Stephen's Rd, Bow, London E3 5ES, United Kingdom",
                orderNumber: 38839202,
                customerAtDropoff: "Alrik",
                date: dateToString(todayPlus2),
                time: "12:00",
                weight: 1500,
                tag: "Morning",
                cargoContent: "Two packages of glue joint.",
            },
            {
                order: 16,
                from: "45 Romford Road, London E15 4LY, UK",
                to: "51 Grove Rd., Bow, London E3 4PE, United Kingdom",
                orderNumber: 15342341,
                customerAtDropoff: "Brommabyggaren",
                date: dateToString(todayPlus2),
                time: "14:00",
                weight: 760,
                tag: "Afternoon",
                cargoContent: "Two pallets of plaster.",
            },
            {
                order: 17,
                from: "45 Romford Road, London E15 4LY, UK",
                to: "185 Lower Rd, London SE16 2LW, United Kingdom",
                orderNumber: 12332423,
                customerAtDropoff: "Favoritkunden",
                date: dateToString(todayPlus2),
                time: "13:00",
                weight: 3000,
                tag: "Morning",
                cargoContent: "One package of wood.",
            },
            {
                order: 18,
                from: "45 Romford Road, London E15 4LY, UK",
                to: "9 Priory Park, Blackheath, London SE3 9UY, United Kingdom",
                orderNumber: 55909102,
                customerAtDropoff: "Storbyggaren",
                date: dateToString(todayPlus2),
                time: "15:00",
                weight: 400,
                tag: "During the day",
                cargoContent: "Two packages of glue joint.",
            },
        ];
    }

    if (addressOptions?.region === "DK") {
        orders = [
            {
                order: 1,
                from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
                to: "Copenhagen Airport (CPH), Lufthavnsboulevarden 6, 2770 Kastrup, Denmark",
                orderNumber: 15342341,
                customerAtDropoff: "Alrik",
                date: dateToString(today),
                time: "12:00",
                weight: 300,
                tag: "Express",
                cargoContent: "Two pallets of plaster.",
            },
            {
                order: 2,
                from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
                to: "Copenhagen Central Station, Bernstorffsgade, København, Danmark",
                orderNumber: 12332423,
                customerAtDropoff: "Brommabyggaren",
                date: dateToString(today),
                time: "14:00",
                weight: 2000,
                tag: "During the day",
                cargoContent: "One package of wood.",
            },
            {
                order: 3,
                from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
                to: "Whalebone Ln N, Romford RM6 6SB, United Kingdom",
                orderNumber: 55909102,
                customerAtDropoff: "Favoritkunden",
                date: dateToString(today),
                time: "13:00",
                weight: 1500,
                tag: "Morning",
                cargoContent: "Two packages of glue joint.",
            },
            {
                order: 4,
                from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
                to: "Alhambravej 11, 1826 Frederiksberg, Denmark",
                orderNumber: 38839202,
                customerAtDropoff: "Storbyggaren",
                date: dateToString(today),
                time: "15:00",
                weight: 760,
                tag: "Afternoon",
                cargoContent: "Two pallets of plaster.",
            },
            {
                order: 5,
                from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
                to: "Marienborg Alle 60, 2860 Søborg, Denmark",
                orderNumber: 15342341,
                customerAtDropoff: "Alrik",
                date: dateToString(today),
                time: "12:00",
                weight: 3000,
                tag: "Morning",
                cargoContent: "One package of wood.",
            },
            {
                order: 6,
                from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
                to: "Roskildevej 173, 2620 Albertslund, Denmark",
                orderNumber: 12332423,
                customerAtDropoff: "Brommabyggaren",
                date: dateToString(today),
                time: "14:00",
                weight: 400,
                tag: "During the day",
                cargoContent: "Two packages of glue joint.",
            },
            {
                order: 7,
                from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
                to: "Park Allé 87, 2605 Brøndby, Denmark",
                orderNumber: 55909104,
                customerAtDropoff: "Favoritkunden",
                date: dateToString(today),
                time: "13:00",
                weight: 300,
                tag: "Morning",
                cargoContent: "Two pallets of plaster.",
            },
            {
                order: 8,
                from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
                to: "Catherine Boothsvej 73, 2650 Hvidovre, Denmark",
                orderNumber: 15342341,
                customerAtDropoff: "Alrik",
                date: dateToString(today),
                time: "15:00",
                weight: 2000,
                tag: "Afternoon",
                cargoContent: "One package of wood.",
            },
            {
                order: 9,
                from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
                to: "Flensborggade 7, 1669 København, Denmark",
                orderNumber: 12332423,
                customerAtDropoff: "Brommabyggaren",
                date: dateToString(today),
                time: "12:00",
                weight: 1500,
                tag: "Morning",
                cargoContent: "Two packages of glue joint.",
            },
            {
                order: 10,
                from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
                to: "Perikumhaven 69A, 2730 Herlev, Denmark",
                orderNumber: 55909104,
                customerAtDropoff: "Favoritkunden",
                date: dateToString(today),
                time: "14:00",
                weight: 760,
                tag: "During the day",
                cargoContent: "Two pallets of plaster.",
            },
            {
                order: 11,
                from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
                to: "Ejbydalsvej 15, 2600 Glostrup, Denmark",
                orderNumber: 15342341,
                customerAtDropoff: "Storbyggaren",
                date: dateToString(tomorrow),
                time: "13:00",
                weight: 3000,
                tag: "Morning",
                cargoContent: "One package of wood.",
            },
            {
                order: 12,
                from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
                to: "Arne Jacobsens Allé 22, 2300 København, Denmark",
                orderNumber: 12332423,
                customerAtDropoff: "Alrik",
                date: dateToString(tomorrow),
                time: "15:00",
                weight: 400,
                tag: "Afternoon",
                cargoContent: "Two packages of glue joint.",
            },
            {
                order: 13,
                from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
                to: "Markvænget 12, 2791 Dragør, Denmark",
                orderNumber: 55909104,
                customerAtDropoff: "Brommabyggaren",
                date: dateToString(tomorrow),
                time: "13:00",
                weight: 300,
                tag: "Morning",
                cargoContent: "Two pallets of plaster.",
            },
            {
                order: 14,
                from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
                to: "Drayton Park, London N5 1NT, United Kingdom",
                orderNumber: 55909102,
                customerAtDropoff: "Favoritkunden",
                date: dateToString(todayPlus2),
                time: "15:00",
                weight: 2000,
                tag: "During the day",
                cargoContent: "One package of wood.",
            },
            {
                order: 15,
                from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
                to: "Bagsværd Hovedgade 228, 2880 Bagsværd, Denmark",
                orderNumber: 38839202,
                customerAtDropoff: "Storbyggaren",
                date: dateToString(todayPlus2),
                time: "12:00",
                weight: 1500,
                tag: "Morning",
                cargoContent: "Two packages of glue joint.",
            },
            {
                order: 16,
                from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
                to: "Sekskanten A 9, 2630 Taastrup, Denmark",
                orderNumber: 15342341,
                customerAtDropoff: "Alrik",
                date: dateToString(todayPlus2),
                time: "14:00",
                weight: 760,
                tag: "Afternoon",
                cargoContent: "Two pallets of plaster.",
            },
            {
                order: 17,
                from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
                to: "Magnoliavej 28, 2600 Glostrup, Denmark",
                orderNumber: 12332423,
                customerAtDropoff: "Brommabyggaren",
                date: dateToString(todayPlus2),
                time: "13:00",
                weight: 3000,
                tag: "Morning",
                cargoContent: "One package of wood.",
            },
            {
                order: 18,
                from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
                to: "Høyrups Alle 19, 2900 Hellerup, Denmark",
                orderNumber: 55909102,
                customerAtDropoff: "Favoritkunden",
                date: dateToString(todayPlus2),
                time: "15:00",
                weight: 400,
                tag: "During the day",
                cargoContent: "Two packages of glue joint.",
            },
        ];
    }

    const result: {
        pickup: CreateStopDraftData;
        dropoff: CreateStopDraftData;
    }[] = [];

    for (let i = 0; i < orders.length; i++) {
        const order = orders[i];

        const randomFrom =
            addressOptions?.from?.[
                Math.floor(Math.random() * addressOptions.from.length)
            ];
        const randomTo =
            addressOptions?.to?.[
                Math.floor(Math.random() * addressOptions.to.length)
            ];

        const pickup: CreateStopDraftData = {
            to_location: randomFrom || order.from,
            phone: "",
            cargo_content: order.cargoContent,
            estimated_offload_time: "15",
            order_number: order.orderNumber.toString(),
            stop_type_id: STOP_DRAFT_TYPE.Pickup,
            time_tooltip: order.time,
            date_tooltip: order.date,
            weight_kg: order.weight,
            tag: order.tag,
        };

        const dropoff: CreateStopDraftData = {
            to_location: randomTo || order.to,
            phone: "",
            cargo_content: order.cargoContent,
            estimated_offload_time: "15",
            order_number: order.orderNumber.toString(),
            stop_type_id: STOP_DRAFT_TYPE.Dropoff,
            time_tooltip: order.time,
            date_tooltip: order.date,
            weight_kg: order.weight,
            tag: order.tag,
        };

        result.push({
            pickup,
            dropoff,
        });
    }

    return result;
}

export function getTourStatus(tour: StopDraftsTour) {
    const hasTourBeenStarted = tour.stops.some(
        (stop) => !!stop.order?.pickup_stop_started_at
    );

    const isUndispatched =
        tour.dispatched_at === null && tour.updated_at !== null;

    const hasUndispatchedChanges =
        tour.dispatched_at && tour.updated_at
            ? isAfter(
                  stringToDate(tour.updated_at)!,
                  stringToDate(tour.dispatched_at)!
              )
            : false;

    const hideEta =
        hasUndispatchedChanges || isUndispatched || !hasTourBeenStarted;

    return {
        hasTourBeenStarted,
        isUndispatched,
        hasUndispatchedChanges,
        hideEta,
    };
}

export function getStopListRealtiveEtaPerIdInMinutes(stops: StopDraft[]) {
    const durationMap: KeyNumber<number> = {};

    for (let i = 0; i < stops.length; i++) {
        const previousStop: StopDraft | null = stops[i - 1] || null;

        if (!previousStop) {
            durationMap[stops[i].id] = 0;
            continue;
        }

        const previousRealtiveEta = durationMap[previousStop.id];

        const estimatedTimeToNextStop =
            previousStop?.estimated_time_to_next_stop || 0;
        let offloadTime = 0;

        if (previousStop.motion_tools_stop_group) {
            if (
                previousStop?.motion_tools_stop_group ===
                previousStop.motion_tools_stop_group
            ) {
                offloadTime = 0;
            } else {
                const allGroupOffloadTimes = stops
                    .filter(
                        (s) =>
                            s.motion_tools_stop_group ===
                            previousStop.motion_tools_stop_group
                    )
                    .map((s) => s.estimated_offload_time);

                const highestOffloadTime = Math.max(...allGroupOffloadTimes);

                offloadTime = highestOffloadTime;
            }
        } else {
            offloadTime = previousStop.estimated_offload_time;
        }

        if (
            offloadTime &&
            previousStop.stop_type_id === STOP_DRAFT_TYPE.Pickup
        ) {
            offloadTime = offloadTime / 2;
        }

        durationMap[stops[i].id] =
            previousRealtiveEta + estimatedTimeToNextStop + offloadTime;
    }

    return durationMap;
}

export function getStopListOffloadDurationInMinutes(stops: StopDraft[]) {
    return stops.reduce((acc, stop, index, stops) => {
        const previousStop = stops[index - 1];

        let offloadTime = 0;

        if (stop.motion_tools_stop_group) {
            if (
                previousStop?.motion_tools_stop_group ===
                stop.motion_tools_stop_group
            ) {
                offloadTime = 0;
            } else {
                const allGroupOffloadTimes = stops
                    .filter(
                        (s) =>
                            s.motion_tools_stop_group ===
                            stop.motion_tools_stop_group
                    )
                    .map((s) => s.estimated_offload_time);

                const highestOffloadTime = Math.max(...allGroupOffloadTimes);

                offloadTime = highestOffloadTime;
            }
        } else {
            offloadTime = stop.estimated_offload_time;
        }

        if (offloadTime && stop.stop_type_id === STOP_DRAFT_TYPE.Pickup) {
            offloadTime = offloadTime / 2;
        }

        return acc + offloadTime;
    }, 0);
}

export function getStopListDrivingDurationInMinutes(stops: StopDraft[]) {
    return stops.reduce((acc, stop) => {
        const estimatedTimeToNextStop = stop.estimated_time_to_next_stop || 0;

        return acc + estimatedTimeToNextStop;
    }, 0);
}

export function getStopListDurationInMinutes(stops: StopDraft[]) {
    const totalOffloadTime = getStopListOffloadDurationInMinutes(stops);
    const totalDrivingTime = getStopListDrivingDurationInMinutes(stops);

    return totalOffloadTime + totalDrivingTime;
}

export function getRunningWeight(stops: StopDraft[]) {
    const weights: number[] = [];

    if (stops.every((stop) => !stop.weight_kg)) {
        return {
            peakWeight: 0,
            runningWeights: [],
        };
    }

    for (let i = 0; i < stops.length; i++) {
        const stop = stops[i];

        const currentWeight = weights[i - 1] || 0;

        if (stop.stop_type_id === STOP_DRAFT_TYPE.Pickup) {
            weights.push(currentWeight + (stop.weight_kg || 0));
        } else {
            weights.push(currentWeight - (stop.weight_kg || 0));
        }
    }

    return {
        peakWeight: Math.max(...weights),
        runningWeights: weights,
    };
}

export function getCombinedStops(
    stops: StopDraft[],
    options: {
        noExternalStops?: boolean;
        filterDate?: Date;
        noMovedAcceptedStops?: boolean;
    }
) {
    const combinedStops: {
        pickup: StopDraft;
        dropoff: StopDraft;
    }[] = [];

    let pickups = stops.filter(
        (stop) => stop.stop_type_id === STOP_DRAFT_TYPE.Pickup
    );

    if (options.noExternalStops) {
        pickups = pickups.filter((pickup) => !pickup.external);
    }

    if (options.noMovedAcceptedStops) {
        pickups = pickups.filter((pickup) =>
            pickup.origin_location ? !!pickup.move_accepted_at : true
        );
    }

    if (options.filterDate) {
        pickups = pickups.filter((pickup) =>
            pickup.date_tooltip
                ? pickup.date_tooltip === dateToString(options.filterDate)
                : true
        );
    }

    for (let i = 0; i < pickups.length; i++) {
        const pickup = pickups[i];

        const dropoff = stops.find(
            (stop) =>
                stop.group_id === pickup.group_id &&
                stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff
        );

        if (!dropoff) continue;

        combinedStops.push({
            pickup,
            dropoff,
        });
    }

    return combinedStops;
}

export function getStopLocationText(stop: StopDraft) {
    if (stop.city && stop.zip_code) return stop.city + ", " + stop.zip_code;
    if (stop.city) return stop.city;
    if (stop.zip_code) return stop.zip_code;

    return stop.to_location;
}
