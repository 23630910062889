import { DraggableProvided } from "@hello-pangea/dnd";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DotsModal, { DotsModalContent } from "../../../components/UI/DotsModal";
import IconOLD from "../../../components/UI/IconOLD";
import DotsButton from "../../../components/buttons/DotsButton";
import IconButton from "../../../components/buttons/IconButton";
import TextButtonOLD from "../../../components/buttons/TextButtonOLD";
import ColorPicker from "../../../components/inputs/ColorPicker";
import { StopDraft } from "../../../shared/types/api";
import "./style.scss";
import useHasActiveEllipsis from "../../../hooks/functionality/useHasActiveEllipsis";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../shared/types/redux";
import StatusCircle from "../../../components/UI/StatusCircle";
import { getOrderStatus } from "../../../shared/utility/order";
import { getStopDraftStatus } from "../../../shared/utility/stop-draft";
import { formatDateString } from "../../../shared/utility/date";
import { isEnvironment } from "../../../shared/utility/misc";
import Spinner from "../../../components/UI/Spinner";

export type CombinedStopCardProps = {
    // Drag and Drop
    provided?: DraggableProvided;

    //Core data
    stops: {
        pickup: StopDraft;
        dropoff: StopDraft;
    };

    // Actions
    onClick?: () => void;
    onHover?: (groupId: number) => void;
    modalContent?: (DotsModalContent | null)[];
    shareActions?: {
        onAccept: () => void;
        onDecline: () => void;
        isLoading?: boolean;
    };
    onColorChange?: (stop: StopDraft, color: string | null) => void;
    isPickupChangingColor?: boolean;
    isDropoffChangingColor?: boolean;
    isLoading?: boolean;
};

function CombinedStopCard(props: CombinedStopCardProps) {
    const { t } = useTranslation();
    const { settings } = useSelector((state: ReduxState) => state.fleetPlanner);
    const { user } = useSelector((state: ReduxState) => state.auth);

    const cargoContentText = useRef<HTMLParagraphElement | null>(null);
    const { hasActiveEllipsis } = useHasActiveEllipsis(
        cargoContentText,
        props.stops.pickup.cargo_content
    );

    const [isCargoContentExpanded, setIsCargoContentExpanded] = useState(false);
    const [isRevisedCargoContentExpanded, setIsRevisedCargoContentExpanded] =
        useState(false);

    const orderStatus = props.stops.pickup.order
        ? getOrderStatus(props.stops.pickup.order)
        : undefined;

    const { showStatus, isStopCompleted, statusText } = useMemo(
        () =>
            getStopDraftStatus({
                stopDraft: props.stops.dropoff,
                orderStatus,
            }),
        [orderStatus, props.stops.dropoff]
    );

    return (
        <div
            className="combined-stop-card"
            onClick={props.onClick}
            ref={props.provided?.innerRef}
            onMouseEnter={() => props.onHover?.(props.stops.pickup.group_id)}
            onMouseLeave={() => props.onHover?.(-1)}
            {...props.provided?.draggableProps}
            {...props.provided?.dragHandleProps}
        >
            {props.isLoading && (
                <div className="loading-wrapper">
                    <Spinner />
                </div>
            )}
            <div className="top">
                <div className="order-number">
                    <p
                        className="text-xs"
                        title={props.stops.pickup.order_number}
                    >
                        {props.stops.pickup.order_number}
                    </p>
                    {props.modalContent && (
                        <DotsModal
                            content={props.modalContent}
                            dotsStyle={{ backgroundColor: "transparent" }}
                            dotsColor="var(--text-color-light)"
                        />
                    )}
                </div>
                {props.stops.pickup.date_tooltip && (
                    <p
                        className="text-xs"
                        style={{
                            color: "var(--text-color-light)",
                        }}
                    >
                        {props.stops.pickup.date_tooltip}
                    </p>
                )}
            </div>

            {(props.stops.pickup.order?.assigned_location_id ||
                props.stops.pickup.origin_location) && (
                <div className="assigned-wrapper">
                    {props.stops.pickup.order?.assigned_location_id && (
                        <p
                            className="text-xs"
                            style={{
                                color: "var(--text-color-alt)",
                            }}
                        >
                            {t("fleetPlanner.assignedByAlrik")}
                        </p>
                    )}

                    {props.stops.pickup.origin_location?.id ===
                    user?.location_id ? (
                        <>
                            <p className="text-xs">
                                {t("fleetPlanner.assignedToOtherLocation")}{" "}
                                {props.stops.pickup.location?.name || ""}
                            </p>
                            <p
                                className="text-xs"
                                style={{
                                    color: props.stops.pickup.move_accepted_at
                                        ? "var(--text-color-alt)"
                                        : "var(--error-color)",
                                }}
                            >
                                {t("fleetPlanner.assignedStatus")}{" "}
                                {props.stops.pickup.move_accepted_at
                                    ? t("fleetPlanner.assignedAccepted")
                                    : t("fleetPlanner.assignedNotAccepted")}
                            </p>
                        </>
                    ) : props.stops.pickup.origin_location ? (
                        <p className="text-xs">
                            {t("fleetPlanner.assignedByOtherLocation")}{" "}
                            {props.stops.pickup.origin_location.name}
                        </p>
                    ) : null}
                </div>
            )}

            <div className="addresses-wrapper">
                <section>
                    <IconOLD
                        type="plane-departure"
                        style={{
                            marginTop: "4px",
                            minWidth: "20px",
                        }}
                    />
                    <div className="address">
                        <div className="address-text">
                            {props.onColorChange ? (
                                <ColorPicker
                                    value={props.stops.pickup.color || null}
                                    onChange={(color) =>
                                        props.onColorChange?.(
                                            props.stops.pickup,
                                            color
                                        )
                                    }
                                    isLoading={props.isPickupChangingColor}
                                />
                            ) : (
                                props.stops.pickup.color && (
                                    <DotsButton
                                        amount={1}
                                        color={
                                            props.stops.pickup.color ||
                                            undefined
                                        }
                                        style={{
                                            backgroundColor: "transparent",
                                            padding: "0",
                                        }}
                                        large
                                    />
                                )
                            )}
                            <span className="text-sm">
                                {props.stops.pickup.to_location}
                            </span>
                        </div>
                        <div className="address-info">
                            {props.stops.pickup.contact && (
                                <span className="text-xs">
                                    {props.stops.pickup.contact.name}
                                </span>
                            )}
                            {props.stops.pickup.time_tooltip &&
                                props.stops.pickup.contact && (
                                    <span className="text-xs">-</span>
                                )}
                            {props.stops.pickup.time_tooltip && (
                                <span className="text-xs">
                                    {props.stops.pickup.time_tooltip}
                                </span>
                            )}
                        </div>
                        {props.stops.pickup.driver_note && (
                            <p className="driver-note">
                                <IconOLD
                                    type="comment"
                                    style={{ minWidth: "20px" }}
                                />
                                <p className="text-xs">
                                    {props.stops.pickup.driver_note}
                                </p>
                            </p>
                        )}
                    </div>
                </section>

                <section>
                    <IconOLD
                        type="race-flag"
                        style={{
                            marginTop: "4px",
                            minWidth: "20px",
                        }}
                    />
                    <div className="address">
                        <div className="address-text">
                            {props.onColorChange ? (
                                <ColorPicker
                                    value={props.stops.dropoff.color || null}
                                    onChange={(color) =>
                                        props.onColorChange?.(
                                            props.stops.dropoff,
                                            color
                                        )
                                    }
                                    isLoading={props.isDropoffChangingColor}
                                />
                            ) : (
                                props.stops.dropoff.color && (
                                    <DotsButton
                                        amount={1}
                                        color={
                                            props.stops.dropoff.color ||
                                            undefined
                                        }
                                        style={{
                                            backgroundColor: "transparent",
                                            padding: "0",
                                        }}
                                        large
                                    />
                                )
                            )}
                            <span className="text-sm">
                                {props.stops.dropoff.to_location}
                            </span>
                        </div>
                        <div className="address-info">
                            {props.stops.dropoff.contact && (
                                <span className="text-xs">
                                    {props.stops.dropoff.contact.name}
                                </span>
                            )}
                            {props.stops.dropoff.time_tooltip &&
                                props.stops.dropoff.contact && (
                                    <span className="text-xs">-</span>
                                )}
                            {props.stops.dropoff.time_tooltip && (
                                <span className="text-xs">
                                    {props.stops.dropoff.time_tooltip}
                                </span>
                            )}
                        </div>
                        {props.stops.dropoff.driver_note && (
                            <p className="driver-note">
                                <IconOLD
                                    type="comment"
                                    style={{ minWidth: "20px" }}
                                />
                                <p className="text-xs">
                                    {props.stops.dropoff.driver_note}
                                </p>
                            </p>
                        )}
                    </div>
                </section>
            </div>

            {props.stops.pickup.cargo_content &&
                !!settings.showCargoContent && (
                    <div className="cargo-content-wrapper">
                        <p
                            ref={cargoContentText}
                            className={`cargo-content${
                                isCargoContentExpanded ? " expanded" : ""
                            }`}
                            style={{ color: "var(--text-color-light)" }}
                        >
                            {props.stops.pickup.cargo_content}
                        </p>

                        {hasActiveEllipsis && (
                            <TextButtonOLD
                                label={
                                    isCargoContentExpanded
                                        ? t("activeFleet.showLess")
                                        : t("activeFleet.showMore")
                                }
                                color="var(--order-in-progress-color)"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsCargoContentExpanded(
                                        (state) => !state
                                    );
                                }}
                            />
                        )}
                    </div>
                )}

            {(showStatus || isStopCompleted) && (
                <div className="status-wrapper">
                    {orderStatus && (
                        <StatusCircle
                            status={isStopCompleted ? "fulfilled" : orderStatus}
                        />
                    )}
                    <span className="text-xs">{t(statusText)}</span>
                    {isStopCompleted && (
                        <span
                            style={{
                                color: "var(--text-color-light)",
                            }}
                        >
                            {isStopCompleted
                                ? props.stops.pickup.order?.fulfilled_at &&
                                  formatDateString(
                                      props.stops.pickup.order.fulfilled_at,
                                      {
                                          onlyTime: true,
                                      }
                                  )
                                : null}
                        </span>
                    )}
                </div>
            )}

            {(props.stops.pickup.weight_kg ||
                props.stops.pickup.tag ||
                props.shareActions) && (
                <div className="bottom">
                    <div className="bottom-info">
                        {props.stops.pickup.weight_kg ? (
                            <p
                                style={{
                                    color: "var(--text-color-light)",
                                }}
                            >
                                <IconOLD
                                    type="weight"
                                    style={{
                                        minWidth: "20px",
                                    }}
                                />
                                {props.stops.pickup.weight_kg} kg
                            </p>
                        ) : (
                            <div />
                        )}
                        {props.stops.pickup.tag && (
                            <p
                                style={{
                                    color: "var(--text-color-light)",
                                }}
                            >
                                <IconOLD
                                    type="tag"
                                    style={{
                                        minWidth: "20px",
                                    }}
                                />
                                {props.stops.pickup.tag}
                            </p>
                        )}
                    </div>
                    <div className="share-actions">
                        {props.shareActions && (
                            <>
                                <IconButton
                                    icon="cross"
                                    onClick={props.shareActions.onDecline}
                                    isLoading={props.shareActions.isLoading}
                                    style={{
                                        backgroundColor: "var(--error-color)",
                                    }}
                                    short
                                />
                                <IconButton
                                    icon="check"
                                    onClick={props.shareActions.onAccept}
                                    isLoading={props.shareActions.isLoading}
                                    style={{
                                        backgroundColor:
                                            "var(--text-color-alt)",
                                    }}
                                    short
                                />
                            </>
                        )}
                    </div>
                </div>
            )}

            {props.stops.pickup.revised_cargo_content &&
                isEnvironment("staging") && (
                    <div className="revised-cargo-content-wrapper">
                        {isRevisedCargoContentExpanded && (
                            <p className="revised-cargo-content">
                                {props.stops.pickup.revised_cargo_content}
                            </p>
                        )}
                        <TextButtonOLD
                            label={
                                isRevisedCargoContentExpanded
                                    ? t("activeFleet.hideRevisedCargo")
                                    : t("activeFleet.showRevisedCargo")
                            }
                            color="var(--order-in-progress-color)"
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsRevisedCargoContentExpanded(
                                    (state) => !state
                                );
                            }}
                        />
                    </div>
                )}
        </div>
    );
}

export default CombinedStopCard;
