import { Control, UseFormSetValue } from "react-hook-form";
import { NewOrderForm } from "../../schemas/form";
import { MultiPriceData, OrderOptions } from "../../shared/types/api";
import { CarNames } from "../../shared/types/internal";
import VehicleCard from "./VehicleCard";
import "./style.scss";

type Props = {
    control: Control<NewOrderForm>;
    orderOptions: OrderOptions;
    priceOptions: MultiPriceData | null;
    onVehicleChange: (vehicleName: string) => void;
    activeVehicle: string;
    disabledVehicles: string[];
    setValue: UseFormSetValue<NewOrderForm>;
};

function FleetSelector(props: Props) {
    return (
        <div className="fleet-selector">
            {props.orderOptions.vehicle_types.map((vehicle) => (
                <VehicleCard
                    key={vehicle.car_name}
                    control={props.control}
                    vehicle={vehicle}
                    onDeliveryChange={() =>
                        props.onVehicleChange(vehicle.car_name)
                    }
                    prices={
                        props.priceOptions?.prices[
                            vehicle.car_name as CarNames
                        ] || null
                    }
                    activeVehicle={props.activeVehicle}
                    disabled={
                        !vehicle.enabled ||
                        props.disabledVehicles.includes(vehicle.car_name)
                    }
                    setValue={props.setValue}
                />
            ))}
        </div>
    );
}

export default FleetSelector;
