import {
    AdvancedMarker,
    InfoWindow,
    useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { useState } from "react";
import IconOLD from "../../../components/UI/IconOLD";
import { LocationEntity } from "../../../shared/types/api";
import "./style.scss";

type Props = {
    location: LocationEntity;
    color?: string;
    backgroundColor?: string;
};

function LocationMarker(props: Props) {
    const [markerRef, marker] = useAdvancedMarkerRef();
    const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);

    if (!props.location.lat && !props.location.lng) return null;

    return (
        <>
            <AdvancedMarker
                key={props.location.id}
                ref={markerRef}
                position={{
                    lat: props.location.lat!,
                    lng: props.location.lng!,
                }}
                zIndex={-1}
                onClick={() => {
                    setIsInfoWindowOpen(!isInfoWindowOpen);
                }}
            >
                {isInfoWindowOpen && (
                    <InfoWindow
                        anchor={marker}
                        onCloseClick={() => setIsInfoWindowOpen(false)}
                    >
                        <p className="text-sm">{props.location.name}</p>
                        <p className="text-sm">{props.location.address}</p>
                    </InfoWindow>
                )}
                <div
                    className="location-marker"
                    style={{
                        backgroundColor: props.backgroundColor || undefined,
                    }}
                >
                    <IconOLD
                        fontSize="18px"
                        type="house"
                        style={{
                            color: props.color || undefined,
                        }}
                    />
                </div>
            </AdvancedMarker>
        </>
    );
}

export default LocationMarker;
