import { Dispatch, SetStateAction } from "react";
import { useOutletContext } from "react-router-dom";

type ContextType = {
    filterDate: Date | null;
    setFilterDate: Dispatch<SetStateAction<Date | null>>;
};

export function useFleetRoute() {
    return useOutletContext<ContextType>();
}
