import { useMap } from "@vis.gl/react-google-maps";
import { useEffect } from "react";

function usePanToMapCenter(center: google.maps.LatLng | undefined) {
    const map = useMap();

    useEffect(() => {
        if (!center || !map) return;

        map.panTo(center);
    }, [center, map]);
}

export default usePanToMapCenter;
