import { format } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import IconOLD from "../../components/UI/IconOLD";
import ButtonOLD from "../../components/buttons/ButtonOLD";
import TextButtonOLD from "../../components/buttons/TextButtonOLD";
import Popup from "../../hoc/Popup";
import useValidateAddress from "../../hooks/data/useValidateAddress";
import PointsMap from "../../maps/PointsMap";
import { NewOrderForm } from "../../schemas/form";
import { Vehicle } from "../../shared/types/api";
import { IconType } from "../../shared/types/internal";
import { convertToNumberWithSpaces } from "../../shared/utility/misc";
import { DELIVERY_NAME } from "../../shared/values/enums";
import "./style.scss";
import { GOOGLE_MAP_IDS } from "../../shared/values/google-map-ids";

type Props = {
    orderData: NewOrderForm;
    vehicleType: Vehicle | null;
    price: number;
    showPopup: boolean;
    onClose: () => void;
    onSubmit: () => void;
    isSubmitting?: boolean;
};

function ConfirmOrderPopup(props: Props) {
    const { t } = useTranslation();

    const [isShowingMore, setIsShowingMore] = useState(false);

    const { data: fromAddressData } = useValidateAddress(
        props.orderData.fromAddress
    );
    const { data: toAddressData } = useValidateAddress(
        props.orderData.toAddress
    );

    let pickupText = "";
    let deliveryText = "";

    switch (props.orderData.deliveryInfo.deliveryOption) {
        case DELIVERY_NAME.Express:
            pickupText = t("popup.confirmOrder.pickupExpressLabel");
            deliveryText = t("popup.confirmOrder.deliveryExpressLabel");
            break;

        case DELIVERY_NAME.Tomorrow:
            pickupText = t("popup.confirmOrder.pickupTomorrowLabel");
            deliveryText = t("popup.confirmOrder.deliveryTomorrowLabel");
            break;
    }

    return (
        <Popup
            showPopup={props.showPopup}
            onClose={props.onClose}
            title={t("popup.confirmOrder.title")}
            dontCloseOnOutsideClick
            overlayComponent={
                <PointsMap
                    points={
                        fromAddressData && toAddressData
                            ? [
                                  {
                                      address: props.orderData.fromAddress,
                                      label: "1",
                                      location: {
                                          lat: fromAddressData.lat,
                                          lng: fromAddressData.lng,
                                      },
                                      icon: "plane-departure",
                                  },
                                  {
                                      address: props.orderData.toAddress,
                                      label: "2",
                                      location: {
                                          lat: toAddressData.lat,
                                          lng: toAddressData.lng,
                                      },
                                      icon: "race-flag",
                                  },
                              ]
                            : []
                    }
                    mapId={GOOGLE_MAP_IDS.ConfirmOrderMap}
                    showDirections
                />
            }
        >
            <div className="confirm-order-popup">
                <section>
                    <div className="info">
                        <p className="title text-xs">
                            {t("popup.confirmOrder.weightLabel")}
                        </p>
                        <p className="value text-sm">
                            {props.orderData.weight}{" "}
                            {t("createOrder.weightUnit")}
                        </p>
                    </div>
                </section>
                <section>
                    <div className="info">
                        <p className="title text-xs">
                            {t("popup.confirmOrder.numberOfPackagesLabel")}
                        </p>
                        <p className="value text-sm">
                            {props.orderData.numberOfEuPallets}
                        </p>
                    </div>
                </section>
                <section>
                    <div className="info">
                        <p className="title text-xs">
                            {t(
                                "popup.confirmOrder.weightOfHeaviestPackageLabel"
                            )}
                        </p>
                        <p className="value text-sm">
                            {props.orderData.weightOfHeaviestPackage}{" "}
                            {t("createOrder.weightUnit")}
                        </p>
                    </div>
                </section>
                <section>
                    <div className="info">
                        <p className="title text-xs">
                            {t("popup.confirmOrder.earliestPickupLabel")}
                        </p>
                        <p className="value text-sm">
                            {pickupText ||
                                (props.orderData.deliveryInfo.deliveryDate
                                    ? `${format(
                                          props.orderData.deliveryInfo
                                              .deliveryDate!,
                                          "yyyy-MM-dd"
                                      )} ${
                                          props.orderData.deliveryInfo
                                              .earliestPickUp
                                      }`
                                    : "-")}
                        </p>
                    </div>
                </section>
                <section>
                    <div className="info">
                        <p className="title text-xs">
                            {t("popup.confirmOrder.latestDeliveryLabel")}
                        </p>
                        <p className="value text-sm">
                            {deliveryText ||
                                (props.orderData.deliveryInfo.deliveryDate
                                    ? `${format(
                                          props.orderData.deliveryInfo
                                              .deliveryDate!,
                                          "yyyy-MM-dd"
                                      )} ${
                                          props.orderData.deliveryInfo
                                              .latestDelivery
                                      }`
                                    : "-")}
                        </p>
                    </div>
                </section>
                <section>
                    <div className="info">
                        <p className="title text-xs">
                            {t("popup.confirmOrder.vehicleLabel")}
                        </p>
                        <p className="value text-sm">
                            <span>
                                <IconOLD
                                    type={
                                        props.vehicleType?.car_name as IconType
                                    }
                                    style={{
                                        color: "var(--text-color-light)",
                                    }}
                                />{" "}
                                {props.vehicleType?.friendlyname}
                            </span>
                        </p>
                    </div>
                </section>
                {props.vehicleType?.car_name === "large-van" && (
                    <section>
                        <div className="info">
                            <p className="title text-xs">
                                {t("createOrder.additionalVehicleTypeLabel")}
                            </p>
                            <p className="value text-sm">
                                {t(
                                    props.orderData.additionalInfo?.sideLoading
                                        ? "general.yes"
                                        : "general.no"
                                )}
                            </p>
                        </div>
                    </section>
                )}
                <section>
                    <div className="info">
                        <p className="title text-xs">
                            {t("popup.confirmOrder.fromLabel")}
                        </p>
                        <p className="value text-sm">
                            {props.orderData.fromAddress}
                        </p>
                    </div>
                </section>
                <section>
                    <div className="info">
                        <p className="title text-xs">
                            {t("popup.confirmOrder.toLabel")}
                        </p>
                        <p className="value text-sm">
                            {props.orderData.toAddress}
                        </p>
                    </div>
                </section>
                <section>
                    <div className="info">
                        <p className="title text-xs">
                            {t("popup.confirmOrder.orderNumberLabel")}
                        </p>
                        <p className="value text-sm">
                            {props.orderData.orderNumber}
                        </p>
                    </div>
                </section>
                <section>
                    <div className="info">
                        <p className="title text-xs">
                            {t("popup.confirmOrder.contactPhoneLabel")}
                        </p>
                        <p className="value text-sm">{props.orderData.phone}</p>
                    </div>
                </section>
                <div className="text-button-wrapper">
                    <TextButtonOLD
                        label={
                            isShowingMore
                                ? t("popup.confirmOrder.hideMoreLabel")
                                : t("popup.confirmOrder.showMoreLabel")
                        }
                        color="var(--text-color)"
                        onClick={() => setIsShowingMore((state) => !state)}
                    />
                </div>
                {isShowingMore && (
                    <>
                        <section>
                            <div className="info">
                                <p className="title text-xs">
                                    {t("createOrder.additionalInfoCompany")}
                                </p>
                                <p className="value text-sm">
                                    {props.orderData.additionalInfo
                                        ?.consigneeCompany || "-"}
                                </p>
                            </div>
                        </section>
                        <section>
                            <div className="info">
                                <p className="title text-xs">
                                    {t("createOrder.additionalInfoUnloading")}
                                </p>
                                <p className="value text-sm">
                                    {props.orderData.additionalInfo
                                        ?.estimatedOffloadTime || "-"}
                                </p>
                            </div>
                        </section>
                        <section>
                            <div className="info">
                                <p className="title text-xs">
                                    {t(
                                        "createOrder.additionalInfoAdditionalContact"
                                    )}
                                </p>
                                {props.orderData.additionalInfo?.contacts?.map(
                                    (c, i) => (
                                        <p className="value text-sm" key={i}>
                                            <span>{c.consigneeName}</span>
                                            <span>{c.consigneePhone}</span>
                                        </p>
                                    )
                                ) || "-"}
                            </div>
                        </section>
                        <section>
                            <div className="info">
                                <p className="title text-xs">
                                    {t(
                                        "createOrder.additionalInfoSpecificDropoff"
                                    )}
                                </p>
                                <p className="value text-sm">
                                    {props.orderData.additionalInfo
                                        ?.offloadInstructions || "-"}
                                </p>
                            </div>
                        </section>
                        <section>
                            <div className="info">
                                <p className="title text-xs">
                                    {t("createOrder.additionalInfoTotalCargo")}
                                </p>
                                {props.orderData.additionalInfo?.cargoDimensions?.map(
                                    (cd, i) => (
                                        <p className="value text-sm" key={i}>
                                            <span>
                                                {t(
                                                    "createOrder.additionalInfoDropdownLengthLabel"
                                                )}
                                                :{cd.length || "-"}
                                            </span>
                                            <span>
                                                {t(
                                                    "createOrder.additionalInfoDropdownWidthLabel"
                                                )}
                                                :{cd.width || "-"}
                                            </span>
                                            <span>
                                                {t(
                                                    "createOrder.additionalInfoDropdownHeightLabel"
                                                )}
                                                :{cd.height || "-"}
                                            </span>
                                            <span>
                                                {t(
                                                    "createOrder.additionalInfoDropdownWeightLabel"
                                                )}
                                                : {cd.weight || "-"}
                                            </span>
                                        </p>
                                    )
                                ) || "-"}
                            </div>
                        </section>
                        <section>
                            <div className="info">
                                <p className="title text-xs">
                                    {t(
                                        "createOrder.additionalInfoBranchNumber"
                                    )}
                                </p>
                                <p className="value text-sm">
                                    {props.orderData.additionalInfo
                                        ?.branchNumber || "-"}
                                </p>
                            </div>
                        </section>
                        <section>
                            <div className="info">
                                <p className="title text-xs">
                                    {t(
                                        "createOrder.additionalInfoMarkingInvoice"
                                    )}
                                </p>
                                <p className="value text-sm">
                                    {props.orderData.additionalInfo
                                        ?.invoiceNumber || "-"}
                                </p>
                            </div>
                        </section>
                    </>
                )}

                <div className="submit-wrapper">
                    <ButtonOLD
                        label={`${t(
                            "popup.confirmOrder.confirmLabel"
                        )} (${convertToNumberWithSpaces(props.price, "SEK")})`}
                        color="secondary"
                        onClick={props.onSubmit}
                        width="100%"
                        isLoading={props.isSubmitting}
                    />
                </div>
            </div>
        </Popup>
    );
}

export default ConfirmOrderPopup;
