import { useQuery } from "@tanstack/react-query";
import { getChatHistory } from "../../api/assistant";

function useAssistantChat() {
    const {
        data: messages,
        refetch,
        status,
        isFetching,
    } = useQuery({
        queryKey: ["assistant-chat"],
        queryFn: async () => {
            const res = await getChatHistory();
            return res.data;
        },
    });

    switch (status) {
        case "success":
            return {
                messages,
                status,
                refetch,
                isFetching,
            };
        case "pending":
            return {
                messages,
                status,
                refetch,
                isFetching,
            };
        case "error":
            return {
                messages,
                status,
                refetch,
                isFetching,
            };
    }
}

export default useAssistantChat;
