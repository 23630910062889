import { useCallback, useState } from "react";
import { StopDraft, StopDraftsTour } from "../../shared/types/api";
import { getStopLocationText } from "../../shared/utility/stop-draft";
import {
    getNextStop,
    getTourEndDate,
    getTourProgress,
} from "../../shared/utility/tour";
import { StopFilter } from "../../shared/types/internal";

function useStopAndTourFilterAndSort() {
    //Sorting
    const [activeTourSort, setActiveTourSort] = useState<
        | "orders-desc"
        | "orders-asc"
        | "driver-desc"
        | "driver-asc"
        | "progress-desc"
        | "progress-asc"
        | "endtime-desc"
        | "endtime-asc"
        | "nextstop-desc"
        | "nextstop-asc"
        | null
    >(null);
    const [activeStopSort, setActiveStopSort] = useState<
        | "weight-desc"
        | "weight-asc"
        | "pickup-desc"
        | "pickup-asc"
        | "dropoff-desc"
        | "dropoff-asc"
        | "ordernumber-desc"
        | "ordernumber-asc"
        | "tag-desc"
        | "tag-asc"
        | null
    >(null);

    //filter
    const [tourSearch, setTourSearch] = useState("");

    const [stopSearch, setStopSearch] = useState("");
    const [activeStopFilter, setActiveStopFilter] = useState<StopFilter>({
        tags: [],
        pickupCities: [],
        dropoffCities: [],
    });

    const sortTours = useCallback(
        (a: StopDraftsTour, b: StopDraftsTour) => {
            if (activeTourSort === "orders-desc") {
                return b.stops.length - a.stops.length;
            }

            if (activeTourSort === "orders-asc") {
                return a.stops.length - b.stops.length;
            }

            if (activeTourSort === "driver-desc") {
                return b.preferred_driver
                    .toLowerCase()
                    .localeCompare(a.preferred_driver.toLowerCase());
            }

            if (activeTourSort === "driver-asc") {
                return a.preferred_driver
                    .toLowerCase()
                    .localeCompare(b.preferred_driver.toLowerCase());
            }

            if (
                activeTourSort === "progress-desc" ||
                activeTourSort === "progress-asc"
            ) {
                const aTourStatus = getTourProgress(a);
                const bTourStatus = getTourProgress(b);

                if (activeTourSort === "progress-desc") {
                    return bTourStatus.progress - aTourStatus.progress;
                }

                if (activeTourSort === "progress-asc") {
                    return aTourStatus.progress - bTourStatus.progress;
                }
            }

            if (
                activeTourSort === "endtime-desc" ||
                activeTourSort === "endtime-asc"
            ) {
                const aEndTime = getTourEndDate(a.stops);
                const bEndTime = getTourEndDate(b.stops);

                if (activeTourSort === "endtime-desc") {
                    return (
                        (bEndTime?.getTime() || 0) - (aEndTime?.getTime() || 0)
                    );
                }

                if (activeTourSort === "endtime-asc") {
                    return (
                        (aEndTime?.getTime() || 0) - (bEndTime?.getTime() || 0)
                    );
                }
            }

            if (
                activeTourSort === "nextstop-desc" ||
                activeTourSort === "nextstop-asc"
            ) {
                const aNextStop = getNextStop(a);
                const bNextStop = getNextStop(b);

                if (activeTourSort === "nextstop-desc") {
                    return (bNextStop ? getStopLocationText(bNextStop) : "")
                        .toLowerCase()
                        .localeCompare(
                            (aNextStop
                                ? getStopLocationText(aNextStop)
                                : ""
                            ).toLowerCase()
                        );
                }

                if (activeTourSort === "nextstop-asc") {
                    return (aNextStop ? getStopLocationText(aNextStop) : "")
                        .toLowerCase()
                        .localeCompare(
                            (bNextStop
                                ? getStopLocationText(bNextStop)
                                : ""
                            ).toLowerCase()
                        );
                }
            }

            return 0;
        },
        [activeTourSort]
    );

    const sortCombinedStops = useCallback(
        (
            a: {
                pickup: StopDraft;
                dropoff: StopDraft;
            },
            b: {
                pickup: StopDraft;
                dropoff: StopDraft;
            }
        ) => {
            if (activeStopSort === "weight-desc") {
                return (b.pickup.weight_kg || 0) - (a.pickup.weight_kg || 0);
            }

            if (activeStopSort === "weight-asc") {
                return (a.pickup.weight_kg || 0) - (b.pickup.weight_kg || 0);
            }

            if (activeStopSort === "pickup-desc") {
                return getStopLocationText(b.pickup)
                    .toLowerCase()
                    .localeCompare(getStopLocationText(a.pickup).toLowerCase());
            }

            if (activeStopSort === "pickup-asc") {
                return getStopLocationText(a.pickup)
                    .toLowerCase()
                    .localeCompare(getStopLocationText(b.pickup).toLowerCase());
            }

            if (activeStopSort === "dropoff-desc") {
                return getStopLocationText(b.dropoff)
                    .toLowerCase()
                    .localeCompare(
                        getStopLocationText(a.dropoff).toLowerCase()
                    );
            }

            if (activeStopSort === "dropoff-asc") {
                return getStopLocationText(a.dropoff)
                    .toLowerCase()
                    .localeCompare(
                        getStopLocationText(b.dropoff).toLowerCase()
                    );
            }

            if (activeStopSort === "ordernumber-desc") {
                return b.pickup.order_number
                    .toLowerCase()
                    .localeCompare(a.pickup.order_number.toLowerCase());
            }

            if (activeStopSort === "ordernumber-asc") {
                return a.pickup.order_number
                    .toLowerCase()
                    .localeCompare(b.pickup.order_number.toLowerCase());
            }

            if (activeStopSort === "tag-desc") {
                return (b.pickup.tag || "")
                    .toLowerCase()
                    .localeCompare((a.pickup.tag || "").toLowerCase());
            }

            if (activeStopSort === "tag-asc") {
                return (a.pickup.tag || "")
                    .toLowerCase()
                    .localeCompare((b.pickup.tag || "").toLowerCase());
            }

            return 0;
        },
        [activeStopSort]
    );

    const filterTours = useCallback(
        (tour: StopDraftsTour) => {
            let showBasedOnSearch = true;
            if (tourSearch) {
                showBasedOnSearch = tour.preferred_driver
                    .toLowerCase()
                    .includes(tourSearch.toLowerCase());
            }

            return showBasedOnSearch;
        },
        [tourSearch]
    );

    const filterCombinedStops = useCallback(
        ({ pickup, dropoff }: { pickup: StopDraft; dropoff: StopDraft }) => {
            let showBasedOnSearch = true;
            if (stopSearch) {
                const searchStringForStops = `${pickup.order_number} ${
                    pickup.to_location
                } ${dropoff.to_location} ${getStopLocationText(
                    pickup
                )} ${getStopLocationText(dropoff)} ${pickup.tag} ${
                    pickup.weight_kg
                }`;

                showBasedOnSearch = searchStringForStops
                    .toLowerCase()
                    .includes(stopSearch.toLowerCase());
            }

            let showBasedOnTag = true;
            if (activeStopFilter.tags.length && pickup.tag) {
                showBasedOnTag = activeStopFilter.tags.includes(pickup.tag);
            }

            let showBasedOnPickup = true;
            if (activeStopFilter.pickupCities.length) {
                const pickupCity = pickup.city || pickup.to_location;

                showBasedOnPickup =
                    activeStopFilter.pickupCities.includes(pickupCity);
            }

            let showBasedOnDropoff = true;
            if (activeStopFilter.dropoffCities.length) {
                const dropoffCity = dropoff.city || dropoff.to_location;

                showBasedOnDropoff =
                    activeStopFilter.dropoffCities.includes(dropoffCity);
            }

            return (
                showBasedOnSearch &&
                showBasedOnTag &&
                showBasedOnPickup &&
                showBasedOnDropoff
            );
        },
        [
            activeStopFilter.dropoffCities,
            activeStopFilter.pickupCities,
            activeStopFilter.tags,
            stopSearch,
        ]
    );

    return {
        sortCombinedStops,
        sortTours,

        filterCombinedStops,
        filterTours,

        activeStopFilter,
        setActiveStopFilter,
        activeStopSort,
        setActiveStopSort,
        stopSearch,
        setStopSearch,

        activeTourSort,
        setActiveTourSort,
        tourSearch,
        setTourSearch,
    };
}

export default useStopAndTourFilterAndSort;
