import { IconType } from "../../../shared/types/internal";
import IconOLD from "../../UI/IconOLD";
import Spinner from "../../UI/Spinner";
import Tooltip from "../../UI/Tooltip";
import TooltipModal from "../../UI/TooltipModal";
import "./style.scss";

type Props = {
    type: "text" | "date" | "tel" | "email" | "password" | "time" | "number";
    value: string;
    onChange: (value: string) => void;
    onClick?: () => void;
    name?: string;
    label?: string | null;
    labelColor?: string;
    tooltip?: string | null;
    hoverTooltip?: string | null;
    placeholder?: string | null;
    width?: string;
    disabled?: boolean;
    invalid?: boolean;
    autoComplete?:
        | "off"
        | "new-password"
        | "current-password"
        | "on"
        | "one-time-code"
        | "email";
    style?: React.CSSProperties;
    isLoading?: boolean;
    onFocus?: (enter: boolean) => void;
    id?: string;
    isValid?: boolean;
    icon?: IconType;
    short?: boolean;
};

function InputOLD(props: Props) {
    const inputClasses = ["text-sm"];
    if (props.icon) {
        inputClasses.push("icon-input");
    }
    if (props.isLoading) {
        inputClasses.push("input-loading");
    }
    if (props.invalid) {
        inputClasses.push("input-invalid");
    }
    if (props.isValid) {
        inputClasses.push("input-valid");
    }
    if (props.short) {
        inputClasses.push("input-short");
    }

    return (
        <label
            className="input-wrapper-old text-sm"
            style={{ width: props.width }}
            id={props.id}
        >
            {(props.tooltip || props.label) && (
                <div
                    className="input-label"
                    style={{ color: props.labelColor }}
                >
                    {props.tooltip && <Tooltip content={props.tooltip} />}
                    {props.label && (
                        <span className="text-xs">{props.label}</span>
                    )}
                </div>
            )}
            {props.icon && (
                <IconOLD
                    type={props.icon}
                    className="input-icon"
                    style={{
                        color: "var(--text-color-light)",
                        zIndex: 1,
                    }}
                    fontSize="14px"
                />
            )}
            <input
                type={props.type}
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
                placeholder={props.placeholder || undefined}
                disabled={props.disabled}
                className={inputClasses.join(" ")}
                autoComplete={props.autoComplete}
                name={props.name}
                style={props.style}
                onClick={props.onClick}
                onBlur={() => props.onFocus && props.onFocus(false)}
                onFocus={() => props.onFocus && props.onFocus(true)}
            />
            {props.hoverTooltip && (
                <TooltipModal
                    className="input-tooltip"
                    content={props.hoverTooltip}
                />
            )}
            {props.isLoading && (
                <div className="input-spinner">
                    <Spinner />
                </div>
            )}
        </label>
    );
}

export default InputOLD;
