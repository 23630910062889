import { useMutation } from "@tanstack/react-query";
import { t } from "i18next";
import toast from "react-hot-toast";
import { ZodError } from "zod";
import { deleteStopDraft, updateStopDrafts } from "../../api/stop-draft";
import { StopDraftForm, stopDraftForm } from "../../schemas/form";
import { StopDraft, UpdateStopDraftData } from "../../shared/types/api";
import { dateToString } from "../../shared/utility/date";
import { formatPhoneNumber } from "../../shared/utility/phone";

function useStopDraftMutations({
    onSuccess,
    stopDrafts,
}: {
    onSuccess: () => Promise<unknown>;
    stopDrafts: StopDraft[];
}) {
    const { mutate: updateStopDraftHandler, isPending: isUpdating } =
        useMutation({
            mutationFn: async ({
                data,
                stopDraft,
            }: {
                data: StopDraftForm;
                stopDraft: StopDraft;
            }) => {
                stopDraftForm.parse(data);

                const pairStopDraft = stopDrafts!.find(
                    (sd) =>
                        sd.group_id === stopDraft.group_id &&
                        sd.id !== stopDraft.id
                );

                const updateData: UpdateStopDraftData[] = [];

                updateData.push({
                    ...stopDraft,
                    to_location: data.toLocation,
                    phone: formatPhoneNumber(data.phone),
                    cargo_content: data.cargoContent,
                    estimated_offload_time: +data.estimatedOffloadTime,
                    order_number: data.orderNumber,
                    contact_id: data.contactId,
                    date_tooltip: data.date ? dateToString(data.date) : null,
                    time_tooltip: data.time,
                    project_reference: data.projectReference,
                    customer_contact_at: data.customerContactAt,
                    weight_kg: data.weight || null,
                });

                if (pairStopDraft) {
                    updateData.push({
                        ...pairStopDraft,
                        cargo_content: data.cargoContent,
                        estimated_offload_time: +data.estimatedOffloadTime,
                        order_number: data.orderNumber,
                        date_tooltip: data.date
                            ? dateToString(data.date)
                            : null,
                        project_reference: data.projectReference,
                        customer_contact_at: data.customerContactAt,
                        weight_kg: data.weight || null,
                    });
                }

                await updateStopDrafts(updateData);
            },
            onSuccess: async () => {
                toast.success(t("successMessage.stopDraftUpdated"));
                await onSuccess();
            },
            onError: (error: ZodError) => {
                if (!error.issues) {
                    toast.error(t("errorMessage.unknown"));
                    return;
                }
                //only print the first error to not overwhelm the user
                toast.error(t(error.issues[0].message));
            },
        });

    const { mutate: deleteHandler, isPending: isDeleting } = useMutation({
        mutationFn: async (stopDraft: StopDraft) => {
            const pairStopDraft = stopDrafts.find(
                (sd) =>
                    sd.group_id === stopDraft.group_id && sd.id !== stopDraft.id
            );

            await deleteStopDraft(stopDraft.id);

            if (pairStopDraft) {
                await deleteStopDraft(pairStopDraft.id);
            }
        },
        onSuccess: async () => {
            await onSuccess();
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    return {
        updateStopDraftHandler,
        deleteHandler,
        isUpdating,
        isDeleting,
    };
}

export default useStopDraftMutations;
