import { useQuery } from "@tanstack/react-query";
import { getMovedStopDrafts } from "../../api/stop-draft";
import { useSelector } from "react-redux";
import { ReduxState } from "../../shared/types/redux";
import { useMemo } from "react";

function useMovedStops() {
    const { user } = useSelector((state: ReduxState) => state.auth);

    const hasAccess = useMemo(() => {
        return (
            !!user?.company_entity?.show_location_orders &&
            !user.company_entity.hidden_locations
        );
    }, [
        user?.company_entity?.hidden_locations,
        user?.company_entity?.show_location_orders,
    ]);

    const {
        data: movedStops,
        status,
        refetch,
        isFetching,
    } = useQuery({
        queryKey: ["moved-stops", hasAccess] as const,
        queryFn: async ({ queryKey: [_, hasAccess] }) => {
            if (!hasAccess) {
                return [];
            }

            const res = await getMovedStopDrafts();
            return res.data;
        },
        enabled: hasAccess,
    });

    switch (status) {
        case "success":
            return {
                movedStops,
                status,
                refetch,
                isFetching,
            };
        case "pending":
            return {
                movedStops,
                status,
                refetch,
                isFetching,
            };
        case "error":
            return {
                movedStops,
                status,
                refetch,
                isFetching,
            };
    }
}

export default useMovedStops;
