import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { useSearchQuery } from "../../hooks/functionality/useSearchQuery";
import TourAccessPopup from "../../popups/TourAccessPopup";
import { ReduxState } from "../../shared/types/redux";
import { stringToDate } from "../../shared/utility/date";
import { QUERY_PARAM, ROUTE } from "../../shared/values/enums";
import "./style.scss";

type Props = {};

function Fleet(props: Props) {
    const [filterDate, setFilterDate] = useState<Date | null>(new Date());
    const query = useSearchQuery();
    const navigate = useNavigate();
    const { user } = useSelector((state: ReduxState) => state.auth);

    useEffect(() => {
        const queryDate = query.get(QUERY_PARAM.FilterDate);
        if (!queryDate) return;
        setFilterDate(stringToDate(queryDate));
    }, [query]);

    return (
        <div className="fleet">
            <div className="content">
                <Outlet
                    context={{
                        filterDate,
                        setFilterDate,
                    }}
                />
            </div>
            <TourAccessPopup
                showPopup={
                    !user?.location_entity?.mt_organization &&
                    !user?.location_entity?.use_alrik_driver_app
                }
                onClose={() => navigate(ROUTE.NewOrder)}
            />
        </div>
    );
}

export default Fleet;
