import { useCallback, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
import useExternalCarriers from "../../../hooks/data/useExternalCarriers";
import { useClickOutside } from "../../../hooks/functionality/useClickOutside";
import ExternalCarrierPopup from "../../../popups/ExternalCarrierPopup";
import { ExternalCarrier } from "../../../shared/types/api";
import ShowingAllMessage from "../../UI/ShowingAllMessage";
import IconButton from "../../buttons/IconButton";
import AddressSearchRow from "../AddressSearch/AddressSearchRow";
import InputOLD from "../InputOLD";
import "./style.scss";

type Props = {
    value: number | null;
    onSelect: (carrier: ExternalCarrier | null) => void;
    placeholder?: string;
    label?: string;
    width?: string;
    noCreate?: boolean;
    hasClearButton?: boolean;
};

function ExternalCarrierInput(props: Props) {
    const { t } = useTranslation();

    const externalCarrierInputRef = useRef<HTMLLabelElement>(null);

    const [searchString, setSearchString] = useState("");
    const [newCarrierName, setNewCarrierName] = useState("");
    const [editCarrier, setEditCarrier] = useState<ExternalCarrier | null>(
        null
    );

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState(-1);

    const [filteredCarriers, setFilteredCarriers] = useState<ExternalCarrier[]>(
        []
    );

    const {
        externalCarriers,
        status: externalCarrierStatus,
        refetch: refetchExternalCarriers,
    } = useExternalCarriers();

    useClickOutside([externalCarrierInputRef], () => {
        if (!isModalOpen) return;

        setIsModalOpen(false);
        if (!searchString.length) {
            selectCarrierHandler(null);
            return;
        }

        const chosenCarrier = externalCarriers?.find(
            (carrier) => carrier.id === props.value
        );
        selectCarrierHandler(chosenCarrier || null);
    });

    function selectCarrierHandler(carrier: ExternalCarrier | null) {
        setTimeout(() => {
            setIsModalOpen(() => false);
        }, 0);

        if (!carrier) {
            props.onSelect(null);
            setSearchString("");
            return;
        }

        props.onSelect(carrier);
        setSearchString(carrier.name);
    }

    const filterCarriers = useCallback(
        (searchString: string, carrier: ExternalCarrier) => {
            const { name } = carrier;
            const search = searchString.toLowerCase();
            return name.toLowerCase().includes(search);
        },
        []
    );

    const searchHandler = useCallback(
        (searchString: string) => {
            if (externalCarrierStatus !== "success") return;

            if (searchString.length > 0) {
                const filtered = externalCarriers.filter((carrier) =>
                    filterCarriers(searchString, carrier)
                );

                setFilteredCarriers(filtered);
            } else {
                setFilteredCarriers(externalCarriers);
            }
        },
        [externalCarrierStatus, externalCarriers, filterCarriers]
    );

    useEffect(() => {
        const scrollableEl = document.querySelector<HTMLElement>(
            "#external-carrier-options-wrapper"
        );
        if (!scrollableEl) return;

        const focusedEl = scrollableEl.querySelector<HTMLElement>(
            "[data-focused=true]"
        );
        if (!focusedEl) return;

        scrollableEl.scroll({
            top: focusedEl.offsetTop - scrollableEl.offsetTop - 10,
            behavior: "smooth",
        });
    }, [focusedIndex]);

    useEffect(() => {
        if (externalCarrierStatus === "success") {
            setFilteredCarriers(externalCarriers);
        }
    }, [externalCarrierStatus, externalCarriers]);

    useEffect(() => {
        if (props.value === null) {
            setSearchString("");
            return;
        }

        const chosenCarrier = externalCarriers?.find(
            (carrier) => carrier.id === props.value
        );

        if (!chosenCarrier) {
            setSearchString("");
            return;
        }

        setSearchString(chosenCarrier.name);
    }, [externalCarriers, props.value]);

    useHotkeys(
        "ArrowDown, ArrowUp, Enter",
        (e) => {
            e.preventDefault();

            switch (e.key) {
                case "ArrowUp":
                    if (focusedIndex > 0) {
                        setFocusedIndex((state) => state - 1);
                    } else {
                        setFocusedIndex(filteredCarriers.length - 1);
                    }
                    break;

                case "ArrowDown":
                    if (focusedIndex < filteredCarriers.length) {
                        setFocusedIndex((state) => state + 1);
                    } else {
                        setFocusedIndex(0);
                    }
                    break;

                case "Enter":
                    if (
                        focusedIndex < 0 ||
                        focusedIndex > filteredCarriers.length
                    )
                        return;
                    selectCarrierHandler(filteredCarriers[focusedIndex]);
                    break;
            }
        },
        {
            enabled: isModalOpen,
            enableOnFormTags: ["input"],
        }
    );

    return (
        <>
            <label
                className="external-carrier-input-wrapper"
                ref={externalCarrierInputRef}
                style={{ width: props.width }}
                onClick={() => setIsModalOpen(() => true)}
                onFocus={() => setIsModalOpen(() => true)}
            >
                <InputOLD
                    type="text"
                    icon="truck"
                    value={searchString}
                    label={props.label}
                    placeholder={props.placeholder}
                    onChange={(v) => {
                        setSearchString(v);
                        searchHandler(v);
                        if (!isModalOpen) {
                            setIsModalOpen(true);
                        }
                    }}
                />
                {props.hasClearButton && (
                    <IconButton
                        icon="cross"
                        style={{
                            position: "absolute",
                            right: "8px",
                            bottom: "5px",
                            backgroundColor: "transparent",
                            color: "var(--text-color)",
                        }}
                        onClick={() => {
                            selectCarrierHandler(null);
                        }}
                        disabled={!props.value}
                        short
                    />
                )}
                {isModalOpen && (
                    <div className="external-carrier-input-modal">
                        <h3 className="text-xs">{t("general.carriers")}</h3>
                        <section id="external-carrier-options-wrapper">
                            {filteredCarriers.map((carrier, i) => (
                                <AddressSearchRow
                                    key={carrier.id}
                                    label={carrier.name}
                                    onClick={() =>
                                        selectCarrierHandler(carrier)
                                    }
                                    isFocused={i === focusedIndex}
                                    onFavouriteClick={() =>
                                        setEditCarrier(carrier)
                                    }
                                    isFavourite={carrier.id !== -1}
                                />
                            ))}
                            {!!searchString && !props.noCreate && (
                                <AddressSearchRow
                                    key="new-carrier"
                                    label={`${t(
                                        "general.createCarrier"
                                    )} ${searchString}`}
                                    onClick={() =>
                                        setNewCarrierName(searchString)
                                    }
                                    onFavouriteClick={() =>
                                        setNewCarrierName(searchString)
                                    }
                                />
                            )}
                            {filteredCarriers.length === 0 &&
                                props.noCreate && (
                                    <ShowingAllMessage
                                        text={t("general.noCarriersFound")}
                                    />
                                )}
                        </section>
                    </div>
                )}
            </label>
            {!props.noCreate && (
                <ExternalCarrierPopup
                    showPopup={!!newCarrierName || !!editCarrier}
                    onClose={() => {
                        setNewCarrierName("");
                        setEditCarrier(null);
                    }}
                    onSave={(carrier) => {
                        refetchExternalCarriers();
                        selectCarrierHandler(carrier || null);
                    }}
                    onDelete={(id) => {
                        refetchExternalCarriers();
                        if (id === props.value) selectCarrierHandler(null);
                    }}
                    name={newCarrierName}
                    editCarrier={editCarrier || undefined}
                />
            )}
        </>
    );
}

export default ExternalCarrierInput;
