import { faClock, faWeightHanging } from "@fortawesome/pro-regular-svg-icons";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { Droppable } from "@hello-pangea/dnd";
import { useMutation } from "@tanstack/react-query";
import { addMinutes } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../../../components/UI/Spinner";
import Button from "../../../components/buttons/Button";
import Dropdown from "../../../components/inputs/Dropdown";
import Input from "../../../components/inputs/Input";
import { Driver, StopDraft, StopDraftsTour } from "../../../shared/types/api";
import { dateToString, stringToDate } from "../../../shared/utility/date";
import { getDriverDisplayName } from "../../../shared/utility/misc";
import {
    cardHoverHandler,
    getRunningWeight,
    getStopListRealtiveEtaPerIdInMinutes,
} from "../../../shared/utility/stop-draft";
import { STOP_DRAFT_TYPE } from "../../../shared/values/enums";
import StopCardDraggable from "../../cards/StopCardDraggable";
import "./style.scss";

type Props = {
    id: string;
    tour?: StopDraftsTour;
    stops: StopDraft[];
    drivers: Driver[];
    filterDate: Date | null;
    isNewTourColumn?: boolean;
    isLoading?: boolean;
    stopIdsLoading: number[];
    tourColor?: string;

    //actions
    onCreateNewTour: (data: {
        preferredDriverId: string;
        date: string;
        time: string;
        stopDraftIds: number[];
    }) => Promise<void>;
    onDispatchTour: (tourId: number) => Promise<void>;
    onUpdateTour: (data: {
        tourId: number;
        date: string;
        time: string;
        preferredDriverId: string;
    }) => Promise<void>;

    onClearStops?: () => void;
    onStopCardClick?: ({
        pickup,
        dropoff,
    }: {
        pickup: StopDraft;
        dropoff: StopDraft;
    }) => void;
};

function TourColumn(props: Props) {
    const { t } = useTranslation();
    const { onStopCardClick } = props;

    const tourColumnClasses = ["tour-column"];
    if (props.isLoading) {
        tourColumnClasses.push("loading");
    }

    const [selectedDriverId, setSelectedDriverId] = useState("");
    const [startTime, setStartTime] = useState("06:00");

    useEffect(() => {
        if (!props.tour) return;

        setSelectedDriverId(props.tour.preferred_driver_id);
        setStartTime(props.tour.time || "");
    }, [props.tour]);

    const { runningWeights, peakWeight } = useMemo(() => {
        return getRunningWeight(props.stops);
    }, [props.stops]);

    const stopsDurationsMap = useMemo(() => {
        return getStopListRealtiveEtaPerIdInMinutes(props.stops);
    }, [props.stops]);

    const { mutate: createTourHandler, isPending: isCreatingTour } =
        useMutation({
            mutationFn: async (data: {
                preferredDriverId: string;
                date: string;
                time: string;
                stopDraftIds: number[];
            }) => {
                await props.onCreateNewTour(data);
            },
            onSuccess: () => {
                setSelectedDriverId("");
                setStartTime("06:00");
            },
        });

    const { mutate: updateTourHandler, isPending: isUpdatingTour } =
        useMutation({
            mutationFn: async (data: {
                tourId: number;
                date: string;
                time: string;
                preferredDriverId: string;
            }) => {
                await props.onUpdateTour(data);
            },
            onSuccess: () => {},
        });

    const { mutate: dispatchTourHandler, isPending: isDispatchingTour } =
        useMutation({
            mutationFn: async (tourId: number) => {
                await props.onDispatchTour(tourId);
            },
            onSuccess: () => {},
        });

    const onStopCardClickHandler = useCallback(
        (stop: StopDraft) => {
            if (!onStopCardClick) return;

            const pickup = props.stops.find(
                (s) =>
                    stop.group_id === s.group_id &&
                    s.stop_type_id === STOP_DRAFT_TYPE.Pickup
            );

            const dropoff = props.stops.find(
                (s) =>
                    stop.group_id === s.group_id &&
                    s.stop_type_id === STOP_DRAFT_TYPE.Dropoff
            );

            if (!pickup || !dropoff) return;

            onStopCardClick({ pickup, dropoff });
        },
        [onStopCardClick, props.stops]
    );

    return (
        <div className={tourColumnClasses.join(" ")} data-column-id={props.id}>
            <div className="top">
                <Dropdown
                    value={selectedDriverId}
                    placeholder={t("bigVolume.chooseDriver")}
                    onSelect={({ value }) => setSelectedDriverId(value)}
                    options={props.drivers.map((driver) => ({
                        label: getDriverDisplayName(driver),
                        value: driver.mt_driver_id || "",
                    }))}
                    isSearchable
                />

                <div className="time-weight-group">
                    <Input
                        type="time"
                        value={startTime}
                        onChange={setStartTime}
                        trailingIcon={faClock}
                        placeholder={t("fleetPlanner.startTimePlaceholder")}
                        style={{ width: "100%" }}
                    />

                    <Input
                        type="text"
                        value={`${peakWeight} kg`}
                        onChange={() => {}}
                        trailingIcon={faWeightHanging}
                        disabled
                        style={{ width: "100%" }}
                    />
                    {props.tour ? (
                        <Button
                            disabled={
                                props.tour.time === startTime &&
                                props.tour.preferred_driver_id ===
                                    selectedDriverId
                            }
                            variant={"primary"}
                            leadingIcon={faSave}
                            isLoading={isUpdatingTour}
                            onClick={() => {
                                if (!props.tour) return;
                                updateTourHandler({
                                    tourId: props.tour.tour_id,
                                    date: props.tour.date || "",
                                    time: startTime,
                                    preferredDriverId: selectedDriverId,
                                });
                            }}
                        />
                    ) : null}
                </div>
            </div>
            <Droppable droppableId={props.id}>
                {(provided) => (
                    <div
                        className="content"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {props.isLoading && (
                            <div className="loading-wrapper">
                                <Spinner />
                            </div>
                        )}
                        {props.stops.map((stop, i) => (
                            <StopCardDraggable
                                key={stop.id}
                                id={stop.id.toString()}
                                index={i}
                                stop={stop}
                                runningWeight={runningWeights[i]}
                                eta={dateToString(
                                    addMinutes(
                                        stringToDate(
                                            (props.tour?.date || "2020-01-01") +
                                                "T" +
                                                startTime,
                                            {
                                                localTimezone: true,
                                            }
                                        )!,
                                        stopsDurationsMap[stop.id]
                                    ),
                                    {
                                        onlyTime: true,
                                    }
                                )}
                                isLoading={props.stopIdsLoading.includes(
                                    stop.id
                                )}
                                onHover={(groupId) =>
                                    cardHoverHandler({
                                        groupId,
                                        columnId: props.id,
                                    })
                                }
                                color={props.tourColor}
                                onClick={() => onStopCardClickHandler(stop)}
                            />
                        ))}
                        {props.stops.length === 0 && (
                            <div className="empty-placeholder">
                                <p className="text-xs">
                                    {t("fleetPlanner.emptyPlaceholderText")}
                                </p>
                            </div>
                        )}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>

            <div className="button-group">
                {props.isNewTourColumn && props.filterDate ? (
                    <>
                        <Button
                            disabled={props.stops.length === 0}
                            style={{ width: "100%" }}
                            variant="primary"
                            label={t("createTour.createTourLabel")}
                            onClick={() =>
                                createTourHandler({
                                    preferredDriverId: selectedDriverId,
                                    date: dateToString(props.filterDate),
                                    time: startTime,
                                    stopDraftIds: props.stops.map((s) => s.id),
                                })
                            }
                            isLoading={isCreatingTour}
                        />
                        <Button
                            disabled={props.stops.length === 0}
                            style={{ width: "100%" }}
                            variant="secondary"
                            label={t("createTour.clearTourLabel")}
                            onClick={props.onClearStops}
                        />
                    </>
                ) : null}

                {props.tour &&
                props.tour.dispatched_at !== props.tour.updated_at ? (
                    <Button
                        style={{ width: "100%" }}
                        variant="primary"
                        label={t("fleetPlanner.dispatchTour")}
                        isLoading={isDispatchingTour}
                        onClick={() => {
                            if (!props.tour) return;
                            dispatchTourHandler(props.tour.tour_id);
                        }}
                    />
                ) : null}
            </div>
        </div>
    );
}

export default TourColumn;
