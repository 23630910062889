import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonOLD from "../../components/buttons/ButtonOLD";
import AddressSearch from "../../components/inputs/AddressSearch";
import ContactInput from "../../components/inputs/ContactInput";
import DateInputOLD from "../../components/inputs/DateInputOLD";
import DropdownOLD from "../../components/inputs/DropdownOLD";
import ExpoSlider from "../../components/inputs/ExpoSlider";
import InputOLD from "../../components/inputs/InputOLD";
import RadioInput from "../../components/inputs/RadioInput";
import TextareaOLD from "../../components/inputs/TextareaOLD";
import OrderFile from "../../containers/OrderFile";
import Popup from "../../hoc/Popup";
import useProjectReferences from "../../hooks/data/useProjectReferences";
import { StopDraftForm } from "../../schemas/form";
import { StopDraft } from "../../shared/types/api";
import { stringToDate } from "../../shared/utility/date";
import { numberRegex } from "../../shared/utility/regex";
import { tourTimes } from "../../shared/values/dropdown";
import { STOP_DRAFT_TYPE } from "../../shared/values/enums";
import "./style.scss";
import { isAfter } from "date-fns";

type Props = {
    showPopup: boolean;
    onClose: () => void;
    onEdit: (data: { data: StopDraftForm; stopDraft: StopDraft }) => void;
    onDelete: (stopDraft: StopDraft) => void;
    isSaving: boolean;
    isDeleting: boolean;
    stopDraft: StopDraft | null;
    pairStopDraft: StopDraft | null;
};

function EditStopDraftPopup(props: Props) {
    const { t } = useTranslation();

    const { projectReferences } = useProjectReferences();

    const [toLocation, setToLocation] = useState<string>("");
    const [phone, setPhone] = useState("");
    const [cargoContent, setCargoContent] = useState("");
    const [estimatedOffloadTime, setEstimatedOffloadTime] = useState("");
    const [orderNumber, setOrderNumber] = useState("");
    const [contactId, setContactId] = useState<number | null>(null);
    const [time, setTime] = useState("");
    const [date, setDate] = useState<Date | null>(null);
    const [customerContactAt, setCustomerContactAt] = useState<
        "pickup" | "dropoff"
    >("dropoff");
    const [projectReference, setProjectReference] = useState("");
    const [weight, setWeight] = useState(0);

    useEffect(() => {
        if (!props.stopDraft) return;

        setToLocation(props.stopDraft.to_location);
        setPhone(props.stopDraft.phone || "");
        setCargoContent(props.stopDraft.cargo_content);
        setEstimatedOffloadTime(
            props.stopDraft.estimated_offload_time.toString()
        );
        setOrderNumber(props.stopDraft.order_number);
        setContactId(props.stopDraft.contact?.id || null);
        setTime(props.stopDraft.time_tooltip || "");
        setDate(
            props.stopDraft.date_tooltip
                ? stringToDate(props.stopDraft.date_tooltip)
                : null
        );
        setCustomerContactAt(props.stopDraft.customer_contact_at || "dropoff");
        setProjectReference(props.stopDraft.project_reference || "");
        setWeight(props.stopDraft.weight_kg || 0);
    }, [props.stopDraft]);

    useEffect(() => {
        if (!props.showPopup) {
            setToLocation("");
            setPhone("");
            setCargoContent("");
            setEstimatedOffloadTime("");
            setOrderNumber("");
            setContactId(null);
            setTime("");
            setDate(null);
            setCustomerContactAt("dropoff");
            setProjectReference("");
            setWeight(0);
        }
    }, [props.showPopup]);

    const isTimeDisabled = useCallback(
        ({
            time,
            compareTime,
            type,
        }: {
            time: string;
            compareTime: string;
            type: "pickup" | "dropoff";
        }) => {
            if (type === "pickup") {
                return isAfter(
                    new Date("2020-01-01T" + time),
                    new Date("2020-01-01T" + compareTime)
                );
            } else {
                return isAfter(
                    new Date("2020-01-01T" + compareTime),
                    new Date("2020-01-01T" + time)
                );
            }
        },
        []
    );

    return !props.stopDraft ? null : (
        <Popup
            showPopup={props.showPopup}
            onClose={props.onClose}
            title={t("popup.editStop.title")}
            dontCloseOnOutsideClick
            overlayComponent={
                props.stopDraft.file_name ? (
                    <OrderFile filename={props.stopDraft.file_name} />
                ) : undefined
            }
        >
            <form
                className="edit-stop-draft-popup"
                onSubmit={(e) => e.preventDefault()}
            >
                <div className="row">
                    {props.stopDraft.stop_type_id ===
                    STOP_DRAFT_TYPE.Dropoff ? (
                        <AddressSearch
                            key={STOP_DRAFT_TYPE.Dropoff}
                            placeholder={t("createOrder.toAddressPlaceholder")}
                            label={t("createOrder.toAddressLabel")}
                            tooltip={t("createOrder.toAddressTooltip")}
                            onChange={setToLocation}
                            value={toLocation}
                            preselectedAddress={props.stopDraft.to_location}
                            width="100%"
                            type="dropoff"
                        />
                    ) : (
                        <AddressSearch
                            key={STOP_DRAFT_TYPE.Pickup}
                            placeholder={t(
                                "createOrder.fromAddressPlaceholder"
                            )}
                            label={t("createOrder.fromAddressLabel")}
                            tooltip={t("createOrder.fromAddressTooltip")}
                            onChange={setToLocation}
                            value={toLocation}
                            preselectedAddress={props.stopDraft.to_location}
                            width="100%"
                            type="pickup"
                        />
                    )}
                </div>
                <div className="row">
                    <InputOLD
                        type="text"
                        label={t("createTour.orderNumber")}
                        placeholder={t("createTour.orderNumberPlaceholder")}
                        value={orderNumber}
                        onChange={setOrderNumber}
                    />
                    <InputOLD
                        type="text"
                        placeholder={t("createTour.approxPlaceholder")}
                        label={t("createTour.approxMinutes")}
                        value={estimatedOffloadTime}
                        onChange={(v) => {
                            if (!numberRegex.test(v)) return;
                            setEstimatedOffloadTime(v);
                        }}
                    />
                    <InputOLD
                        type="tel"
                        label={t("createTour.phone")}
                        placeholder={t("createTour.phonePlaceholder")}
                        value={phone}
                        onChange={setPhone}
                        name="phone"
                    />
                </div>
                <div className="row">
                    <ExpoSlider
                        value={weight === 0 ? "" : weight.toString()}
                        onChange={(v) => setWeight(+v)}
                        steps={[5, 10, 50, 100]}
                        max={4500}
                        label={t("createTour.weight")}
                    />
                </div>
                <div className="row">
                    <ContactInput
                        value={contactId}
                        onSelect={(c) => {
                            setContactId(c?.id || null);
                            setPhone((state) => c?.phone || state);
                        }}
                        label={t("createTour.contact")}
                        placeholder={t("createTour.contactPlaceholder")}
                        width="100%"
                    />
                </div>
                <div className="row">
                    <div className="radio-wrapper">
                        <p
                            className="text-xs"
                            style={{ color: "var(--text-color-light)" }}
                        >
                            {t("createTour.customerContactAt")}
                        </p>
                        <RadioInput
                            checked={customerContactAt === "pickup"}
                            label={t("fleetPlanner.pickup")}
                            onClick={() => setCustomerContactAt("pickup")}
                        />
                        <RadioInput
                            checked={customerContactAt === "dropoff"}
                            label={t("fleetPlanner.dropoff")}
                            onClick={() => setCustomerContactAt("dropoff")}
                        />
                    </div>
                    <DropdownOLD
                        value={projectReference}
                        onSelect={({ value }) => setProjectReference(value)}
                        options={
                            projectReferences?.map((pr) => ({
                                value: pr.name,
                                label: pr.name,
                            })) || []
                        }
                        label={t("createTour.projectReference")}
                        placeholder={t(
                            "createTour.projectReferencePlaceholder"
                        )}
                        width="100%"
                        isCreatable
                        onCreate={setProjectReference}
                    />
                </div>
                <div className="row">
                    <DateInputOLD
                        label={t("createTour.date")}
                        value={date}
                        onChange={setDate}
                        width="100%"
                        minDateDays={0}
                        ignoreUnselectableDates
                    />
                    <DropdownOLD
                        label={
                            props.stopDraft.stop_type_id ===
                            STOP_DRAFT_TYPE.Pickup
                                ? t("createTour.pickupTime")
                                : t("createTour.dropoffTime")
                        }
                        options={[
                            {
                                label: t("createTour.deselectTime"),
                                value: "",
                            },
                            ...tourTimes,
                        ]}
                        value={time}
                        onSelect={({ value }) => setTime(value)}
                        isOptionDisabled={(o) =>
                            isTimeDisabled({
                                time: o.value,
                                compareTime:
                                    props.pairStopDraft?.time_tooltip || "",
                                type:
                                    props.stopDraft!.stop_type_id ===
                                    STOP_DRAFT_TYPE.Pickup
                                        ? "pickup"
                                        : "dropoff",
                            })
                        }
                        isSearchable
                        width="100%"
                    />
                </div>
                <div className="row">
                    <TextareaOLD
                        value={cargoContent}
                        onChange={setCargoContent}
                        placeholder={t("createTour.cargoPlaceholder")}
                        label={t("createTour.cargoContent")}
                        width="100%"
                    />
                </div>
                <div className="bottom">
                    <ButtonOLD
                        label={t("popup.editStop.delete")}
                        color="danger"
                        onClick={() => {
                            if (!props.stopDraft) return;
                            props.onDelete(props.stopDraft);
                        }}
                        isLoading={props.isDeleting}
                        noSubmit
                    />
                    <ButtonOLD
                        label={t("popup.editStop.submit")}
                        color="secondary"
                        onClick={() => {
                            if (!props.stopDraft) return;
                            props.onEdit({
                                data: {
                                    toLocation: toLocation || "",
                                    phone,
                                    cargoContent,
                                    estimatedOffloadTime,
                                    orderNumber,
                                    stopTypeId: props.stopDraft.stop_type.id,
                                    contactId: contactId,
                                    time,
                                    date,
                                    customerContactAt: customerContactAt,
                                    projectReference,
                                    weight,
                                },
                                stopDraft: props.stopDraft,
                            });
                        }}
                        isLoading={props.isSaving}
                    />
                </div>
            </form>
        </Popup>
    );
}

export default EditStopDraftPopup;
