import { Control, Controller } from "react-hook-form";
import { CargoForm } from "../../schemas";
import "../../style.scss";
import { useTranslation } from "react-i18next";
import TextareaOLD from "../../../../components/inputs/TextareaOLD";
import InputOLD from "../../../../components/inputs/InputOLD";

type Props = {
    control: Control<CargoForm>;
};

function CargoInputForm(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="fleet-manual-order-form">
            <p className="text-sm">{t("fleetManualOrder.cargo.title")}</p>

            <Controller
                control={props.control}
                name="weight"
                render={({ field: { value, onChange } }) => (
                    <InputOLD
                        type="number"
                        value={value === 0 ? "" : value.toString()}
                        onChange={(v) => onChange(+v)}
                        label={t("createTour.weight")}
                    />
                )}
            />
            <Controller
                control={props.control}
                name="weightOfHeaviestPackage"
                render={({ field: { value, onChange } }) => (
                    <InputOLD
                        type="number"
                        value={value === 0 ? "" : value.toString()}
                        onChange={(v) => onChange(+v)}
                        label={t("createOrder.heaviestPackageLabel")}
                    />
                )}
            />
            <Controller
                control={props.control}
                name="numberOfEuPallets"
                render={({ field: { value, onChange } }) => (
                    <InputOLD
                        type="number"
                        value={value === 0 ? "" : value.toString()}
                        onChange={(v) => onChange(+v)}
                        label={t("createOrder.numberOfEuPackagesLabel")}
                    />
                )}
            />
            <Controller
                control={props.control}
                name="cargoContent"
                render={({ field: { value, onChange } }) => (
                    <TextareaOLD
                        value={value}
                        onChange={onChange}
                        placeholder={t("createOrder.cargoContentPlaceholder")}
                        label={t("createOrder.cargoContentLabel")}
                        textareaStyle={{
                            minHeight: "160px",
                        }}
                    />
                )}
            />
            <Controller
                control={props.control}
                name="driverInstructions"
                render={({ field: { value, onChange } }) => (
                    <TextareaOLD
                        value={value}
                        onChange={onChange}
                        label={t("createTour.driverInstructions")}
                        placeholder={t(
                            "createTour.driverInstructionsPlaceholder"
                        )}
                        textareaStyle={{
                            minHeight: "160px",
                        }}
                    />
                )}
            />
        </div>
    );
}

export default CargoInputForm;
