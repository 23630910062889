import { useMemo } from "react";
import {
    calculatePercentageChange,
    convertToNumberWithSpaces,
} from "../../../shared/utility/misc";
import "./style.scss";

export type FigureProps = {
    title: string;
    value: number;
    compareValue?: number;
    invertColors?: boolean;
    unit?: string;
};

function Figure(props: FigureProps) {
    const change = useMemo(
        () => calculatePercentageChange(props.value, props.compareValue || 0),
        [props.compareValue, props.value]
    );

    const changeColor = useMemo(() => {
        if (props.invertColors) {
            return change > 0 ? "var(--error-color)" : "var(--text-color-alt)";
        }
        return change > 0 ? "var(--text-color-alt)" : "var(--error-color)";
    }, [change, props.invertColors]);

    return (
        <div className="figure">
            <p className="title">{props.title}</p>
            <p className="text-sm">
                {convertToNumberWithSpaces(props.value, props.unit)}
            </p>
            {!!change && !!props.compareValue ? (
                <p
                    className="text-xs change-container"
                    style={{
                        color: changeColor,
                    }}
                >
                    <span className="change">
                        {change > 0 ? "+" : ""}
                        {change.toFixed(1)}%
                    </span>
                    <span className="compare-value">
                        {convertToNumberWithSpaces(
                            props.compareValue,
                            props.unit
                        )}
                    </span>
                </p>
            ) : (
                <p>-</p>
            )}
        </div>
    );
}

export default Figure;
