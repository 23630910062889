import axios from "../shared/services/axios";
import {
    CreateExternalCarrierData,
    ExternalCarrier,
} from "../shared/types/api";

export function getExternalCarriers() {
    return axios.get<ExternalCarrier[]>("/carrier/external");
}

export function createExternalCarrier(data: CreateExternalCarrierData) {
    return axios.post<ExternalCarrier>("/carrier/external", data);
}

export function updateExternalCarrier(data: ExternalCarrier) {
    return axios.put<ExternalCarrier>("/carrier/external", data);
}

export function deleteExternalCarrier(id: number) {
    return axios.delete(`/carrier/external/${id}`);
}
