import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import "./style.scss";

type Props = {
    label: string;
    color?: string;
    isCompleted?: boolean;
};

function StopCardIcon(props: Props) {
    return (
        <div
            className="stop-card-icon"
            style={
                props.color
                    ? { backgroundColor: props.color }
                    : { backgroundColor: "var(--color-neutral-100)" }
            }
        >
            {props.isCompleted ? (
                <FontAwesomeIcon
                    icon={faCheck}
                    color="var(--color-pure-white)"
                />
            ) : (
                <p className="text-xs">{props.label}</p>
            )}
        </div>
    );
}

export default StopCardIcon;
