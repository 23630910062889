import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "../../components/buttons/Button";
import { StopDraft, StopDraftsTour } from "../../shared/types/api";
import { KeyString } from "../../shared/types/internal";
import { ReduxState } from "../../shared/types/redux";
import { DEFAULT_CENTER, NEW_TOUR_COLUMN_ID } from "../../shared/values/enums";
import { GOOGLE_MAP_IDS } from "../../shared/values/google-map-ids";
import Map from "../Map";
import TourMarkers from "../markers/TourMarkers";
import "./style.scss";

type Props = {
    tours: StopDraftsTour[];
    stops?: StopDraft[];
    tourColorMap?: KeyString<string>;
};

function ToursMap(props: Props) {
    const { t } = useTranslation();
    const { user } = useSelector((state: ReduxState) => state.auth);
    const [focusedTourId, setFocusedTourId] = useState<number | null>(null);

    return (
        <div className="tours-map">
            <div className="controls">
                {props.stops && props.stops.length > 0 && (
                    <Button
                        key={"stops"}
                        variant={"secondary"}
                        label={t("bigVolume.plannedStops")}
                        onClick={() => setFocusedTourId(-1)}
                        leadingIcon={faCircle}
                        leadingIconColor={
                            props.tourColorMap?.[NEW_TOUR_COLUMN_ID]
                        }
                    />
                )}

                {props.tours.map((tour, i) => (
                    <Button
                        key={tour.tour_id}
                        variant={"secondary"}
                        label={tour.preferred_driver}
                        onClick={() => setFocusedTourId(tour.tour_id)}
                        leadingIcon={faCircle}
                        leadingIconColor={props.tourColorMap?.[tour.tour_id]}
                    />
                ))}
            </div>
            <Map
                mapId={GOOGLE_MAP_IDS.ToursMap}
                defaultCenter={
                    user?.location_entity?.lat && user?.location_entity?.lng
                        ? {
                              lat: user.location_entity.lat,
                              lng: user.location_entity.lng,
                          }
                        : DEFAULT_CENTER
                }
                defaultZoom={12}
                showTrafficToggle
            >
                {props.tours.map((tour, i) => (
                    <TourMarkers
                        key={tour.tour_id}
                        tour={tour}
                        disableFitBounds={focusedTourId !== tour.tour_id}
                        boundsPadding={50}
                        tourColor={props.tourColorMap?.[tour.tour_id]}
                    />
                ))}

                {props.stops && (
                    <TourMarkers
                        key={"stops"}
                        stops={props.stops}
                        disableFitBounds={focusedTourId !== -1}
                        boundsPadding={50}
                        tourColor={props.tourColorMap?.[NEW_TOUR_COLUMN_ID]}
                    />
                )}
            </Map>
        </div>
    );
}

export default ToursMap;
