import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { ZodError } from "zod";
import ButtonOLD from "../../components/buttons/ButtonOLD";
import IconButton from "../../components/buttons/IconButton";
import DropdownOLD from "../../components/inputs/DropdownOLD";
import InputOLD from "../../components/inputs/InputOLD";
import Popup from "../../hoc/Popup";
import {
    AdditionalInfoForm,
    CargoDimensionsForm,
    ConsigneeForm,
    additionalInfoFormSchema,
} from "../../schemas/form";
import { numberRegex } from "../../shared/utility/regex";
import {
    heightOptions,
    lengthOptions,
    weightOptions,
    widthOptions,
} from "../../shared/values/dropdown";
import "./style.scss";

type Props = {
    showPopup: boolean;
    onClose: () => void;
    onSubmit: (data: AdditionalInfoForm) => void;
    orderAdditionalInfo: AdditionalInfoForm;
};

function OrderAdditionalInfoPopup(props: Props) {
    const { t } = useTranslation();

    const { mutate: handleSubmit } = useMutation({
        mutationFn: async (values: AdditionalInfoForm) => {
            const formattedContacts = values?.contacts?.filter(
                (contact) => !!contact.consigneeName || !!contact.consigneePhone
            );
            const formattedCargoDimensions = values?.cargoDimensions?.filter(
                (cargoDimension) =>
                    !!cargoDimension.length ||
                    !!cargoDimension.width ||
                    !!cargoDimension.height ||
                    !!cargoDimension.weight
            );

            return additionalInfoFormSchema.parse({
                ...values,
                contacts: formattedContacts?.length
                    ? formattedContacts
                    : undefined,
                cargoDimensions: formattedCargoDimensions?.length
                    ? formattedCargoDimensions
                    : undefined,
            });
        },
        onSuccess: (data) => {
            props.onSubmit(data);
        },
        onError: (error: ZodError) => {
            //only print the first error to not overwhelm the user
            toast.error(t(error.issues[0].message));
        },
    });

    const [consigneeCompany, setConsigneeCompany] = useState("");
    const [estimatedOffloadTime, setEstimatedOffloadTime] = useState("");
    const [contacts, setContacts] = useState<ConsigneeForm[]>([
        {
            consigneeName: "",
            consigneePhone: "",
        },
    ]);
    const [offloadInstructions, setOffloadInstructions] = useState("");
    const [cargoDimensions, setCargoDimensions] = useState<
        CargoDimensionsForm[]
    >([
        {
            length: "",
            width: "",
            height: "",
            weight: "",
        },
    ]);
    const [branchNumber, setBranchNumber] = useState("");
    const [invoiceNumber, setInvoiceNumber] = useState("");

    useEffect(() => {
        if (props.orderAdditionalInfo === null) {
            resetInputs();
        }
        if (props.orderAdditionalInfo) {
            setConsigneeCompany(
                props.orderAdditionalInfo.consigneeCompany || ""
            );
            setEstimatedOffloadTime(
                props.orderAdditionalInfo.estimatedOffloadTime?.toString() || ""
            );
            setContacts(
                props.orderAdditionalInfo.contacts || [
                    {
                        consigneeName: "",
                        consigneePhone: "",
                    },
                ]
            );
            setOffloadInstructions(
                props.orderAdditionalInfo.offloadInstructions || ""
            );
            setCargoDimensions(
                props.orderAdditionalInfo.cargoDimensions || [
                    {
                        length: "",
                        width: "",
                        height: "",
                        weight: "",
                    },
                ]
            );
            setBranchNumber(props.orderAdditionalInfo.branchNumber || "");
            setInvoiceNumber(props.orderAdditionalInfo.invoiceNumber || "");
        }
    }, [props.orderAdditionalInfo]);

    function resetInputs() {
        setConsigneeCompany("");
        setEstimatedOffloadTime("");
        setContacts([
            {
                consigneeName: "",
                consigneePhone: "",
            },
        ]);
        setOffloadInstructions("");
        setCargoDimensions([
            {
                length: "",
                width: "",
                height: "",
                weight: "",
            },
        ]);
        setBranchNumber("");
        setInvoiceNumber("");
    }

    function addContact() {
        setContacts((state) => [
            ...state,
            { consigneeName: "", consigneePhone: "" },
        ]);
    }

    function removeContact(index: number) {
        setContacts((state) => {
            const newContacts = [...state];
            newContacts.splice(index, 1);
            return newContacts;
        });
    }

    function setContact(contact: ConsigneeForm, index: number) {
        setContacts((state) => {
            const newContacts = [...state];
            newContacts[index] = contact;
            return newContacts;
        });
    }

    function addCargoDimensions() {
        setCargoDimensions((state) => [
            ...state,
            { length: "", width: "", height: "", weight: "" },
        ]);
    }

    function removeCargoDimensions(index: number) {
        setCargoDimensions((state) => {
            const newCargoDimensions = [...state];
            newCargoDimensions.splice(index, 1);
            return newCargoDimensions;
        });
    }

    function setCargoDimension(
        cargoDimension: CargoDimensionsForm,
        index: number
    ) {
        setCargoDimensions((state) => {
            const newCargoDimensions = [...state];
            newCargoDimensions[index] = cargoDimension;
            return newCargoDimensions;
        });
    }

    return (
        <Popup
            showPopup={props.showPopup}
            onClose={props.onClose}
            title={t("popup.additionalInfo.title")}
            dontCloseOnOutsideClick
        >
            <form
                className="order-additional-info-popup"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit({
                        consigneeCompany,
                        estimatedOffloadTime: estimatedOffloadTime
                            ? +estimatedOffloadTime
                            : undefined,
                        contacts,
                        offloadInstructions,
                        cargoDimensions,
                        branchNumber: branchNumber || undefined,
                        invoiceNumber,
                        sideLoading: undefined,
                    });
                }}
            >
                <InputOLD
                    label={t("createOrder.additionalInfoCompany")}
                    type="text"
                    value={consigneeCompany}
                    onChange={setConsigneeCompany}
                />
                <InputOLD
                    label={t("createOrder.additionalInfoUnloading")}
                    type="text"
                    value={estimatedOffloadTime}
                    onChange={(v) => {
                        if (!numberRegex.test(v)) return;
                        setEstimatedOffloadTime(v);
                    }}
                    width="50%"
                />

                <section>
                    <span className="text-xs">
                        {t("createOrder.additionalInfoAdditionalContact")}
                    </span>
                    {contacts.map((contact, index) => (
                        <div className="row" key={index}>
                            <InputOLD
                                type={"text"}
                                placeholder={t(
                                    "createOrder.additionalInfoNamePlaceholder"
                                )}
                                value={contact.consigneeName}
                                onChange={(value) =>
                                    setContact(
                                        {
                                            ...contact,
                                            consigneeName: value,
                                        },
                                        index
                                    )
                                }
                                width="100%"
                            />
                            <InputOLD
                                type={"tel"}
                                value={contact.consigneePhone}
                                placeholder={t("createOrder.phonePlaceholder")}
                                onChange={(value) =>
                                    setContact(
                                        {
                                            ...contact,
                                            consigneePhone: value,
                                        },
                                        index
                                    )
                                }
                                width="100%"
                                name="phone"
                            />
                            <IconButton
                                icon={"cross"}
                                onClick={() => removeContact(index)}
                                noSubmit
                            />
                        </div>
                    ))}
                    <IconButton icon="plus" onClick={addContact} noSubmit />
                </section>
                <InputOLD
                    label={t("createOrder.additionalInfoSpecificDropoff")}
                    type="text"
                    value={offloadInstructions}
                    onChange={setOffloadInstructions}
                />

                <section>
                    <span className="text-xs">
                        {t("createOrder.additionalInfoTotalCargo")}
                    </span>
                    {cargoDimensions.map((cargoDimension, index) => (
                        <div className="row" key={index}>
                            <DropdownOLD
                                width="100%"
                                placeholder={t(
                                    "createOrder.additionalInfoDropdownLengthLabel"
                                )}
                                menuPlacement="top"
                                value={cargoDimension.length}
                                onSelect={(option) =>
                                    setCargoDimension(
                                        {
                                            ...cargoDimension,
                                            length: option.value,
                                        },
                                        index
                                    )
                                }
                                options={lengthOptions}
                            />
                            <DropdownOLD
                                width="100%"
                                placeholder={t(
                                    "createOrder.additionalInfoDropdownWidthLabel"
                                )}
                                menuPlacement="top"
                                value={cargoDimension.width}
                                onSelect={(option) =>
                                    setCargoDimension(
                                        {
                                            ...cargoDimension,
                                            width: option.value,
                                        },
                                        index
                                    )
                                }
                                options={widthOptions}
                            />
                            <DropdownOLD
                                width="100%"
                                placeholder={t(
                                    "createOrder.additionalInfoDropdownHeightLabel"
                                )}
                                menuPlacement="top"
                                value={cargoDimension.height}
                                onSelect={(option) =>
                                    setCargoDimension(
                                        {
                                            ...cargoDimension,
                                            height: option.value,
                                        },
                                        index
                                    )
                                }
                                options={heightOptions}
                            />
                            <DropdownOLD
                                width="100%"
                                placeholder={t(
                                    "createOrder.additionalInfoDropdownWeightLabel"
                                )}
                                menuPlacement="top"
                                value={cargoDimension.weight}
                                onSelect={(option) =>
                                    setCargoDimension(
                                        {
                                            ...cargoDimension,
                                            weight: option.value,
                                        },
                                        index
                                    )
                                }
                                options={weightOptions}
                            />
                            <IconButton
                                icon={"cross"}
                                onClick={() => removeCargoDimensions(index)}
                                noSubmit
                            />
                        </div>
                    ))}
                    <IconButton
                        icon="plus"
                        onClick={addCargoDimensions}
                        noSubmit
                    />
                </section>
                <section>
                    <div className="row">
                        <InputOLD
                            label={t("createOrder.additionalInfoBranchNumber")}
                            type="text"
                            value={branchNumber}
                            onChange={setBranchNumber}
                            width="50%"
                        />
                        <InputOLD
                            label={t(
                                "createOrder.additionalInfoMarkingInvoice"
                            )}
                            type="text"
                            value={invoiceNumber}
                            onChange={setInvoiceNumber}
                            width="50%"
                        />
                    </div>
                </section>
                <ButtonOLD
                    label={t("popup.additionalInfo.submit")}
                    color={"secondary"}
                    width="50%"
                />
            </form>
        </Popup>
    );
}

export default OrderAdditionalInfoPopup;
