import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addDays, format, getUnixTime, isWeekend, startOfDay } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { dateToString } from "../../../shared/utility/date";
import { getLocale } from "../../../shared/utility/misc";
import "./style.scss";

type Props = {
    value: Date | null;
    onChange: (value: Date | null) => void;
    minDateDays?: number;
    maxDateDays?: number;
    placeholder?: string | null;
    width?: string;
    unselectableDates?: string[];
    ignoreUnselectableDates?: boolean;
    showAllDates?: boolean;
    hideWeekNumbers?: boolean;
    label?: string;
    labelColor?: string;
};

function DateInput(props: Props) {
    const { t } = useTranslation();

    function getMinDate() {
        if (props.showAllDates) {
            return new Date(0);
        }

        return addDays(
            startOfDay(new Date()),
            props.minDateDays === undefined ? 1 : props.minDateDays
        );
    }

    function getMaxDate() {
        if (props.showAllDates) {
            //Feb 24 274493
            return new Date(8600000000000000);
        }

        return addDays(
            startOfDay(new Date()),
            props.maxDateDays === undefined ? 365 * 10 : props.maxDateDays
        );
    }

    function selectHandler(date: Date | null) {
        if (date === null) {
            props.onChange(null);
            return;
        }

        const minDate = getMinDate();

        if (getUnixTime(date) >= getUnixTime(minDate)) {
            props.onChange(date);
        } else {
            props.onChange(null);
            toast.error(
                `${t("errorMessage.badDate")} ${format(minDate, "yyyy-MM-dd")} `
            );
        }
    }

    return (
        <div
            className="date-picker"
            style={{
                width: props.width,
            }}
        >
            {props.label && (
                <p
                    className="text-xs"
                    style={{
                        color: props.labelColor || "var(--color-neutral-500)",
                    }}
                >
                    {props.label}
                </p>
            )}
            <div className="date-picker-wrapper">
                <DatePicker
                    className="date-picker-input"
                    selected={props.value}
                    onChange={(date) => selectHandler(date)}
                    placeholderText={props.placeholder || undefined}
                    dateFormat="yyyy-MM-dd"
                    minDate={getMinDate()}
                    maxDate={getMaxDate()}
                    calendarClassName="date-input-calendar"
                    wrapperClassName="date-input-wrapper-desktop"
                    autoComplete="off"
                    locale={getLocale()}
                    calendarStartDay={1}
                    filterDate={
                        props.ignoreUnselectableDates
                            ? undefined
                            : (date) =>
                                  !isWeekend(date) &&
                                  !props.unselectableDates?.includes(
                                      dateToString(date)
                                  )
                    }
                    disabledKeyboardNavigation={true}
                    showWeekNumbers={!props.hideWeekNumbers}
                    popperModifiers={[]}
                    popperPlacement="bottom-start"
                />
                <FontAwesomeIcon
                    icon={faCalendar}
                    size="lg"
                    color="var(--color-neutral-400)"
                />
            </div>
        </div>
    );
}

export default DateInput;
