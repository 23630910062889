import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Carousel from "../../components/widgets/Carousel";
import { ROUTE } from "../../shared/values/enums";
import "./style.scss";

type Props = {
    children: React.ReactNode;
};

function NonAuthLayout(props: Props) {
    const { t } = useTranslation();
    const location = useLocation();

    const shouldHideCarousel =
        location.pathname.includes(ROUTE.PublicOrder) ||
        location.pathname.includes(ROUTE.ExternalOrder) ||
        location.pathname.includes(ROUTE.TrackingRedirect) ||
        location.pathname.includes(ROUTE.Tracking);

    return (
        <div
            className="non-auth-layout"
            style={{
                padding: shouldHideCarousel ? "10px" : undefined,
            }}
        >
            <div className="content">{props.children}</div>
            {!shouldHideCarousel && (
                <div className="background">
                    <Carousel
                        pages={[
                            <div className="carousel-item">
                                <p className="title">
                                    {t("onboarding.carousel.0.title")}
                                </p>
                                <p className="text">
                                    {t("onboarding.carousel.0.text")}
                                </p>
                            </div>,
                            <div className="carousel-item">
                                <p className="title">
                                    {t("onboarding.carousel.1.title")}
                                </p>
                                <p className="text">
                                    {t("onboarding.carousel.1.text")}
                                </p>
                            </div>,
                            <div className="carousel-item">
                                <p className="title">
                                    {t("onboarding.carousel.2.title")}
                                </p>
                                <p className="text">
                                    {t("onboarding.carousel.2.text")}
                                </p>
                            </div>,
                        ]}
                    />
                </div>
            )}
        </div>
    );
}

export default NonAuthLayout;
