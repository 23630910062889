import { AxiosRequestConfig } from "axios";
import axios from "../shared/services/axios";
import {
    CalendarItem,
    StatsCsvExport,
    LocationStatsData,
    Order,
    StopDraftsTour,
    SmsSettings,
    StopDraft,
} from "../shared/types/api";
import { KeyString } from "../shared/types/internal";
import { API_MAX_PER_PAGE, STOP_DRAFT_TYPE } from "../shared/values/enums";

export function archiveStopDraftTour(tourId: number) {
    return axios.get<string>("/stop_drafts/tours/archive", {
        params: {
            tour_ids: tourId,
        },
    });
}

export function getFulfilledTourOrders({
    searchType,
    searchString,
    page,
    size = API_MAX_PER_PAGE.Orders,
}: {
    searchString?: string;
    searchType?: string;
    page: number;
    size?: number;
}) {
    const params: AxiosRequestConfig["params"] = { page, size };

    if (searchType && searchString) {
        params[searchType] = searchString;
    }

    return axios.get<{
        items: Order[];
        total: number;
        page: number;
        size: number;
        pages: number;
    }>("/v2/tours/orders/fulfilled", {
        params: params,
    });
}

export function uploadFile(file: File) {
    const formData = new FormData();
    formData.append("file", file);

    return axios.post<{ filename: string }>("/upload", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export function getFilenameUrl(filename: string) {
    return axios.get<{
        url: string;
        type: string;
    }>(`/upload/url`, {
        headers: {
            filename: filename,
        },
    });
}

export function getStats({
    startDate,
    endDate,
}: {
    startDate: string;
    endDate: string;
}) {
    return axios.get<LocationStatsData>("/stats/orders", {
        params: {
            start_date: startDate,
            end_date: endDate,
        },
    });
}

export function getStatsCsv({
    startDate,
    endDate,
}: {
    startDate: string;
    endDate: string;
}) {
    return axios.get<StatsCsvExport>("/stats/csv", {
        params: {
            start_date: startDate,
            end_date: endDate,
        },
    });
}

export function getCalendar({
    startDate,
    endDate,
}: {
    startDate: string;
    endDate: string;
}) {
    return axios.get<KeyString<CalendarItem>>("v2/calendar", {
        params: {
            start_date: startDate,
            end_date: endDate,
        },
    });
}

export function getActiveTours(scheduledDate?: string) {
    return axios.get<StopDraftsTour[]>("/v3/tours/active", {
        params: {
            scheduled_date: scheduledDate,
        },
    });
}

export function createTour(data: {
    stop_draft_ids: number[];
    preferred_driver_id: string;
    date: string;
    time: string;
}) {
    return axios.post<{
        status: string;
        tour_id: number;
        excluded_stop_draft_ids: number[];
    }>("/v3/tour/create", data);
}

export function reorderTour(data: {
    tour_id: number;
    stop_draft_ids: number[];
}) {
    return axios.post<string>("/v3/tour/reorder", data);
}

export function addStopsToTour(data: {
    tour_id: number;
    stop_draft_ids: number[];
    index: number;
}) {
    return axios.post<string>("/v3/tour/add", data);
}

export function removeStopFromTour(stopDraftId: number) {
    return axios.put<string>("/v3/stop_draft/detach", {
        stop_draft_id: stopDraftId,
    });
}

export async function removeStopFromTourDegrouping(
    stopToRemove: StopDraft,
    tour: StopDraftsTour
) {
    const pickup = tour.stops.find(
        (stop) =>
            stop.stop_type_id === STOP_DRAFT_TYPE.Pickup &&
            stop.group_id === stopToRemove.group_id
    );

    const dropoff = tour.stops.find(
        (stop) =>
            stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff &&
            stop.group_id === stopToRemove.group_id
    );

    const stopsToDegroup: StopDraft[] = [];

    if (pickup?.motion_tools_stop_group) {
        const groupedPickups = tour.stops.filter(
            (stop) =>
                stop.motion_tools_stop_group === pickup.motion_tools_stop_group
        );

        // If there are only two pickups in the group, we need to remove the group
        if (groupedPickups.length <= 2) {
            stopsToDegroup.push(...groupedPickups);
        }
    }

    if (dropoff?.motion_tools_stop_group) {
        const groupedDropoffs = tour.stops.filter(
            (stop) =>
                stop.motion_tools_stop_group === dropoff.motion_tools_stop_group
        );

        // If there are only two dropoffs in the group, we need to remove the group
        if (groupedDropoffs.length <= 2) {
            stopsToDegroup.push(...groupedDropoffs);
        }
    }

    if (!!stopsToDegroup.length) {
        await groupStops(
            stopsToDegroup.map((sd) => ({ stop_draft_id: sd.id, group: null }))
        );
    }

    return removeStopFromTour(stopToRemove.id);
}

export function dispatchTour(tourId: number) {
    return axios.post<string>("/v3/tour/dispatch", {
        tour_id: tourId,
    });
}

export function groupStops(
    stops: {
        stop_draft_id: number;
        group: string | null;
    }[]
) {
    return axios.put<string>("/v3/tour/group", {
        stops,
    });
}

export function getSmsSettings() {
    return axios.get<SmsSettings>("/sms/settings");
}

export function updateSmsSettings(data: SmsSettings) {
    return axios.put<string>("/sms/settings", data);
}

export function updateTour(data: {
    tour_id: number;
    date: string;
    time: string;
    preferred_driver_id: string;
}) {
    return axios.post<string>("/v3/tour/update", data);
}
