import { useInfiniteQuery } from "@tanstack/react-query";
import { getActiveOrders, getFulfilledOrders } from "../../api/order";
import { OrderMode } from "../../shared/types/internal";
import { API_MAX_PER_PAGE } from "../../shared/values/enums";

function useInfiniteOrders(
    orderMode: OrderMode,
    searchString?: string,
    searchType?: string
) {
    const {
        data: orders,
        fetchNextPage,
        hasNextPage,
        status,
    } = useInfiniteQuery({
        initialPageParam: 1,
        queryKey: ["orders", orderMode, searchType, searchString] as const,
        queryFn: async ({
            queryKey: [, mode, searchType, searchString],
            pageParam,
        }) => {
            if (mode === "active") {
                const res = await getActiveOrders({
                    page: pageParam,
                });
                return res.data.items;
            } else if (mode === "fulfilled") {
                const res = await getFulfilledOrders({
                    searchType: searchType,
                    searchString: searchString,
                    page: pageParam,
                });
                return res.data.items;
            } else {
                return [];
            }
        },
        getNextPageParam: (lastPage, pages) =>
            lastPage.length < API_MAX_PER_PAGE.Orders
                ? undefined
                : pages.length + 1,
    });

    switch (status) {
        case "success":
            return {
                orders,
                fetchNextPage,
                hasNextPage,
                status,
            };
        case "pending":
            return {
                orders,
                fetchNextPage,
                hasNextPage,
                status,
            };
        case "error":
            return {
                orders,
                fetchNextPage,
                hasNextPage,
                status,
            };
    }
}

export default useInfiniteOrders;
