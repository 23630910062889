import { useTranslation } from "react-i18next";
import { OrderType } from "../../shared/types/internal";

function useOrderSearchOptions(orderType: OrderType) {
    const { t } = useTranslation();
    const options = [
        {
            label: t("orderHistory.orderNumber"),
            value: "origin_order_id",
        },
        {
            label: t("orderHistory.address"),
            value: "address",
        },
        {
            label: t("orderHistory.created"),
            value: "created_date",
        },
        {
            label: t("orderHistory.fulfilledTime"),
            value: "fulfilled_date",
        },
    ];

    if (orderType === "fleet-planner") {
        options.splice(1, 0, {
            label: t("orderHistory.contactName"),
            value: "contact_name",
        });
    }

    return options;
}

export default useOrderSearchOptions;
