import Spinner from "../../components/UI/Spinner";
import useFileUrl from "../../hooks/data/useFileUrl";
import { FILE_TYPE } from "../../shared/values/enums";
import "./style.scss";

type Props = {
    filename: string;
};

function OrderFile(props: Props) {
    const { fileData, isFetching: isFetchingFileUrl } = useFileUrl(
        props.filename
    );

    const isPdf = fileData?.type === FILE_TYPE.pdf;

    return (
        <div className="order-file">
            {isFetchingFileUrl ? (
                <Spinner />
            ) : fileData ? (
                isPdf ? (
                    <object
                        data={fileData.url}
                        type="application/pdf"
                        width="100%"
                        height="100%"
                    >
                        pdf_file
                    </object>
                ) : (
                    <img src={fileData.url} alt="order file" />
                )
            ) : (
                <p>File not found</p>
            )}
        </div>
    );
}

export default OrderFile;
