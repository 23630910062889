import { useState } from "react";
import { useTranslation } from "react-i18next";
import IconOLD from "../../components/UI/IconOLD";
import TextButtonOLD from "../../components/buttons/TextButtonOLD";
import FaqRow from "../../components/widgets/FaqRow";
import { FaqSection, faqSections } from "../../shared/types/internal";
import { getVersion } from "../../shared/utility/misc";
import "./style.scss";

type Props = {};

function Support(props: Props) {
    const { t } = useTranslation();

    const [faqSection, setFaqSection] = useState<FaqSection>(faqSections[0]);

    const faqs: {
        question: string;
        answer: string;
    }[] = t([`support.${faqSection}.faqs`], {
        returnObjects: true,
    }) as { question: string; answer: string }[];

    return (
        <div className="support">
            <div className="support-buttons">
                <a className="phone-support" href="tel:010-880 07 84">
                    <p className="text-sm">{t("support.phoneSupport")}</p>
                    <div className="icon-wrapper">
                        <IconOLD type="phone" />
                    </div>
                    <p className="text-sm">010-880 07 84</p>
                </a>
                <a className="email-support" href="mailto:order@alrik.com">
                    <p className="text-sm">{t("support.emailSupport")}</p>
                    <div className="icon-wrapper">
                        <IconOLD type="paper-plane" />
                    </div>
                    <p className="text-sm">order@alrik.com</p>
                </a>
            </div>
            <div className="faq-sections">
                {faqSections.map((fs) => (
                    <TextButtonOLD
                        key={fs}
                        label={t(`support.${fs}.buttonLabel`)}
                        onClick={() => setFaqSection(fs)}
                        color={
                            faqSection === fs
                                ? "var(--text-color)"
                                : "var(--text-color-light)"
                        }
                        className="text-sm"
                        style={{ textDecoration: "none" }}
                    />
                ))}
                <a
                    href="https://storage.googleapis.com/alrik-connect-images/transport-policy.pdf"
                    target="_blank"
                    rel="noreferrer"
                >
                    <TextButtonOLD
                        label={t("support.transportPolicy")}
                        noSumbit
                        color="var(--text-color)"
                        className="text-sm"
                    />
                </a>
                <p style={{ color: "var(--text-color-light)" }}>
                    v{getVersion()}
                </p>
            </div>
            <div className="faqs">
                {faqs.map((faq) =>
                    faq.question && faq.answer ? (
                        <FaqRow
                            key={faq.question}
                            question={faq.question}
                            answer={faq.answer}
                        />
                    ) : null
                )}
            </div>
        </div>
    );
}

export default Support;
