import { useQuery } from "@tanstack/react-query";
import { getUsersInCompany } from "../../api/auth";
import { useSelector } from "react-redux";
import { ReduxState } from "../../shared/types/redux";

function useUsersInCompany() {
    const { user } = useSelector((state: ReduxState) => state.auth);

    const {
        data: users,
        status,
        refetch,
        isFetching,
    } = useQuery({
        queryKey: ["users-in-company"],
        queryFn: async () => {
            const res = await getUsersInCompany();
            return res.data.users;
        },
        enabled: !!user?.company_entity?.show_location_orders,
    });

    switch (status) {
        case "success":
            return {
                users,
                status,
                refetch,
                isFetching,
            };
        case "pending":
            return {
                users,
                status,
                refetch,
                isFetching,
            };
        case "error":
            return {
                users,
                status,
                refetch,
                isFetching,
            };
    }
}

export default useUsersInCompany;
