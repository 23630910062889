import { useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "../../components/UI/Spinner";
import ShowingAllMessage from "../../components/UI/ShowingAllMessage";
import OrdersSearch from "../../containers/OrdersSearch";
import NoOrders from "../../fleet-on-demand/NoOrders";
import OrdersTable from "../../fleet-on-demand/OrdersTable";
import useInfiniteOrders from "../../hooks/data/useInfiniteOrders";
import useOrderSearchOptions from "../../hooks/functionality/useOrderSearchOptions";
import { useOrdersRoute } from "../../hooks/route/useOrdersRoute";
import { Order } from "../../shared/types/api";
import "./style.scss";

type Props = {};

function FulfilledOrders(props: Props) {
    const { t } = useTranslation();
    const orderSearchOptions = useOrderSearchOptions("fleet-on-demand");
    const { setSelectedOrder } = useOrdersRoute();

    const [searchString, setSearchString] = useState("");
    const [searchType, setSearchType] = useState(orderSearchOptions[0].value);

    const { orders, fetchNextPage, hasNextPage, status } = useInfiniteOrders(
        "fulfilled",
        searchString,
        searchType
    );

    let flatOrders: Order[] = [];
    if (status === "success") {
        flatOrders = orders.pages.flat();
    }

    return (
        <div className="fulfilled-orders">
            <OrdersSearch
                orderType={"fleet-on-demand"}
                searchString={searchString}
                searchType={searchType}
                onSearchStringChange={setSearchString}
                onSearchTypeChange={setSearchType}
            />
            <div className="content">
                {status !== "success" && <Spinner padding="10px" />}
                {status === "success" && !flatOrders.length && (
                    <NoOrders mode="fulfilled" />
                )}
                {status === "success" && !!flatOrders.length && (
                    <InfiniteScroll
                        dataLength={orders.pages.flat().length}
                        next={fetchNextPage}
                        hasMore={!!hasNextPage}
                        loader={<Spinner padding="10px" />}
                        endMessage={
                            <ShowingAllMessage
                                text={t("orderHistory.showingAllOrders")}
                            />
                        }
                        scrollThreshold={0.5}
                    >
                        <OrdersTable
                            orders={orders.pages.flat()}
                            onOrderClick={setSelectedOrder}
                            mode="fulfilled"
                        />
                    </InfiniteScroll>
                )}
            </div>
        </div>
    );
}

export default FulfilledOrders;
