import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/buttons/Button";
import Badge from "../../components/UI/Badge";
import OrderFile from "../../containers/OrderFile";
import Popup from "../../hoc/Popup";
import { StopDraft } from "../../shared/types/api";
import { formatDateString } from "../../shared/utility/date";
import { convertToNumberWithSpaces } from "../../shared/utility/misc";
import { faTag } from "@fortawesome/pro-regular-svg-icons";
import "./style.scss";

type Props = {
    showPopup: boolean;
    onClose: () => void;
    stops: {
        pickup: StopDraft;
        dropoff: StopDraft;
    };
    onEditStops?: (data: { pickup: StopDraft; dropoff: StopDraft }) => void;
    onDetachStops?: (data: { pickup: StopDraft; dropoff: StopDraft }) => void;
    onAssignOutsideFleet?: (data: {
        pickup: StopDraft;
        dropoff: StopDraft;
    }) => void;
    onAssignAlrik?: (data: { pickup: StopDraft; dropoff: StopDraft }) => void;
    onChangedLocation?: (data: {
        pickup: StopDraft;
        dropoff: StopDraft;
    }) => void;
};

function StopsInfoPopup(props: Props) {
    const { t } = useTranslation();

    const sections = useMemo(() => {
        return [
            [
                {
                    label: t("popup.stopsInfo.date"),
                    value: props.stops.pickup.date_tooltip || "-",
                },
            ],
            [
                {
                    label: t("popup.stopsInfo.pickupAddress"),
                    value: props.stops.pickup.to_location,
                },
                {
                    label: t("popup.stopsInfo.pickupContact"),
                    value: props.stops.pickup.contact?.name || "-",
                },
                {
                    label: t("popup.stopsInfo.pickupTime"),
                    value: props.stops.pickup.time_tooltip || "-",
                },
            ],
            [
                {
                    label: t("popup.stopsInfo.dropoffAddress"),
                    value: props.stops.dropoff.to_location,
                },
                {
                    label: t("popup.stopsInfo.dropoffContact"),
                    value: props.stops.dropoff.contact?.name || "-",
                },
                {
                    label: t("popup.stopsInfo.dropoffTime"),
                    value: props.stops.dropoff.time_tooltip || "-",
                },
            ],
            [
                {
                    label: t("popup.stopsInfo.estimatedOffloadTime"),
                    value: convertToNumberWithSpaces(
                        props.stops.pickup.estimated_offload_time,
                        "min"
                    ),
                },
                {
                    label: t("popup.stopsInfo.weight"),
                    value: props.stops.pickup.weight_kg
                        ? convertToNumberWithSpaces(
                              props.stops.pickup.weight_kg,
                              "kg"
                          )
                        : "-",
                },
            ],
            [
                {
                    label: t("popup.stopsInfo.cargoContent"),
                    value: props.stops.pickup.cargo_content,
                },
            ],
            [
                {
                    label: t("popup.stopsInfo.driverInstructions"),
                    value: props.stops.pickup.driver_instructions || "-",
                },
            ],
        ];
    }, [t, props.stops.pickup, props.stops.dropoff]);

    return (
        <Popup
            showPopup={props.showPopup}
            onClose={props.onClose}
            overlayComponent={
                props.stops.pickup.file_name ? (
                    <OrderFile filename={props.stops.pickup.file_name} />
                ) : undefined
            }
        >
            <div className="stops-info-popup">
                <div className="top">
                    <p className="text-xl">{props.stops.pickup.order_number}</p>
                    <p className="text-xs">
                        {t("popup.stopsInfo.createdAt")}
                        {": "}
                        {formatDateString(props.stops.pickup.created_at, {
                            showTime: true,
                        })}
                    </p>
                </div>
                {props.stops.pickup.tag && (
                    <Badge
                        title={props.stops.pickup.tag}
                        variant="neutral"
                        icon={faTag}
                    />
                )}

                <div className="content">
                    {sections.map((section, i) => (
                        <section key={i}>
                            {section.map((row) => (
                                <div
                                    className="row"
                                    key={row.label + row.value}
                                >
                                    <p className="label text-xs">{row.label}</p>
                                    <p className="value text-xs">{row.value}</p>
                                </div>
                            ))}
                        </section>
                    ))}
                </div>

                <div className="bottom">
                    <Button
                        variant={"secondary"}
                        label={t("popup.stopsInfo.closePopup")}
                        style={{
                            width: "100%",
                        }}
                        onClick={props.onClose}
                    />
                    {props.onEditStops && (
                        <Button
                            variant={"primary"}
                            label={t("popup.stopsInfo.editStops")}
                            style={{
                                width: "100%",
                            }}
                            onClick={() => props.onEditStops?.(props.stops)}
                        />
                    )}
                    {props.onDetachStops && (
                        <Button
                            variant={"primary"}
                            label={t("popup.stopsInfo.detachStops")}
                            style={{
                                width: "100%",
                            }}
                            onClick={() => props.onDetachStops?.(props.stops)}
                        />
                    )}
                    {props.onAssignOutsideFleet && (
                        <Button
                            variant={"secondary"}
                            label={t("popup.stopsInfo.assignOutsideFleet")}
                            style={{
                                width: "100%",
                            }}
                            onClick={() =>
                                props.onAssignOutsideFleet?.(props.stops)
                            }
                        />
                    )}
                    {props.onAssignAlrik && (
                        <Button
                            variant={"secondary"}
                            label={t("popup.stopsInfo.assignAlrik")}
                            style={{
                                width: "100%",
                            }}
                            onClick={() => props.onAssignAlrik?.(props.stops)}
                        />
                    )}
                    {props.onChangedLocation && (
                        <Button
                            variant={"secondary"}
                            label={t("popup.stopsInfo.changedLocation")}
                            style={{
                                width: "100%",
                            }}
                            onClick={() =>
                                props.onChangedLocation?.(props.stops)
                            }
                        />
                    )}
                </div>
            </div>
        </Popup>
    );
}

export default StopsInfoPopup;
