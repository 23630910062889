import { useQuery } from "@tanstack/react-query";
import { getExternalCarriers } from "../../api/external-carrier";

function useExternalCarriers() {
    const {
        data: externalCarriers,
        status,
        refetch,
        isFetching,
    } = useQuery({
        queryKey: ["external-carriers"],
        queryFn: async () => {
            const res = await getExternalCarriers();
            return res.data;
        },
    });

    switch (status) {
        case "success":
            return {
                externalCarriers,
                status,
                refetch,
                isFetching,
            };
        case "pending":
            return {
                externalCarriers,
                status,
                refetch,
                isFetching,
            };
        case "error":
            return {
                externalCarriers,
                status,
                refetch,
                isFetching,
            };
    }
}

export default useExternalCarriers;
