import IconOLD from "../IconOLD";
import "./style.scss";

type Props = {
    padding?: string;
    color?: string;
    width?: string;
};

function Spinner(props: Props) {
    return (
        <div
            className="spinner"
            style={{
                padding: props.padding,
                color: props.color,
                width: props.width,
            }}
        >
            <IconOLD type="spinner-third" />
        </div>
    );
}

export default Spinner;
