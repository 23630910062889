import { useMutation } from "@tanstack/react-query";
import { isBefore, startOfDay } from "date-fns";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {
    bulkDeleteOrderDrafts as bulkDeleteOrderDraftsApi,
    bulkDeleteStopDrafts,
    getOrderDrafts,
    getStopDrafts,
} from "../../api/stop-draft";
import { stringToDate } from "../../shared/utility/date";

const today = startOfDay(new Date());

function useBulkDeletion({ onSuccess }: { onSuccess?: () => void }) {
    const { t } = useTranslation();

    const { mutate: bulkDeleteStops, isPending: isBulkDeletingStops } =
        useMutation({
            mutationFn: async () => {
                const {
                    data: { stop_drafts: stopDrafts },
                } = await getStopDrafts();

                await bulkDeleteStopDrafts(
                    stopDrafts
                        .filter((sd) => {
                            const stopDate = sd.date_tooltip
                                ? stringToDate(sd.date_tooltip)
                                : null;
                            if (!stopDate) return true;

                            return isBefore(startOfDay(stopDate), today);
                        })
                        .map((sd) => sd.id)
                );
            },
            onSuccess: () => {
                toast.success(t("successMessage.stopDraftsDeleted"));
                if (onSuccess) onSuccess();
            },
            onError: () => {
                toast.error(t("errorMessage.unknown"));
            },
        });

    const {
        mutate: bulkDeleteOrderDrafts,
        isPending: isBulkDeletingOrderDrafts,
    } = useMutation({
        mutationFn: async () => {
            const { data: orderDrafts } = await getOrderDrafts();

            await bulkDeleteOrderDraftsApi(
                orderDrafts
                    .filter((od) => {
                        const orderDraftEaliestPickup = od.earliest_pickup_utc
                            ? stringToDate(od.earliest_pickup_utc)
                            : null;
                        const orderDraftLatestDelivery = od.latest_delivery_utc
                            ? stringToDate(od.latest_delivery_utc)
                            : null;

                        if (
                            !orderDraftEaliestPickup &&
                            !orderDraftLatestDelivery
                        )
                            return true;

                        let isOrderDraftEarliestPickupBeforeToday = false;
                        let isOrderDraftLatestDeliveryBeforeToday = false;

                        if (orderDraftEaliestPickup) {
                            isOrderDraftEarliestPickupBeforeToday = isBefore(
                                startOfDay(orderDraftEaliestPickup),
                                today
                            );
                        }

                        if (orderDraftLatestDelivery) {
                            isOrderDraftLatestDeliveryBeforeToday = isBefore(
                                startOfDay(orderDraftLatestDelivery),
                                today
                            );
                        }

                        return (
                            isOrderDraftEarliestPickupBeforeToday ||
                            isOrderDraftLatestDeliveryBeforeToday
                        );
                    })
                    .map((od) => od.id)
            );
        },
        onSuccess: () => {
            toast.success(t("successMessage.orderDraftsDeleted"));
            if (onSuccess) onSuccess();
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    return {
        bulkDeleteStops,
        isBulkDeletingStops,
        bulkDeleteOrderDrafts,
        isBulkDeletingOrderDrafts,
    };
}

export default useBulkDeletion;
