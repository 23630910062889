import { Driver } from "../../../shared/types/api";
import DriverMarker from "../DriverMarker";

type Props = {
    drivers: Driver[];
};

function DriverMarkers(props: Props) {
    return (
        <>
            {props.drivers.map((driver) => (
                <DriverMarker key={driver.id} driver={driver} />
            ))}
        </>
    );
}

export default DriverMarkers;
