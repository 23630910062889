import { useTranslation } from "react-i18next";
import craneIcon from "../../../assets/vehicles/crane.svg";
import largeCraneIcon from "../../../assets/vehicles/large-crane.svg";
import largeVanIcon from "../../../assets/vehicles/large-van.svg";
import mediumCraneIcon from "../../../assets/vehicles/medium-crane.svg";
import ordinaryCarIcon from "../../../assets/vehicles/ordinary-car.svg";
import truckIcon from "../../../assets/vehicles/truck.svg";
import { Order } from "../../../shared/types/api";
import { formatDateString } from "../../../shared/utility/date";
import { convertToNumberWithSpaces } from "../../../shared/utility/misc";
import { getOrderStatus } from "../../../shared/utility/order";
import { CAR_NAME } from "../../../shared/values/enums";
import "./style.scss";

type Props = {
    order: Order;
    onClick: (order: Order) => void;
};

function OrderCard(props: Props) {
    const { t } = useTranslation();

    let vehicleIcon = ordinaryCarIcon;
    switch (props.order.vehicle?.car_name) {
        case CAR_NAME.Car:
            vehicleIcon = ordinaryCarIcon;
            break;
        case CAR_NAME.SmallTruck:
            vehicleIcon = largeVanIcon;
            break;
        case CAR_NAME.Truck:
            vehicleIcon = truckIcon;
            break;
        case CAR_NAME.Crane:
            vehicleIcon = craneIcon;
            break;
        case CAR_NAME.MediumCrane:
            vehicleIcon = mediumCraneIcon;
            break;
        case CAR_NAME.LargeCrane:
            vehicleIcon = largeCraneIcon;
            break;
        default:
            vehicleIcon = ordinaryCarIcon;
            break;
    }

    const status = getOrderStatus(props.order);
    let statusText = "";

    switch (status) {
        case "created":
            statusText = "orderHistory.statusCreated";
            break;
        case "verified":
            statusText = "orderHistory.statusVerified";
            break;
        case "in-progress":
            statusText = "orderHistory.statusInProgress";
            break;
        case "next-stop":
            statusText = "orderHistory.statusNextStop";
            break;
        case "unloading":
            statusText = "orderHistory.statusUnloading";
            break;
        case "fulfilled":
            statusText = "orderHistory.statusFulfilled";
            break;
    }

    return (
        <div className="order-card" onClick={() => props.onClick(props.order)}>
            <div className="top">
                <img
                    src={vehicleIcon}
                    alt={props.order.vehicle?.friendlyname}
                />
                <p className="text-sm">{props.order.vehicle?.friendlyname}</p>
                <p
                    title={`${t("orderHistory.pickUp")}: ${
                        props.order.pickup_address
                    }`}
                >
                    {t("orderHistory.pickUp")}
                    {": "}
                    {props.order.pickup_address}
                </p>
                <p
                    title={`${t("orderHistory.dropOff")}: ${
                        props.order.dropoff_address
                    }`}
                >
                    {t("orderHistory.dropOff")}
                    {": "}
                    {props.order.dropoff_address}
                </p>
            </div>

            {props.order.delivery &&
                !props.order.earliest_pickup &&
                !props.order.latest_delivery && (
                    <section>
                        <span className="text-sm">
                            {props.order.delivery?.friendlyname}
                        </span>
                    </section>
                )}
            {props.order.earliest_pickup && props.order.latest_delivery && (
                <>
                    <section>
                        <span className="text-sm">
                            {t("createOrder.earliestPickUpPlaceholder")}
                        </span>
                        <span className="text-xs">
                            {formatDateString(props.order.earliest_pickup, {
                                showTime: true,
                            })}
                        </span>
                    </section>
                    <section>
                        <span className="text-sm">
                            {t("createOrder.latestDeliveryPlaceholder")}
                        </span>
                        <span className="text-xs">
                            {formatDateString(props.order.latest_delivery, {
                                showTime: true,
                            })}
                        </span>
                    </section>
                </>
            )}
            <section>
                <span className="text-sm">
                    {t("popup.orderInfo.priceLabel")}:{" "}
                    {convertToNumberWithSpaces(
                        props.order.total_price || 0,
                        "SEK"
                    )}
                </span>
                <span className="text-sm">{t(statusText)}</span>
            </section>
        </div>
    );
}

export default OrderCard;
