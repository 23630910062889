import { useState } from "react";
import { Outlet } from "react-router-dom";
import OrderInfoPopup from "../../popups/OrderInfoPopup";
import { Order } from "../../shared/types/api";
import "./style.scss";

type Props = {};

function Orders(props: Props) {
    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

    return (
        <div className="orders">
            <div className="content">
                <Outlet
                    context={{
                        selectedOrder,
                        setSelectedOrder,
                    }}
                />
            </div>
            <OrderInfoPopup
                showPopup={!!selectedOrder}
                onClose={() => setSelectedOrder(null)}
                order={selectedOrder}
            />
        </div>
    );
}

export default Orders;
