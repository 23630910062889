import { DropdownOption } from "../types/internal";

export const tourTimes: DropdownOption[] = [
    { label: "00:00", value: "00:00" },
    { label: "01:00", value: "01:00" },
    { label: "02:00", value: "02:00" },
    { label: "03:00", value: "03:00" },
    { label: "04:00", value: "04:00" },
    { label: "05:00", value: "05:00" },
    { label: "06:00", value: "06:00" },
    { label: "07:00", value: "07:00" },
    { label: "08:00", value: "08:00" },
    { label: "09:00", value: "09:00" },
    { label: "10:00", value: "10:00" },
    { label: "11:00", value: "11:00" },
    { label: "12:00", value: "12:00" },
    { label: "13:00", value: "13:00" },
    { label: "14:00", value: "14:00" },
    { label: "15:00", value: "15:00" },
    { label: "16:00", value: "16:00" },
    { label: "17:00", value: "17:00" },
    { label: "18:00", value: "18:00" },
    { label: "19:00", value: "19:00" },
    { label: "20:00", value: "20:00" },
    { label: "21:00", value: "21:00" },
    { label: "22:00", value: "22:00" },
    { label: "23:00", value: "23:00" },
];

export const orderTimes: DropdownOption[] = [
    { label: "06:00", value: "06:00" },
    { label: "06:30", value: "06:30" },
    { label: "07:00", value: "07:00" },
    { label: "07:30", value: "07:30" },
    { label: "08:00", value: "08:00" },
    { label: "08:30", value: "08:30" },
    { label: "09:00", value: "09:00" },
    { label: "09:30", value: "09:30" },
    { label: "10:00", value: "10:00" },
    { label: "10:30", value: "10:30" },
    { label: "11:00", value: "11:00" },
    { label: "11:30", value: "11:30" },
    { label: "12:00", value: "12:00" },
    { label: "12:30", value: "12:30" },
    { label: "13:00", value: "13:00" },
    { label: "13:30", value: "13:30" },
    { label: "14:00", value: "14:00" },
    { label: "14:30", value: "14:30" },
    { label: "15:00", value: "15:00" },
    { label: "15:30", value: "15:30" },
    { label: "16:00", value: "16:00" },
    { label: "16:30", value: "16:30" },
    { label: "17:00", value: "17:00" },
    { label: "17:30", value: "17:30" },
    { label: "18:00", value: "18:00" },
    { label: "18:30", value: "18:30" },
    { label: "19:00", value: "19:00" },
    { label: "19:30", value: "19:30" },
    { label: "20:00", value: "20:00" },
    { label: "20:30", value: "20:30" },
    { label: "21:00", value: "21:00" },
    { label: "21:30", value: "21:30" },
    { label: "22:00", value: "22:00" },
];

export const lengthOptions: DropdownOption[] = [
    { label: "0.5 meter", value: "0.5 m" },
    { label: "1 meter", value: "1 m" },
    { label: "1.2 meter (EU pall)", value: "1.2 m" },
    { label: "2 meter", value: "2 m" },
    { label: "3 meter", value: "3 m" },
    { label: "4 meter", value: "4 m" },
    { label: "5 meter", value: "5 m" },
    { label: "6 meter", value: "6 m" },
    { label: "7 meter", value: "7 m" },
    { label: "8 meter", value: "8 m" },
    { label: "9 meter", value: "9 m" },
    { label: "10 meter", value: "10 m" },
    { label: "11 meter", value: "11 m" },
    { label: "12 meter", value: "12 m" },
    { label: "13 meter", value: "13 m" },
    { label: "14 meter", value: "14 m" },
    { label: "15 meter", value: "15 m" },
    { label: "16 meter", value: "16 m" },
    { label: "17 meter", value: "17 m" },
    { label: "18 meter", value: "18 m" },
    { label: "19 meter", value: "19 m" },
    { label: "20 meter", value: "20 m" },
];

export const widthOptions: DropdownOption[] = [
    { label: "0.5 meter", value: "0.5 m" },
    { label: "0.8 meter (EU pall)", value: "0.8 m" },
    { label: "1 meter", value: "1 m" },
    { label: "2 meter", value: "2 m" },
    { label: "3 meter", value: "3 m" },
    { label: "4 meter", value: "4 m" },
    { label: "5 meter", value: "5 m" },
    { label: "6 meter", value: "6 m" },
    { label: "7 meter", value: "7 m" },
    { label: "8 meter", value: "8 m" },
    { label: "9 meter", value: "9 m" },
    { label: "10 meter", value: "10 m" },
    { label: "11 meter", value: "11 m" },
    { label: "12 meter", value: "12 m" },
    { label: "13 meter", value: "13 m" },
    { label: "14 meter", value: "14 m" },
    { label: "15 meter", value: "15 m" },
    { label: "16 meter", value: "16 m" },
    { label: "17 meter", value: "17 m" },
    { label: "18 meter", value: "18 m" },
    { label: "19 meter", value: "19 m" },
    { label: "20 meter", value: "20 m" },
];

export const heightOptions: DropdownOption[] = [
    { label: "0.5 meter", value: "0.5 m" },
    { label: "1 meter", value: "1 m" },
    { label: "2 meter", value: "2 m" },
    { label: "3 meter", value: "3 m" },
    { label: "4 meter", value: "4 m" },
    { label: "5 meter", value: "5 m" },
    { label: "6 meter", value: "6 m" },
    { label: "7 meter", value: "7 m" },
    { label: "8 meter", value: "8 m" },
    { label: "9 meter", value: "9 m" },
    { label: "10 meter", value: "10 m" },
    { label: "11 meter", value: "11 m" },
    { label: "12 meter", value: "12 m" },
    { label: "13 meter", value: "13 m" },
    { label: "14 meter", value: "14 m" },
    { label: "15 meter", value: "15 m" },
    { label: "16 meter", value: "16 m" },
    { label: "17 meter", value: "17 m" },
    { label: "18 meter", value: "18 m" },
    { label: "19 meter", value: "19 m" },
    { label: "20 meter", value: "20 m" },
];

export const weightOptions: DropdownOption[] = [
    { label: "10 kg", value: "10 kg" },
    { label: "20 kg", value: "20 kg" },
    { label: "30 kg", value: "30 kg" },
    { label: "40 kg", value: "40 kg" },
    { label: "50 kg", value: "50 kg" },
    { label: "60 kg", value: "60 kg" },
    { label: "70 kg", value: "70 kg" },
    { label: "80 kg", value: "80 kg" },
    { label: "90 kg", value: "90 kg" },
    { label: "100 kg", value: "100 kg" },
    { label: "200 kg", value: "200 kg" },
    { label: "300 kg", value: "300 kg" },
    { label: "400 kg", value: "400 kg" },
    { label: "500 kg", value: "500 kg" },
    { label: "600 kg", value: "600 kg" },
    { label: "700 kg", value: "700 kg" },
    { label: "800 kg", value: "800 kg" },
    { label: "900 kg", value: "900 kg" },
    { label: "1000 kg", value: "1000 kg" },
    { label: "2000 kg", value: "2000 kg" },
    { label: "3000 kg", value: "3000 kg" },
    { label: "4000 kg", value: "4000 kg" },
    { label: "5000 kg", value: "5000 kg" },
    { label: "6000 kg", value: "6000 kg" },
    { label: "7000 kg", value: "7000 kg" },
    { label: "8000 kg", value: "8000 kg" },
    { label: "9000 kg", value: "9000 kg" },
    { label: "10000 kg", value: "10000 kg" },
    { label: "20000 kg", value: "20000 kg" },
];
