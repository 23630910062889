import { z } from "zod";
import { isValidPhoneNumber } from "../../shared/utility/phone";

export const deliverySchema = z
    .object({
        orderNumber: z.string().min(1, {
            message: "errorMessage.missingOrderNumber",
        }),
        fromAddress: z.string().min(1, {
            message: "errorMessage.missingFromAddress",
        }),
        toAddress: z.string().min(1, {
            message: "errorMessage.missingToAddress",
        }),
        date: z.date({
            invalid_type_error: "errorMessage.missingDate",
            required_error: "errorMessage.missingDate",
        }),
        contactId: z.number().positive({
            message: "errorMessage.missingContactId",
        }),
        dropoffPhone: z.string().min(1, {
            message: "errorMessage.missingDropoffPhone",
        }),
    })
    .refine((schema) => {
        const { dropoffPhone } = schema;

        return isValidPhoneNumber(dropoffPhone);
    }, "errorMessage.invalidPhoneNumber");

export const cargoSchema = z.object({
    weight: z
        .number({
            invalid_type_error: "errorMessage.missingWeight",
            required_error: "errorMessage.missingWeight",
        })
        .positive({
            message: "errorMessage.missingWeight",
        })
        .min(1, {
            message: "errorMessage.missingWeight",
        }),
    numberOfEuPallets: z.number(),
    weightOfHeaviestPackage: z
        .number()
        .min(1, "errorMessage.missingWeightOfHeaviestPackage"),
    cargoContent: z.string().min(1, {
        message: "errorMessage.missingCargoContent",
    }),
    driverInstructions: z.string(),
});

export const transportSchema = z.object({
    transportOption: z.union([
        z.literal("ordinary"),
        z.literal("schenker"),
        z.literal("alrik"),
    ]),
});

export type DeliveryForm = z.infer<typeof deliverySchema>;

export type CargoForm = z.infer<typeof cargoSchema>;

export type TransportForm = z.infer<typeof transportSchema>;
