import IconOLD from "../../UI/IconOLD";
import TooltipModal from "../../UI/TooltipModal";
import "./style.scss";

type Props = {
    checked: boolean;
    label?: string | null;
    hoverTooltip?: string | null;
    placeholder?: string | null;
    width?: string;
    style?: React.CSSProperties;
    large?: boolean;
    disabled?: boolean;
    onClick?: () => void;
};

function RadioInput(props: Props) {
    const radioInputClasses = ["radio-input"];
    if (props.checked) {
        radioInputClasses.push("checked");
    }
    if (props.disabled) {
        radioInputClasses.push("disabled");
    }
    if (props.large) {
        radioInputClasses.push("large");
    }

    return (
        <label
            className={radioInputClasses.join(" ")}
            style={{ width: props.width, ...props.style }}
        >
            <input
                type={"radio"}
                checked={props.checked}
                onClick={props.onClick ? () => props.onClick?.() : undefined}
                onChange={() => {}}
                placeholder={props.placeholder || undefined}
                disabled={props.disabled}
                hidden
            />
            {props.checked ? (
                <IconOLD
                    type="circle-dot"
                    fontSize={props.large ? "30px" : undefined}
                />
            ) : (
                <IconOLD
                    type="circle"
                    fontSize={props.large ? "30px" : undefined}
                />
            )}
            {props.hoverTooltip && (
                <TooltipModal
                    className="radio-tooltip"
                    content={props.hoverTooltip}
                />
            )}
            {props.label && <span className="text-xs">{props.label}</span>}
        </label>
    );
}

export default RadioInput;
