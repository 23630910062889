import { useTranslation } from "react-i18next";
import { Order } from "../../shared/types/api";
import { getOrderStatus } from "../../shared/utility/order";
import FleetOrderRow from "./FleetOrderRow";
import "./style.scss";
import { STOP_DRAFT_TYPE } from "../../shared/values/enums";

type Props = {
    rows: Order[];
    onRowClick: (order: Order) => void;
};

function FleetOrdersTable(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="fleet-orders-table">
            <table>
                <thead>
                    <tr>
                        <th className="text-xs status">
                            {t("orderHistory.status")}
                        </th>
                        <th className="text-xs created">
                            {t("orderHistory.created")}
                        </th>
                        <th className="text-xs order-number">
                            {t("orderHistory.orderNumber")}
                        </th>
                        <th className="text-xs driver">
                            {t("orderHistory.contactName")}
                        </th>
                        <th className="text-xs driver">
                            {t("orderHistory.driver")}
                        </th>
                        <th className="text-xs address">
                            {t("orderHistory.pickedUp")}
                        </th>
                        <th className="text-xs address">
                            {t("orderHistory.droppedOff")}
                        </th>
                        <th className="text-xs fulfilled-time">
                            {t("orderHistory.fulfilledTime")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.rows.map((order) => (
                        <FleetOrderRow
                            key={order.id}
                            trackingIds={
                                order.dropoff_tracking_code &&
                                order.pickup_tracking_code
                                    ? {
                                          pickup: order.pickup_tracking_code,
                                          dropoff: order.dropoff_tracking_code,
                                      }
                                    : undefined
                            }
                            status={getOrderStatus(order)}
                            orderNumber={order.origin_order_id || ""}
                            driverName={order.preferred_driver || ""}
                            externalCarrier={
                                order.carrier_delivery_requests?.[0]
                                    ?.external_carrier || null
                            }
                            created={order.created_at || ""}
                            customer={order.customer}
                            pickupData={{
                                address: order.pickup_address || "",
                                contactName: order.pickup_contact?.name,
                                note: order.pickup_driver_note,
                                hasProofOfDelivery:
                                    order.proof_of_deliveries?.some(
                                        (pod) =>
                                            pod.stop_type_id ===
                                            STOP_DRAFT_TYPE.Pickup
                                    ),
                            }}
                            dropoffData={{
                                address: order.dropoff_address || "",
                                date: order.fulfilled_at,
                                contactName: order.dropoff_contact?.name,
                                note: order.dropoff_driver_note,
                                hasProofOfDelivery:
                                    order.proof_of_deliveries?.some(
                                        (pod) =>
                                            pod.stop_type_id ===
                                            STOP_DRAFT_TYPE.Dropoff
                                    ),
                            }}
                            archivedAt={order.archived_at}
                            onClick={() => props.onRowClick(order)}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default FleetOrdersTable;
