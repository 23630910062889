import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {
    createExternalCarrier,
    deleteExternalCarrier,
    updateExternalCarrier,
} from "../../api/external-carrier";
import ButtonOLD from "../../components/buttons/ButtonOLD";
import InputOLD from "../../components/inputs/InputOLD";
import Popup from "../../hoc/Popup";
import { ExternalCarrierForm, externalCarrierForm } from "../../schemas/form";
import { ExternalCarrier } from "../../shared/types/api";
import { onFormError } from "../../shared/utility/misc";
import "./style.scss";

type Props = {
    showPopup: boolean;
    onClose: () => void;
    onSave: (carrier?: ExternalCarrier) => void;
    onDelete: (id: number) => void;
    name?: string;
    editCarrier?: ExternalCarrier;
};

function ExternalCarrierPopup(props: Props) {
    const { t } = useTranslation();

    const defaultValues = useMemo<ExternalCarrierForm>(() => {
        const values: ExternalCarrierForm = {
            name: "",
            phone: "",
            email: "",
            hourlyCost: "",
            reference: "",
        };

        if (props.name) {
            values.name = props.name;
        }

        if (props.editCarrier) {
            values.name = props.editCarrier.name;
            values.phone = props.editCarrier.phone;
            values.email = props.editCarrier.email;
            values.hourlyCost = props.editCarrier.hourly_cost?.toString() || "";
            values.reference = props.editCarrier.reference || "";
        }

        return values;
    }, [props.editCarrier, props.name]);

    const {
        handleSubmit,
        control,
        reset: resetInputs,
    } = useForm<ExternalCarrierForm>({
        resolver: zodResolver(externalCarrierForm),
        defaultValues,
    });

    const { mutate: createHandler, isPending: isCreating } = useMutation({
        mutationFn: async ({
            name,
            phone,
            email,
            hourlyCost,
            reference,
        }: ExternalCarrierForm) => {
            const res = await createExternalCarrier({
                name,
                phone,
                email,
                hourly_cost: hourlyCost ? Math.round(+hourlyCost) : undefined,
                reference,
            });

            return res.data;
        },
        onSuccess: (carrier) => {
            toast.success(t("successMessage.externalCarrierCreated"));
            closeHandler();
            props.onSave(carrier);
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    const { mutate: editHandler, isPending: isEditing } = useMutation({
        mutationFn: async ({
            data: { name, phone, email, hourlyCost, reference },
            id,
        }: {
            data: ExternalCarrierForm;
            id: number;
        }) => {
            const res = await updateExternalCarrier({
                id,
                name,
                phone,
                email,
                hourly_cost: hourlyCost ? Math.round(+hourlyCost) : undefined,
                reference,
            });

            return res.data;
        },
        onSuccess: (carrier) => {
            toast.success(t("successMessage.externalCarrierUpdated"));
            closeHandler();
            props.onSave(carrier);
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    const { mutate: deleteHandler, isPending: isDeleting } = useMutation({
        mutationFn: async (id: number) => {
            await deleteExternalCarrier(id);

            return id;
        },
        onSuccess: (deletedId) => {
            toast.success(t("successMessage.externalCarrierDeleted"));
            closeHandler();
            props.onDelete(deletedId);
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    useEffect(() => {
        resetInputs(defaultValues);
    }, [defaultValues, resetInputs]);

    function closeHandler() {
        resetInputs();
        props.onClose();
    }
    return (
        <Popup
            showPopup={props.showPopup}
            onClose={closeHandler}
            title={t("popup.externalCarrier.title")}
            dontCloseOnOutsideClick
        >
            <div className="carrier-popup">
                <Controller
                    name="name"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <InputOLD
                            type="text"
                            label={t("popup.favourite.nameLabel")}
                            placeholder={t("popup.favourite.namePlaceholder")}
                            value={value}
                            onChange={onChange}
                            width="100%"
                        />
                    )}
                />
                <Controller
                    name="phone"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <InputOLD
                            type="tel"
                            label={t("createTour.phone")}
                            placeholder={t("createTour.phonePlaceholder")}
                            value={value || ""}
                            onChange={onChange}
                            width="100%"
                        />
                    )}
                />
                <Controller
                    name="email"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <InputOLD
                            type="email"
                            label={t("settings.emailLabel")}
                            value={value || ""}
                            onChange={onChange}
                            width="100%"
                        />
                    )}
                />
                <Controller
                    name="hourlyCost"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <InputOLD
                            type="text"
                            label={t("createTour.hourlyCost")}
                            value={value || ""}
                            onChange={onChange}
                            width="100%"
                        />
                    )}
                />
                <Controller
                    name="reference"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <InputOLD
                            type="text"
                            label={t("createTour.externalReference")}
                            value={value || ""}
                            onChange={onChange}
                            width="100%"
                        />
                    )}
                />
                <div className="buttons">
                    {props.editCarrier ? (
                        <>
                            <ButtonOLD
                                label={t("popup.favourite.deleteLabel")}
                                onClick={() =>
                                    deleteHandler(props.editCarrier!.id)
                                }
                                isLoading={isDeleting}
                                color="secondary"
                                width="49%"
                            />
                            <ButtonOLD
                                label={t("popup.favourite.submitLabel")}
                                onClick={handleSubmit(
                                    (data) =>
                                        editHandler({
                                            data,
                                            id: props.editCarrier!.id,
                                        }),
                                    onFormError
                                )}
                                isLoading={isEditing}
                                color="secondary"
                                width="49%"
                            />
                        </>
                    ) : (
                        <ButtonOLD
                            label={t("popup.favourite.submitLabel")}
                            onClick={handleSubmit(
                                (data) => createHandler(data),
                                onFormError
                            )}
                            isLoading={isCreating}
                            color="secondary"
                            width="100%"
                        />
                    )}
                </div>
            </div>
        </Popup>
    );
}

export default ExternalCarrierPopup;
