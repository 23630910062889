import ReactConfetti from "react-confetti";

type Props = {
    run: boolean;
    onEnded?: () => void;
};

function Confetti(props: Props) {
    return (
        <ReactConfetti
            run={props.run}
            recycle={false}
            gravity={0.15}
            style={{ width: "100%", height: "100%" }}
            tweenDuration={6000}
            numberOfPieces={500}
            onEnded={props.onEnded}
        />
    );
}

export default Confetti;
