import { useTranslation } from "react-i18next";
import Select, { MenuPlacement, StylesConfig, components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { DropdownOption } from "../../../shared/types/internal";
import IconOLD from "../../UI/IconOLD";
import Subtitle from "../../UI/Subtitle";
import Tooltip from "../../UI/Tooltip";
import "./style.scss";

type Props = {
    value: string | DropdownOption | null;
    onSelect: (value: DropdownOption) => void;
    options: DropdownOption[];
    isSearchable?: boolean;
    placeholder?: string | null;
    width?: string;
    label?: string | null;
    tooltip?: string | null;
    fixedMenu?: boolean;
    menuPlacement?: MenuPlacement;
    disabled?: boolean;
    subtitle?: string | null;
    isLoading?: boolean;
    labelColor?: string;
    menuHeight?: string;
    isOptionDisabled?: (option: DropdownOption) => boolean;
    isCompleted?: boolean;
    isCreatable?: boolean;
    onCreate?: (value: string) => void;
    noOptionsMessage?: string;
};

function DropdownOLD(props: Props) {
    const { t } = useTranslation();

    const CustomDropdownIndicator = (props: any) => (
        <components.DropdownIndicator {...props}>
            <IconOLD type="chevron-down" />
        </components.DropdownIndicator>
    );

    let value = props.value;

    if (typeof props.value === "string" && props.value !== "") {
        value = props.options.find((o) => o.value === props.value) || null;

        if (!value && props.isCreatable) {
            value = {
                value: props.value,
                label: props.value,
            };
        }
    }

    const styles: StylesConfig<DropdownOption> = {
        container: (base) => ({
            ...base,
            width: props.width || "100%",
            maxWidth: props.width || "100%",
        }),
        menuList: (base) => ({
            ...base,
            maxHeight: props.menuHeight || "200px",
        }),
        menu: (base) => ({
            ...base,
            zIndex: 100000,
        }),
        placeholder: (base) => ({
            ...base,
            color: "var(--text-color-light)",
            fontWeight: 300,
        }),
        control: (base, state) => ({
            ...base,
            width: "100%",
            color: "var(--text-color)",
            borderRadius: "40px",
            border: state.isFocused
                ? "1px solid var(--text-color)"
                : props.isCompleted
                ? "2px solid var(--text-color-alt)"
                : "var(--border-primary)",
            "&:hover": {
                border: state.isFocused
                    ? "1px solid var(--text-color)"
                    : "var(--border-primary)",
            },
            minHeight: "40px",
            cursor: "pointer",
            boxShadow: "none",
            backgroundColor: props.isCompleted
                ? "var(--highlight-color)"
                : "var(--color-pure-white)",
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused
                ? "var(--highlight-color)"
                : "transparent",
            color: "var(--text-color)",
            cursor: "pointer",
            ":active": {
                backgroundColor: "var(--highlight-color)",
            },
            opacity: state.isDisabled ? 0.5 : 1,
        }),
    };

    return (
        <label
            className="dropdown-wrapper-old"
            style={{
                width: props.width,
                maxWidth: props.width,
            }}
        >
            {(props.tooltip || props.label) && (
                <div
                    className="dropdown-label"
                    style={{ color: props.labelColor }}
                >
                    {props.tooltip && <Tooltip content={props.tooltip} />}
                    {props.label && (
                        <span className="text-xs">{props.label}</span>
                    )}
                </div>
            )}
            {!props.isCreatable ? (
                <Select
                    value={value as DropdownOption | null}
                    onChange={(o: any) => props.onSelect(o as DropdownOption)}
                    options={props.options as any}
                    isSearchable={!!props.isSearchable}
                    isLoading={props.isLoading}
                    placeholder={props.placeholder || ""}
                    menuPosition={props.fixedMenu ? "fixed" : "absolute"}
                    noOptionsMessage={() =>
                        props.noOptionsMessage ||
                        t("general.dropdown.noResults")
                    }
                    components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: CustomDropdownIndicator,
                    }}
                    menuPlacement={props.menuPlacement}
                    isOptionDisabled={(option) =>
                        !!option.disabled || !!props.isOptionDisabled?.(option)
                    }
                    isDisabled={props.disabled}
                    styles={styles}
                />
            ) : (
                <CreatableSelect
                    value={value as DropdownOption | null}
                    onChange={(o: any) => props.onSelect(o as DropdownOption)}
                    options={props.options as any}
                    isSearchable={true}
                    isLoading={props.isLoading}
                    placeholder={props.placeholder || ""}
                    menuPosition={props.fixedMenu ? "fixed" : "absolute"}
                    noOptionsMessage={() =>
                        props.noOptionsMessage ||
                        t("general.dropdown.noResults")
                    }
                    formatCreateLabel={(inputValue) =>
                        `${t("general.dropdown.create")} ${inputValue}`
                    }
                    components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: CustomDropdownIndicator,
                    }}
                    menuPlacement={props.menuPlacement}
                    isOptionDisabled={(option) =>
                        !!option.disabled || !!props.isOptionDisabled?.(option)
                    }
                    isDisabled={props.disabled}
                    styles={styles}
                    onCreateOption={props.onCreate}
                />
            )}
            {props.subtitle && <Subtitle text={props.subtitle} />}
        </label>
    );
}

export default DropdownOLD;
