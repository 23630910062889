import {
    faAnglesDown,
    faAnglesUp,
    faAnglesUpDown,
    faEllipsisVertical,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useCallback, useMemo, useState } from "react";
import Button from "../../components/buttons/Button";
import Modal from "../../hoc/Modal";
import "./style.scss";

type Column<T> = {
    key: keyof T | string;
    title: string | ReactElement;
    getValue?: (item: T, column: Column<T>) => string | number;
    render?: (item: T, column: Column<T>) => ReactElement | null;
    hide?: boolean;
    onSort?: (column: Column<T>) => void;
    sortMode?: "asc" | "desc" | null;
    getHoverTitle?: (item: T, column: Column<T>) => string;
    width?: string;
};

type Props<T> = {
    data: T[];
    columns: Column<T>[];
    maxHeight?: string;
    renderDotsModalContent?: (item: T, close: () => void) => JSX.Element;
    onRowClick?: (item: T) => void;
};

function Table<T>(props: Props<T>) {
    const { renderDotsModalContent, onRowClick } = props;

    const [modalOpenIndex, setModalOpenIndex] = useState<number | null>(null);

    const tableClasses = ["table"];
    if (props.maxHeight) {
        tableClasses.push("scrollable");
    }

    const getValueToDisplay = useCallback((item: T, column: Column<T>) => {
        if (column.render) {
            return column.render(item, column);
        }

        if (column.getValue !== undefined) {
            const value = column.getValue(item, column);

            return (
                <span
                    className="value-wrapper"
                    title={column.getHoverTitle?.(item, column) || `${value}`}
                    style={{
                        width: column.width,
                    }}
                >
                    {value}
                </span>
            );
        }

        const value = item[column.key as keyof typeof item] as string;
        return (
            <span
                className="value-wrapper"
                title={column.getHoverTitle?.(item, column) || `${value}`}
                style={{
                    width: column.width,
                }}
            >
                {item[column.key as keyof typeof item] as string}{" "}
            </span>
        );
    }, []);

    const headers = useMemo(
        () =>
            props.columns
                .filter((column) => !column.hide)
                .map((column, index) => (
                    <th key={`head-cell-${index}`}>
                        <div
                            className="table-head-wrapper text-xs"
                            style={{
                                cursor: column.onSort ? "pointer" : undefined,
                            }}
                            onClick={() => column.onSort?.(column)}
                        >
                            {column.title}{" "}
                            {column.onSort && (
                                <FontAwesomeIcon
                                    icon={
                                        column.sortMode === "asc"
                                            ? faAnglesUp
                                            : column.sortMode === "desc"
                                            ? faAnglesDown
                                            : faAnglesUpDown
                                    }
                                    size="sm"
                                />
                            )}
                        </div>
                    </th>
                )),
        [props.columns]
    );

    const rows = useMemo(
        () =>
            props.data.map((item, i) => (
                <tr
                    key={`row-${i}`}
                    onClick={() => onRowClick?.(item)}
                    style={{ cursor: onRowClick ? "pointer" : undefined }}
                >
                    {props.columns
                        .filter((column) => !column.hide)
                        .map((column, j) => {
                            const value = getValueToDisplay(item, column);
                            return <td key={`cell-${j}`}>{value}</td>;
                        })}
                    {renderDotsModalContent && (
                        <td>
                            <Modal
                                buttonElement={(ref) => (
                                    <Button
                                        ref={ref}
                                        variant="secondary"
                                        leadingIcon={faEllipsisVertical}
                                        style={{
                                            backgroundColor: "transparent",
                                            boxShadow: "none",
                                            border: "none",
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setModalOpenIndex((state) =>
                                                state === i ? null : i
                                            );
                                        }}
                                    />
                                )}
                                isOpen={modalOpenIndex === i}
                                onClose={() => setModalOpenIndex(null)}
                                align={"right"}
                            >
                                {renderDotsModalContent(item, () =>
                                    setModalOpenIndex(null)
                                )}
                            </Modal>
                        </td>
                    )}
                </tr>
            )),
        [
            getValueToDisplay,
            modalOpenIndex,
            onRowClick,
            props.columns,
            props.data,
            renderDotsModalContent,
        ]
    );

    return (
        <div
            className={tableClasses.join(" ")}
            style={{
                maxHeight: props.maxHeight,
                height: props.maxHeight,
            }}
        >
            <table>
                <thead>
                    <tr>
                        {headers}
                        {renderDotsModalContent && <th></th>}
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
        </div>
    );
}

export default Table;
