import { isSameDay, setDay } from "date-fns";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { WEEK_DAYS } from "../../../shared/values/enums";
import Button from "../../buttons/Button";

import "./style.scss";
import DateInput from "../DateInput";

type Props = {
    value: Date | null;
    onChange: (value: Date) => void;
};

function WeekDaySelector(props: Props) {
    const { onChange } = props;

    const { t } = useTranslation();

    const weekDay = props.value ? props.value.getDay() : null;

    const weekDaysNumbers = [1, 2, 3, 4, 5, 6, 0];

    const changeHandler = useCallback(
        (date: Date) => {
            if (props.value && isSameDay(date, props.value)) return;

            onChange(date);
        },
        [onChange, props.value]
    );

    return (
        <div className="week-day-selector">
            <DateInput
                value={props.value}
                onChange={(value) => {
                    if (value) changeHandler(value);
                }}
                showAllDates
                ignoreUnselectableDates
            />
            <div className="buttons">
                {weekDaysNumbers.map((dayKey) => (
                    <Button
                        key={dayKey}
                        label={t(`general.weekdays.${WEEK_DAYS[dayKey]}`)}
                        variant={weekDay === +dayKey ? "primary" : "secondary"}
                        onClick={() =>
                            changeHandler(
                                setDay(props.value || new Date(), +dayKey, {
                                    weekStartsOn: 1,
                                })
                            )
                        }
                    />
                ))}
            </div>
        </div>
    );
}

export default WeekDaySelector;
