import { IconType } from "../../../shared/types/internal";
import IconOLD from "../../UI/IconOLD";
import Spinner from "../../UI/Spinner";
import Subtitle from "../../UI/Subtitle";
import TooltipModal from "../../UI/TooltipModal";
import "./style.scss";

type Props = {
    label: string;
    color: "primary" | "secondary" | "alt" | "danger";
    width?: string;
    onClick?: (event: React.MouseEvent) => void;
    isLoading?: boolean;
    className?: string;
    icon?: IconType;
    short?: boolean;
    disabled?: boolean;
    noSubmit?: boolean;
    tooltip?: string | null;
    id?: string;
    subtitle?: string | null;
    style?: React.CSSProperties;
};

function ButtonOLD(props: Props) {
    const buttonClasses = ["button-old", "waves-effect"];

    buttonClasses.push(props.color);

    if (props.color === "secondary") {
        buttonClasses.push("waves-light");
    }

    if (props.className) {
        buttonClasses.push(props.className);
    }

    if (props.isLoading) {
        buttonClasses.push("loading");
    }

    if (props.short) {
        buttonClasses.push("short");
        buttonClasses.push("text-xs");
    } else {
        buttonClasses.push("text-sm");
    }

    if (props.icon) {
        buttonClasses.push("icon-button");
    }

    if (props.disabled) {
        buttonClasses.push("disabled");
    }

    let icon: JSX.Element | undefined;
    if (props.icon) {
        icon = <IconOLD type={props.icon} />;
    }

    return (
        <div
            className="button-wrapper-old"
            style={{
                width: props.width,
                minWidth: props.width,
                ...props.style,
            }}
            id={props.id}
        >
            {props.tooltip && (
                <TooltipModal
                    className="button-tooltip"
                    content={props.tooltip}
                />
            )}
            <button
                className={buttonClasses.join(" ")}
                disabled={props.disabled}
                onClick={(e) => {
                    if (props.isLoading || props.disabled) {
                        e.preventDefault();
                        return;
                    }
                    if (props.onClick) props.onClick(e);
                }}
                type={props.noSubmit || props.onClick ? "button" : undefined}
            >
                {props.isLoading && (
                    <div className="button-loading">
                        <Spinner />
                    </div>
                )}
                <div className="content">
                    {icon}
                    {props.label}
                </div>
            </button>

            {props.subtitle && <Subtitle text={props.subtitle} />}
        </div>
    );
}

export default ButtonOLD;
