import "./style.scss";

type Props = {
    text: string;
};

function ShowingAllMessage(props: Props) {
    return <p className="showing-all-message text-xs">{props.text}</p>;
}

export default ShowingAllMessage;
