import { useQuery } from "@tanstack/react-query";
import { validateAddress } from "../../api/order";

function useValidateAddress(address: string) {
    const { data, status, refetch, isFetching } = useQuery({
        queryKey: ["validate-address", address] as const,
        queryFn: async ({ queryKey: [_, address] }) => {
            const res = await validateAddress(address);
            return res.data;
        },
        enabled: !!address,
    });

    switch (status) {
        case "success":
            return {
                data,
                status,
                refetch,
                isFetching,
            };
        case "pending":
            return {
                data,
                status,
                refetch,
                isFetching,
            };
        case "error":
            return {
                data,
                status,
                refetch,
                isFetching,
            };
    }
}

export default useValidateAddress;
