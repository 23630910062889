import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ButtonOLD from "../../../components/buttons/ButtonOLD";
import InputOLD from "../../../components/inputs/InputOLD";
import { ResetPasswordForm, resetPasswordForm } from "../../../schemas/auth";
import memberstack from "../../../shared/services/memberstack";
import { onFormError } from "../../../shared/utility/misc";
import { ROUTE } from "../../../shared/values/enums";
import "./style.scss";

type Props = {};

function ResetPassword(props: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { control, handleSubmit } = useForm<ResetPasswordForm>({
        resolver: zodResolver(resetPasswordForm),
        defaultValues: {
            resetToken: "",
            newPassword: "",
        },
    });

    const { mutate: resetPasswordHandler, isPending } = useMutation({
        mutationFn: async ({ resetToken, newPassword }: ResetPasswordForm) => {
            await memberstack.resetMemberPassword({
                token: resetToken,
                newPassword,
            });
        },
        onSuccess: () => {
            toast.success(t("resetPassword.success"));
            navigate(ROUTE.Login);
        },
        onError: (error) => {
            if ((error as any).code === "invalid-reset-code") {
                toast.error((error as any).message);
            } else {
                toast.error(t("errorMessage.resetPasswordFailed"));
            }
        },
    });

    return (
        <div className="reset-password">
            <form
                onSubmit={handleSubmit(
                    (data) => resetPasswordHandler(data),
                    onFormError
                )}
            >
                <h1 className="text-sm">{t("resetPassword.title")}</h1>
                <hr />
                <Controller
                    name="resetToken"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <InputOLD
                            type="tel"
                            value={value}
                            onChange={onChange}
                            label={t("resetPassword.passwordTokenLabel")}
                            placeholder={t(
                                "resetPassword.passwordTokenPlaceholder"
                            )}
                            width="100%"
                            autoComplete="one-time-code"
                        />
                    )}
                />
                <Controller
                    name="newPassword"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <InputOLD
                            type="password"
                            value={value}
                            onChange={onChange}
                            label={t("resetPassword.newPasswordLabel")}
                            placeholder={t(
                                "resetPassword.newPasswordPlaceholder"
                            )}
                            width="100%"
                        />
                    )}
                />
                <ButtonOLD
                    label={t("resetPassword.submitLabel")}
                    color="secondary"
                    width="100%"
                    isLoading={isPending}
                />
            </form>
        </div>
    );
}

export default ResetPassword;
