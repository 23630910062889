import { IconType } from "../../../shared/types/internal";
import "./style.scss";

type Props = {
    type: IconType;
    className?: string;
    style?: React.CSSProperties;
    fontSize?: string;
    title?: string;
};

function IconOLD(props: Props) {
    const iconClasses = ["icon-old"];

    let iconClass = "";
    switch (props.type) {
        case "ordinary-car":
            iconClass = "fa-solid fa-car";
            break;
        case "large-van":
        case "truck":
            iconClass = "fa-solid fa-truck";
            break;
        case "circle-info":
            iconClass = "fa-solid fa-circle-info";
            break;
        case "plus":
            iconClass = "fa-solid fa-plus";
            break;
        case "cog":
            iconClass = "fa-solid fa-cog";
            break;
        case "phone":
            iconClass = "fa-solid fa-phone";
            break;
        case "spinner-third":
            iconClass = "far fa-spinner-third";
            break;
        case "chevron-right":
            iconClass = "fa-solid fa-chevron-right";
            break;
        case "chevron-left":
            iconClass = "fa-solid fa-chevron-left";
            break;
        case "chevron-up":
            iconClass = "fa-solid fa-chevron-up";
            break;
        case "chevron-down":
            iconClass = "fa-solid fa-chevron-down";
            break;
        case "arrow-up":
            iconClass = "fa-solid fa-arrow-up";
            break;
        case "arrow-down":
            iconClass = "fa-solid fa-arrow-down";
            break;
        case "orders":
            iconClass = "fa-solid fa-send-backward";
            break;
        case "logout":
            iconClass = "fa-solid fa-right-from-bracket";
            break;
        case "paper-plane":
            iconClass = "fa-solid fa-paper-plane";
            break;
        case "crane":
        case "medium-crane":
        case "large-crane":
            iconClass = "fa-solid fa-truck-tow";
            break;
        case "search":
            iconClass = "fa-regular fa-magnifying-glass";
            break;
        case "cross":
            iconClass = "fa-solid fa-xmark";
            break;
        case "check":
            iconClass = "fa-solid fa-check";
            break;
        case "clock":
            iconClass = "fa-solid fa-clock";
            break;
        case "calendar":
            iconClass = "fa-regular fa-calendar";
            break;
        case "map":
            iconClass = "fa-regular fa-map-location-dot";
            break;
        case "trash":
            iconClass = "fa-regular fa-trash-can";
            break;
        case "star":
            iconClass = "fa-regular fa-star";
            break;
        case "star-filled":
            iconClass = "fa-solid fa-star";
            break;
        case "seedling":
            iconClass = "fa-regular fa-seedling";
            break;
        case "comment":
            iconClass = "fa-solid fa-comment";
            break;
        case "circle":
            iconClass = "fa-regular fa-circle";
            break;
        case "circle-dot":
            iconClass = "fa-regular fa-circle-dot";
            break;
        case "house":
            iconClass = "fa-solid fa-house";
            break;
        case "eye-slash":
            iconClass = "fa-solid fa-eye-slash";
            break;
        case "copy":
            iconClass = "fa-regular fa-copy";
            break;
        case "leaf":
            iconClass = "fa-regular fa-leaf";
            break;
        case "arrow-bracket":
            iconClass = "fa-regular fa-arrow-up-from-bracket";
            break;
        case "plane-departure":
            iconClass = "fa-solid fa-plane-departure";
            break;
        case "race-flag":
            iconClass = "fa-solid fa-flag-checkered";
            break;
        case "user":
            iconClass = "fa-regular fa-user";
            break;
        case "address-book":
            iconClass = "fa-regular fa-address-book";
            break;
        case "circle-solid":
            iconClass = "fa-solid fa-circle";
            break;
        case "image":
            iconClass = "fa-regular fa-image";
            break;
        case "file-upload":
            iconClass = "fa-regular fa-file-arrow-up";
            break;
        case "file-download":
            iconClass = "fa-regular fa-file-arrow-down";
            break;
        case "ai":
            iconClass = "fa-solid fa-sparkles";
            break;
        case "map-pin":
            iconClass = "fa-solid fa-map-pin";
            break;
        case "hashtag":
            iconClass = "fa-solid fa-hashtag";
            break;
        case "tag":
            iconClass = "fa-solid fa-tag";
            break;
        case "weight":
            iconClass = "fa-solid fa-weight-hanging";
            break;
        case "circle-check":
            iconClass = "fa-regular fa-circle-check";
            break;
        case "filled-circle-check":
            iconClass = "fa-solid fa-circle-check";
            break;
    }

    iconClasses.push(iconClass);

    if (props.className) {
        iconClasses.push(props.className);
    }

    return (
        <i
            className={iconClasses.join(" ")}
            title={props.title}
            style={{
                ...props.style,
                fontSize: props.fontSize,
            }}
        />
    );
}

export default IconOLD;
