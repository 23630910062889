import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import environment from "../../environment";
import analytics from "../../shared/services/ga4";
import { LOCAL_STORAGE_KEY } from "../../shared/values/enums";

function useAnalytics() {
    const location = useLocation();

    const TRACKING_ID = environment.ga4TrackingId || undefined;

    const hasDisabled =
        localStorage.getItem(LOCAL_STORAGE_KEY.DisableAnalytics) === "1";

    useEffect(() => {
        if (!TRACKING_ID || hasDisabled) return;

        analytics.init(TRACKING_ID);
    }, [TRACKING_ID, hasDisabled]);

    useEffect(() => {
        if (!TRACKING_ID || hasDisabled) return;
        analytics.sendVersion();
    }, [TRACKING_ID, hasDisabled, location]);
}

export default useAnalytics;
