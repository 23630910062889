import { useState } from "react";
import Modal from "../../../hoc/Modal";
import { IconType } from "../../../shared/types/internal";
import DotsButton from "../../buttons/DotsButton";
import IconButton from "../../buttons/IconButton";
import Switch from "../../inputs/Switch";
import { DotsModalContent } from "../DotsModal";
import IconOLD from "../IconOLD";
import Spinner from "../Spinner";
import "./style.scss";

type Props = {
    fixedMenu?: boolean;
    isLoading?: boolean;
    icon?: IconType;
    content: {
        title: string;
        items?: {
            title: string;
            onChange: (value: boolean) => void;
            isActive?: boolean;
            disabled?: boolean;
            hidden?: boolean;
        }[];
        buttons?: DotsModalContent[];
    }[];
};

function SwitchModal(props: Props) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <Modal
            buttonElement={(ref) =>
                props.icon ? (
                    <IconButton
                        ref={ref}
                        icon={props.icon}
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsModalOpen((state) => !state);
                        }}
                        isLoading={props.isLoading}
                    />
                ) : (
                    <DotsButton
                        ref={ref}
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsModalOpen((state) => !state);
                        }}
                        isLoading={props.isLoading}
                    />
                )
            }
            onClose={() => setIsModalOpen(false)}
            isOpen={isModalOpen}
            modalClass="switch-modal"
            align="right"
            width="400px"
            fixedMenu={props.fixedMenu}
        >
            <>
                {props.content.map((c) => (
                    <section key={c.title}>
                        <p className="title text-sm">{c.title}</p>
                        {c.buttons?.map((button) => (
                            <button
                                key={button.title}
                                className="button-row text-sm"
                                type="button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (button.onClick) button.onClick();
                                    setIsModalOpen(false);
                                }}
                                disabled={button.disabled}
                                style={{
                                    opacity: button.disabled ? 0.5 : 1,
                                }}
                            >
                                {button.icon && (
                                    <IconOLD
                                        type={button.icon}
                                        style={{ color: button.iconColor }}
                                    />
                                )}
                                {button.isLoading ? <Spinner /> : button.title}
                            </button>
                        ))}

                        {c.items
                            ?.filter((item) => !item.hidden)
                            .map((item, i) => (
                                <div
                                    key={item.title + i}
                                    className="switch-row"
                                >
                                    <span>{item.title}</span>
                                    <Switch
                                        isActive={!!item.isActive}
                                        onChange={item.onChange}
                                        disabled={item.disabled}
                                    />
                                </div>
                            ))}
                    </section>
                ))}
            </>
        </Modal>
    );
}

export default SwitchModal;
