import { useQuery } from "@tanstack/react-query";
import { getActiveTours } from "../../api/tours";

function useActiveTours(filterDate?: string) {
    const {
        data: tours,
        status,
        refetch,
        isFetching,
    } = useQuery({
        queryKey: ["active-fleet", filterDate] as const,
        queryFn: async ({ queryKey: [_, date] }) => {
            const res = await getActiveTours(date ? date : undefined);
            return res.data;
        },
    });

    switch (status) {
        case "success":
            return {
                tours,
                status,
                refetch,
                isFetching,
            };
        case "pending":
            return {
                tours,
                status,
                refetch,
                isFetching,
            };
        case "error":
            return {
                tours,
                status,
                refetch,
                isFetching,
            };
    }
}

export default useActiveTours;
