import memberstackDOM from "@memberstack/dom";
import environment from "../../environment";

const memberstackConfig = {
    publicKey: environment.memberStackToken,
    sessionDurationDays: 30,
};

const memberstack = memberstackDOM.init(memberstackConfig);

export default memberstack;
