import { CSSProperties } from "react";
import { OrderStatus } from "../../../shared/types/internal";
import "./style.scss";

type Props = {
    status: OrderStatus;
    isArchived?: boolean;
    style?: CSSProperties;
};

function StatusCircle(props: Props) {
    let color;
    switch (props.status) {
        case "created":
            color = "var(--order-created-color)";
            break;
        case "verified":
            color = "var(--order-verified-color)";
            break;
        case "at-pickup":
            color = "var(--order-at-pickup-color)";
            break;
        case "in-progress":
            color = "var(--order-in-progress-color)";
            break;
        case "next-stop":
            color = "var(--order-next-stop-color)";
            break;
        case "unloading":
            color = "var(--order-unloading-color)";
            break;
        case "fulfilled":
            color = "var(--order-fulfilled-color)";
            break;
    }

    return (
        <div
            className={"status-circle" + (props.isArchived ? " archived" : "")}
            style={{
                backgroundColor: props.isArchived
                    ? "var(--component-background)"
                    : color,
                ...props.style,
            }}
        />
    );
}

export default StatusCircle;
