import { useMemo } from "react";
import "./style.scss";

type Props = {
    progress: number;
    maxWidth?: string;
    minWidth?: string;
};

function ProgressBar(props: Props) {
    const progressProcent = useMemo(() => {
        return Math.round(props.progress * 100);
    }, [props.progress]);

    return (
        <div
            className="progress-bar"
            style={{
                maxWidth: props.maxWidth,
                minWidth: props.minWidth,
            }}
        >
            <div className="bar">
                <div
                    className="progress"
                    style={{
                        width: `${progressProcent}%`,
                    }}
                />
            </div>
            <span>{progressProcent}%</span>
        </div>
    );
}

export default ProgressBar;
