import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { forwardRef, useEffect, useRef } from "react";
import { useClickOutside } from "../../hooks/functionality/useClickOutside";
import "./style.scss";

type Props = {
    children: React.ReactNode;
    showPopup: boolean;
    onClose: () => void;
    className?: string;
    title?: string | null;
    width?: string;
    id?: string;
    dontCloseOnOutsideClick?: boolean;
    overlayComponent?: React.ReactNode;
};

const Popup = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const popupRef = useRef<HTMLDivElement>(null);

    useClickOutside(
        [popupRef],
        () => {
            if (props.dontCloseOnOutsideClick || !props.showPopup) return;
            props.onClose();
        },
        true
    );

    useEffect(() => {
        if (props.showPopup) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [props.showPopup]);

    const popupClasses = ["popup"];
    if (props.className) {
        popupClasses.push(props.className);
    }

    // Use marginLeft for animation to prevent fixed positioning issues caused by transform property changes.
    const popupVariants: Variants = {
        closed: {
            marginLeft: "100%",
            opacity: 0,
            transition: {
                duration: 0.3,
            },
        },
        open: {
            marginLeft: 0,
            opacity: 1,
            transition: {
                duration: 0.3,
            },
        },
    };

    const popupWrapperVariants: Variants = {
        closed: {
            backgroundColor: "rgba(0, 0, 0, 0)",
            transition: {
                duration: 0.3,
            },
        },
        open: {
            backgroundColor: "rgba(3, 2, 41, 0.4)",
            transition: {
                duration: 0.3,
            },
        },
    };

    const overlayComponentVariants: Variants = {
        closed: {
            x: "-100%",
            opacity: 0,
            transition: {
                duration: 0.3,
            },
        },
        open: {
            x: 0,
            opacity: 1,
            transition: {
                duration: 0.3,
            },
        },
    };

    return (
        <AnimatePresence>
            {props.showPopup ? (
                <motion.div
                    ref={ref}
                    className="popup-wrapper"
                    variants={popupWrapperVariants}
                    initial="closed"
                    animate={props.showPopup ? "open" : "closed"}
                    exit="closed"
                >
                    {props.showPopup ? (
                        <motion.div
                            className="overlay-component"
                            variants={overlayComponentVariants}
                            initial="closed"
                            animate={props.showPopup ? "open" : "closed"}
                            exit="closed"
                        >
                            {props.overlayComponent}
                        </motion.div>
                    ) : null}

                    <motion.div
                        className={popupClasses.join(" ")}
                        variants={popupVariants}
                        initial="closed"
                        animate={props.showPopup ? "open" : "closed"}
                        exit="closed"
                        ref={popupRef}
                        style={{ width: props.width, maxWidth: props.width }}
                        id={props.id}
                    >
                        <div className="top">
                            <span className="text-sm">{props.title}</span>
                            <button type="button" onClick={props.onClose}>
                                <FontAwesomeIcon
                                    icon={faClose}
                                    size="2xl"
                                    color="var(--color-neutral-600)"
                                />
                            </button>
                        </div>
                        {props.children}
                    </motion.div>
                </motion.div>
            ) : null}
        </AnimatePresence>
    );
});

export default Popup;
