import { useTranslation } from "react-i18next";

function useStopTypeToShowOptions() {
    const { t } = useTranslation();
    const options = [
        {
            label: t("fleetPlanner.stopDraftsMapStopTypeBoth"),
            value: "both",
        },
        {
            label: t("fleetPlanner.stopDraftsMapStopTypePickup"),
            value: "pickup",
        },
        {
            label: t("fleetPlanner.stopDraftsMapStopTypeDropoff"),
            value: "dropoff",
        },
    ];

    return options;
}

export default useStopTypeToShowOptions;
