import { faSquare } from "@fortawesome/pro-regular-svg-icons";
import { faSquareCheck } from "@fortawesome/pro-solid-svg-icons";
import { Map as GoogleMap, MapProps } from "@vis.gl/react-google-maps";
import { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/buttons/Button";
import useMapTraffic from "../../hooks/functionality/useMapTraffic";
import "./style.scss";

type Props = {
    showTrafficToggle?: boolean;
} & PropsWithChildren<MapProps>;

function Map(props: Props) {
    const { t } = useTranslation();
    const [showTraffic, setShowTraffic] = useState(false);

    useMapTraffic({
        hide: !showTraffic,
    });

    return (
        <div className="map">
            <div className="controls">
                {props.showTrafficToggle && (
                    <Button
                        onClick={() => setShowTraffic((state) => !state)}
                        variant={"secondary"}
                        label={t("general.showTraffic")}
                        leadingIcon={showTraffic ? faSquareCheck : faSquare}
                        leadingIconColor={
                            showTraffic ? "var(--color-primary-400)" : undefined
                        }
                    />
                )}
            </div>
            <GoogleMap {...props}>{props.children}</GoogleMap>
        </div>
    );
}

export default Map;
