export enum GOOGLE_MAP_IDS {
    DriversMap = "7bdfe5fa631540f",
    MapButton = "c7c6a453a8cf720d",
    TourMap = "97e1ecaaeb403d41",
    PinMap = "ed325fbbfbeec261",
    UnhandledStopsMap = "12a714a0399488",
    ToursMap = "6849f94e371de149",
    ExternalOrderMap = "d4ccb97502f784dd",
    ManualOrderMap = "85e41294bca43956",
    PublicOrderMap = "6e7bb0327854076a",
    TrackingMap = "2dc3ceb330b8e851",
    ConfirmOrderMap = "869a667436235e91",
    MarkAsExternalMap = "f54e98c9ec4c0f71",
    GroupTourMap = "281e550a409f7787",
}
