import Markdown from "markdown-to-jsx";
import "./style.scss";
import { useTranslation } from "react-i18next";

type Props = {
    author: "user" | "assistant";
    message: string;
};

function AssistantMessage(props: Props) {
    const { t } = useTranslation();

    const assistantMessageClasses = ["assistant-message", props.author];

    return (
        <div className={assistantMessageClasses.join(" ")}>
            <p className="text-sm">
                {props.author === "assistant"
                    ? t("general.assistantName")
                    : t("general.userChatName")}
            </p>
            <div className="message">
                <Markdown>{props.message}</Markdown>
            </div>
        </div>
    );
}

export default AssistantMessage;
