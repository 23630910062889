import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { useMemo } from "react";
import useDirections from "../../../hooks/functionality/useDirections";
import useMapFitBounds from "../../../hooks/functionality/useMapFitBounds";
import { StopDraft, StopDraftsTour } from "../../../shared/types/api";
import { KeyString } from "../../../shared/types/internal";
import { getStopStatus } from "../../../shared/utility/stop-draft";
import "./style.scss";

type Props = {
    tour?: StopDraftsTour;
    stops?: StopDraft[];
    disableFitBounds?: boolean;
    boundsPadding?: number;
    tourColor?: string;
    disableDirections?: boolean;
};

function TourMarkers(props: Props) {
    const mapPoints = useMemo(() => {
        const stopList = props.stops || props.tour?.stops;

        if (!stopList) return [];

        return stopList.map((stop, i) => {
            const stopStatus = getStopStatus(stop);
            return {
                label: `${i + 1}`,
                location: {
                    lat: +stop.lat!,
                    lng: +stop.lng!,
                },
                stopStatus,
            };
        });
    }, [props.stops, props.tour?.stops]);

    useMapFitBounds({
        path: mapPoints.map((point) => point.location),
        disable: props.disableFitBounds,
        boundsPadding: props.boundsPadding,
    });

    useDirections({
        path: mapPoints.map((point) => point.location),
        directionsColor: props.tourColor,
        hide: props.disableDirections,
    });

    const latLngStopCounts = useMemo(() => {
        const latLngCounts: KeyString<number> = {};
        for (let i = 0; i < mapPoints.length; i++) {
            const point = mapPoints[i];

            const key = `${point.location.lat},${point.location.lng}`;
            latLngCounts[key] = (latLngCounts[key] || 0) + 1;
        }
        return latLngCounts;
    }, [mapPoints]);

    return (
        <>
            {mapPoints.map((point) => (
                <AdvancedMarker key={point.label} position={point.location}>
                    <div
                        className="tour-marker"
                        style={{
                            backgroundColor: props.tourColor,
                        }}
                    >
                        {point.stopStatus.isCompleted ? (
                            <FontAwesomeIcon icon={faCheck} />
                        ) : (
                            <span className="text-xs">{point.label}</span>
                        )}
                    </div>
                </AdvancedMarker>
            ))}
        </>
    );
}

export default TourMarkers;
