import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, isAxiosError } from "axios";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { registerAccount } from "../../../api/auth";
import ButtonOLD from "../../../components/buttons/ButtonOLD";
import TextButtonOLD from "../../../components/buttons/TextButtonOLD";
import InputOLD from "../../../components/inputs/InputOLD";
import { RegisterForm, registerForm } from "../../../schemas/auth";
import { onFormError } from "../../../shared/utility/misc";
import { API_ERROR, ROUTE } from "../../../shared/values/enums";
import "./style.scss";

type Props = {};

function Register(props: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { control, handleSubmit } = useForm<RegisterForm>({
        resolver: zodResolver(registerForm),
        defaultValues: {
            email: "",
        },
    });

    const { mutate: registerHandler, isPending } = useMutation({
        mutationFn: async (data: RegisterForm) => {
            await registerAccount({
                email: data.email,
                source: "connect",
            });

            return data;
        },
        onSuccess: () => {
            toast.success(t("successMessage.register"));
            navigate(ROUTE.EmailSent);
        },
        onError: (error) => {
            if (isAxiosError(error)) {
                const axiosError = error as AxiosError<{ detail: string }>;

                if (axiosError.response?.data.detail === API_ERROR.EmailInUse) {
                    toast.error(t("errorMessage.emailAlreadyExists"));
                    return;
                }
            }

            toast.error(t("errorMessage.setupError"));
        },
    });

    return (
        <div className="register">
            <form
                onSubmit={handleSubmit(
                    (data) => registerHandler(data),
                    onFormError
                )}
            >
                <h1 className="text-sm">{t("register.title")}</h1>
                <hr />
                <Controller
                    name="email"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <InputOLD
                            type="email"
                            value={value}
                            onChange={onChange}
                            width="100%"
                            placeholder={t("settings.emailPlaceholder")}
                            label={t("settings.emailLabel")}
                        />
                    )}
                />
                <ButtonOLD
                    color="secondary"
                    label={t("register.submitButtonLabel")}
                    width="100%"
                    isLoading={isPending}
                />
            </form>
            <div className="login-wrapper">
                <p className="text-xs">{t("register.hasAccount")}</p>
                <NavLink to={ROUTE.Login}>
                    <TextButtonOLD
                        label={t("register.loginLabel")}
                        color="primary"
                    />
                </NavLink>
            </div>
        </div>
    );
}

export default Register;
