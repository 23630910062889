import { useQuery } from "@tanstack/react-query";
import { getOrderOptions } from "../../api/order";

function useOrderOptions() {
    const { data: orderOptions, status: orderOptionsStatus } = useQuery({
        queryKey: ["order-options"],
        queryFn: async () => {
            const res = await getOrderOptions();
            return res.data;
        },
        refetchOnWindowFocus: true,
        staleTime: 0,
    });

    switch (orderOptionsStatus) {
        case "success":
            return {
                orderOptionsStatus,
                orderOptions,
            };
        case "pending":
            return {
                orderOptionsStatus,
                orderOptions,
            };
        case "error":
            return {
                orderOptionsStatus,
                orderOptions,
            };
    }
}

export default useOrderOptions;
