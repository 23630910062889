import { faSquare } from "@fortawesome/pro-regular-svg-icons";
import { faSquareCheck, faSquareMinus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.scss";

type Props = {
    label?: string;
    subLabel?: string;
    checked: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
    style?: React.CSSProperties;
};

function Checkbox(props: Props) {
    const checkboxClasses = ["checkbox"];
    if (props.checked) {
        checkboxClasses.push("checked");
    }
    if (props.disabled) {
        checkboxClasses.push("disabled");
    }

    return (
        <label
            className={checkboxClasses.join(" ")}
            style={{
                cursor: props.disabled ? "default" : "pointer",
                ...props.style,
            }}
            onClick={(e) => e.stopPropagation()}
        >
            <input
                type="checkbox"
                checked={props.checked}
                disabled={props.disabled}
                onChange={(e) => {
                    props.onChange(e.target.checked);
                }}
                hidden
            />
            {props.checked ? (
                <FontAwesomeIcon
                    icon={faSquareCheck}
                    size="xl"
                    color="var(--color-primary-400)"
                    fixedWidth
                />
            ) : (
                <FontAwesomeIcon
                    icon={props.disabled ? faSquareMinus : faSquare}
                    color={
                        props.disabled
                            ? "var(--color-neutral-300)"
                            : "var-color-neutral-600"
                    }
                    size="xl"
                    fixedWidth
                />
            )}
            {props.label && (
                <span
                    style={{
                        color: props.checked
                            ? "var(--color-neutral-900)"
                            : "var(--color-neutral-600)",
                    }}
                >
                    {props.label}
                </span>
            )}
            {props.subLabel && (
                <span
                    style={{
                        marginLeft: "auto",
                        color: "var(--color-neutral-400)",
                    }}
                >
                    {props.subLabel}
                </span>
            )}
        </label>
    );
}

export default Checkbox;
