import { Draggable } from "@hello-pangea/dnd";
import StopCard, { StopCardProps } from "../StopCard";
import "./style.scss";

type Props = {
    id: string;
    isDragDisabled?: boolean;
} & StopCardProps;

function StopCardDraggable(props: Props) {
    return (
        <Draggable
            draggableId={props.id}
            index={props.index}
            isDragDisabled={props.isDragDisabled}
        >
            {(provided) => <StopCard provided={provided} {...props} />}
        </Draggable>
    );
}

export default StopCardDraggable;
