import { useInfiniteQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { getFulfilledTourOrders } from "../../api/tours";
import ShowingAllMessage from "../../components/UI/ShowingAllMessage";
import Spinner from "../../components/UI/Spinner";
import OrdersSearch from "../../containers/OrdersSearch";
import FleetOrdersTable from "../../fleet-planner/FleetOrdersTable";
import NoFleetTours from "../../fleet-planner/NoFleetTours";
import useOrderSearchOptions from "../../hooks/functionality/useOrderSearchOptions";
import OrderInfoPopup from "../../popups/OrderInfoPopup";
import { Order } from "../../shared/types/api";
import { API_MAX_PER_PAGE } from "../../shared/values/enums";
import "./style.scss";

type Props = {};

function FulfilledFleetTours(props: Props) {
    const { t } = useTranslation();
    const orderSearchOptions = useOrderSearchOptions("fleet-planner");

    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

    const [searchString, setSearchString] = useState("");
    const [searchType, setSearchType] = useState(orderSearchOptions[0].value);

    const {
        data: orders,
        status,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery({
        initialPageParam: 1,
        queryKey: ["fulfilled-fleet", searchType, searchString] as const,
        queryFn: async ({
            queryKey: [_, searchType, searchString],
            pageParam,
        }) => {
            const res = await getFulfilledTourOrders({
                searchType: searchType,
                searchString: searchString,
                page: pageParam,
            });
            return res.data.items;
        },
        getNextPageParam: (lastPage, pages) =>
            lastPage.length < API_MAX_PER_PAGE.StopDraftsTour
                ? undefined
                : pages.length + 1,
    });

    let flatOrders: Order[] = [];
    if (status === "success") {
        flatOrders = orders.pages.flat();
    }

    return (
        <div className="fulfilled-fleet-tours">
            <OrdersSearch
                orderType={"fleet-planner"}
                searchString={searchString}
                searchType={searchType}
                onSearchStringChange={setSearchString}
                onSearchTypeChange={setSearchType}
            />
            <div className="content">
                {status !== "success" && <Spinner padding="10px" />}

                {status === "success" && !flatOrders.length && (
                    <NoFleetTours mode="fulfilled" />
                )}

                {status === "success" && !!flatOrders.length && (
                    <InfiniteScroll
                        dataLength={flatOrders.length}
                        next={fetchNextPage}
                        hasMore={!!hasNextPage}
                        loader={<Spinner padding="10px" />}
                        endMessage={
                            <ShowingAllMessage
                                text={t("fulfilledFleet.showingAllOrders")}
                            />
                        }
                        scrollThreshold={0.5}
                    >
                        <FleetOrdersTable
                            rows={flatOrders}
                            onRowClick={setSelectedOrder}
                        />
                    </InfiniteScroll>
                )}
            </div>

            <OrderInfoPopup
                order={selectedOrder}
                showPopup={!!selectedOrder}
                onClose={() => setSelectedOrder(null)}
            />
        </div>
    );
}

export default FulfilledFleetTours;
