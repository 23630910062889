import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    FleetPlannerSettings,
    FleetPlannerState,
} from "../../shared/types/redux";
import { updateFleetPlannerSettingsLocalStorage } from "../../shared/utility/misc";

const initialState: FleetPlannerState = {
    settings: {
        showAlrikColumn: 1,
        showOutsideColumn: 1,
        showInboxColumn: 1,
        filterUnhandledByDate: 1,
        showCargoContent: 1,
        showAllDriversInCompany: 0,
        hiddenDriverIds: {},
    },
};

const fleetPlannerSlice = createSlice({
    name: "fleetPlanner",
    initialState,
    reducers: {
        setFleetPlannerSettings: (
            state,
            action: PayloadAction<Partial<FleetPlannerSettings>>
        ) => {
            state.settings = { ...state.settings, ...action.payload };
            updateFleetPlannerSettingsLocalStorage(state.settings);
        },
    },
});

export const { setFleetPlannerSettings } = fleetPlannerSlice.actions;

export default fleetPlannerSlice.reducer;
