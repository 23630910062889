import { DraggableProvided } from "@hello-pangea/dnd";
import { useMemo } from "react";
import Badge from "../../../components/UI/Badge";
import { StopDraft } from "../../../shared/types/api";
import { formatEta } from "../../../shared/utility/date";
import { STOP_DRAFT_TYPE } from "../../../shared/values/enums";
import Spinner from "../../../components/UI/Spinner";
import {
    getStopLocationText,
    getStopStatus,
} from "../../../shared/utility/stop-draft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isAfter, parse } from "date-fns";
import { faWarning } from "@fortawesome/pro-duotone-svg-icons";
import { faTag } from "@fortawesome/pro-regular-svg-icons";
import StopCardIcon from "../../../components/UI/StopCardIcon";
import { useTranslation } from "react-i18next";
import "./style.scss";

export type StopCardProps = {
    // Drag and Drop
    provided?: DraggableProvided;

    //Core data
    stop: StopDraft;
    index: number;
    runningWeight?: number;
    eta?: string;

    //states
    isLoading?: boolean;
    color?: string;

    //actions handlers
    onHover?: (groupId: number) => void;
    onClick?: () => void;
};

function StopCard(props: StopCardProps) {
    const { t } = useTranslation();

    const warningIconStyle = {
        "--fa-primary-color": "var(--color-neutral-900)",
        "--fa-primary-opacity": "1",
        "--fa-secondary-color": "var(--color-yellow-400)",
        "--fa-secondary-opacity": "1",
    };

    const etaString = useMemo(() => {
        return formatEta(
            props.stop.completed_at ||
                props.stop.arrived_at ||
                props.stop.eta_internal ||
                undefined
        );
    }, [
        props.stop.arrived_at,
        props.stop.completed_at,
        props.stop.eta_internal,
    ]);

    const isLate = useMemo(() => {
        if (!props.stop.time_tooltip || !etaString || !props.eta) return false;

        const parsedTooltip = parse(
            props.stop.time_tooltip,
            "HH:mm",
            new Date()
        );
        const parsedEta = parse(etaString || props.eta, "HH:mm", new Date());

        return isAfter(parsedEta, parsedTooltip);
    }, [props.stop.time_tooltip, props.eta, etaString]);

    const { isCompleted } = useMemo(() => {
        return getStopStatus(props.stop);
    }, [props.stop]);

    return (
        <div
            className="stop-card"
            onMouseEnter={() => props.onHover?.(props.stop.group_id)}
            onMouseLeave={() => props.onHover?.(-1)}
            data-group-id={props.stop.group_id}
            data-card-id={props.stop.id}
            ref={props.provided?.innerRef}
            onClick={props.onClick}
            {...props.provided?.draggableProps}
            {...props.provided?.dragHandleProps}
            style={{
                ...props.provided?.draggableProps.style,
                opacity: isCompleted ? 0.5 : 1,
            }}
        >
            {props.isLoading && (
                <div className="loading-wrapper">
                    <Spinner />
                </div>
            )}
            <div className="top">
                <div className="header">
                    <StopCardIcon
                        isCompleted={isCompleted}
                        label={`${props.index + 1}`}
                        color={props.color}
                    />
                    <div className="header-info">
                        <p className="type text-2xs">
                            {props.stop.stop_type_id === STOP_DRAFT_TYPE.Pickup
                                ? t("bigVolume.loading")
                                : t("bigVolume.unloading")}
                        </p>
                        <p
                            className="address text-xs"
                            title={props.stop.to_location}
                        >
                            {getStopLocationText(props.stop)}
                        </p>
                    </div>
                </div>
                <div className="eta-wrapper">
                    {props.stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff &&
                        isLate && (
                            <FontAwesomeIcon
                                icon={faWarning}
                                style={warningIconStyle as React.CSSProperties}
                                size="lg"
                            />
                        )}
                    <p className="eta">{etaString || props.eta || "-"}</p>
                </div>
            </div>
            <div className="info">
                {props.stop.weight_kg ? (
                    <p className="text-xs">
                        {props.runningWeight !== undefined
                            ? `${
                                  props.stop.stop_type_id ===
                                  STOP_DRAFT_TYPE.Pickup
                                      ? "+"
                                      : "-"
                              }${props.stop.weight_kg} kg (${
                                  props.runningWeight
                              } kg)`
                            : `${props.stop.weight_kg} kg`}
                    </p>
                ) : (
                    <div />
                )}
                {props.stop.tag && (
                    <Badge
                        title={props.stop.tag}
                        variant="neutral"
                        icon={faTag}
                    />
                )}
            </div>
        </div>
    );
}

export default StopCard;
