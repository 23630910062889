import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
    bulkDeleteStopDrafts,
    createStopDrafts,
    getStopDrafts,
} from "../../api/stop-draft";
import { archiveStopDraftTour, getActiveTours } from "../../api/tours";
import optimeraData from "../../shared/data/lev-volym-optimera.json";
import { CreateStopDraftData } from "../../shared/types/api";
import { ReduxState } from "../../shared/types/redux";
import { generateDemoStopDrafts } from "../../shared/utility/stop-draft";
import { STOP_DRAFT_TYPE } from "../../shared/values/enums";
import { fakeFromAddresses, fakeToAddresses } from "../../shared/values/lists";

type OptimeraData = {
    "Pickup adress": string;
    "Lev.Filial": string;
    Pr: string;
    Status: string;
    Klocka: string;
    Ordernr: string;
    Kund: string;
    Vikt: string;
    Volym: string;
    "Deln.kod": string;
    "O-typ": string;
    Postadress: string;
    "Adress 2": string;
    Kolli: string;
    Lagerplats: string;
    "Transp.sätt": string;
    "Status Tr": string;
    "Lev.adress/Lev.omr": string;
    "Lev.anvisning 1"?: string | null;
    "Lev.anvisning 2"?: string | null;
    "Lev.område": string;
    "Märke/Ordernr": string;
    Referens: string;
    Mottagare: string;
    Fraktsedelsnr: string;
};

function useDemoMutations({ onSuccess }: { onSuccess?: () => void }) {
    const { t } = useTranslation();
    const { user } = useSelector((state: ReduxState) => state.auth);

    const {
        mutate: createDemoStopDrafts,
        isPending: isCreatingDemoStopDrafts,
    } = useMutation({
        mutationFn: async () => {
            const stopDrafts = generateDemoStopDrafts({
                region: user?.location_entity?.region?.region_code,
            });

            const promises = [];

            for (let i = 0; i < stopDrafts.length; i++) {
                const stops = stopDrafts[i];
                promises.push(createStopDrafts([stops.pickup, stops.dropoff]));
            }

            await Promise.all(promises);
        },
        onSuccess: () => {
            toast.success(t("successMessage.demoStopDraftsCreated"));
            onSuccess?.();
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    const {
        mutate: createTestStopDrafts,
        isPending: isCreatingTestStopDrafts,
    } = useMutation({
        mutationFn: async () => {
            const stopDrafts = generateDemoStopDrafts({
                region: user?.location_entity?.region?.region_code,
                from: fakeFromAddresses,
                to: fakeToAddresses,
            });

            const promises = [];

            for (let i = 0; i < stopDrafts.length; i++) {
                const stops = stopDrafts[i];
                promises.push(createStopDrafts([stops.pickup, stops.dropoff]));
            }

            await Promise.all(promises);
        },
        onSuccess: () => {
            toast.success(t("successMessage.testStopDraftsCreated"));
            onSuccess?.();
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    const {
        mutate: createOptimeraTestStops,
        isPending: isCreatingOptimeraTestStops,
    } = useMutation({
        mutationFn: async () => {
            const optimeraSections = [];
            const sectionsSize = 10;
            for (let i = 0; i < optimeraData.length; i += sectionsSize) {
                optimeraSections.push(optimeraData.slice(i, i + sectionsSize));
            }

            for (let i = 0; i < optimeraSections.length; i++) {
                const section: OptimeraData[] = optimeraSections[i];

                const promises = [];
                for (let i = 0; i < section.length; i++) {
                    const data = section[i];

                    let driverInstructions = "";
                    if (data["Lev.anvisning 1"]) {
                        driverInstructions += `${data["Lev.anvisning 1"]}\n`;
                    }
                    if (data["Lev.anvisning 2"]) {
                        driverInstructions += `${data["Lev.anvisning 2"]}`;
                    }

                    let tag = "";
                    if (data["Klocka"]) {
                        tag = data["Klocka"];
                    }
                    if (data["Klocka"] && data["Pr"]) {
                        tag += " ";
                    }
                    if (data["Pr"]) {
                        tag += data["Pr"];
                    }

                    let cargoContent = "";
                    if (data["Lev.område"]) {
                        cargoContent += `${data["Lev.område"]}\n`;
                    }
                    if (data["Märke/Ordernr"]) {
                        cargoContent += `${data["Märke/Ordernr"]}\n`;
                    }
                    if (data["Lagerplats"]) {
                        cargoContent += `${data["Lagerplats"]}\n`;
                    }
                    if (data["Transp.sätt"]) {
                        cargoContent += `${data["Transp.sätt"]}\n`;
                    }

                    const pickup: CreateStopDraftData = {
                        to_location: data["Pickup adress"],
                        order_number: data["Ordernr"],
                        phone: "",
                        estimated_offload_time: "15",
                        stop_type_id: STOP_DRAFT_TYPE.Pickup,
                        cargo_content: cargoContent,
                        tag,
                        weight_kg: +data["Vikt"],
                    };

                    const dropoff: CreateStopDraftData = {
                        to_location: `${data["Adress 2"]}, ${data["Postadress"]}`,
                        order_number: data["Ordernr"],
                        phone: "",
                        estimated_offload_time: "15",
                        stop_type_id: STOP_DRAFT_TYPE.Dropoff,
                        cargo_content: cargoContent,
                        driver_instructions: driverInstructions,
                        tag,
                        weight_kg: +data["Vikt"],
                    };

                    const stops = [pickup, dropoff];

                    promises.push(createStopDrafts(stops));
                }
                await Promise.all(promises);
                toast.success(
                    `${(i + 1) * section.length}/${
                        optimeraData.length
                    } orders created`
                );
            }
        },
        onSuccess: () => {
            toast.success(t("successMessage.testStopDraftsCreated"));
            onSuccess?.();
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    const { mutate: deleteAllStopDrafts, isPending: isDeletingAllStopDrafts } =
        useMutation({
            mutationFn: async () => {
                const {
                    data: { stop_drafts: stopDrafts },
                } = await getStopDrafts();

                await bulkDeleteStopDrafts(stopDrafts.map((sd) => sd.id));
            },

            onSuccess: () => {
                toast.success(t("successMessage.allStopDraftsDeleted"));
                onSuccess?.();
            },

            onError: () => {
                toast.error(t("errorMessage.unknown"));
            },
        });

    const {
        mutate: archiveAllActiveTours,
        isPending: isArchivingAllActiveTours,
    } = useMutation({
        mutationFn: async () => {
            const { data: tours } = await getActiveTours();
            for (let i = 0; i < tours.length; i++) {
                const tour = tours[i];
                await archiveStopDraftTour(tour.tour_id);
            }
        },

        onSuccess: () => {
            toast.success(t("successMessage.allActiveToursArchived"));
            onSuccess?.();
        },

        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    return {
        createDemoStopDrafts,
        isCreatingDemoStopDrafts,
        deleteAllStopDrafts,
        createTestStopDrafts,
        isCreatingTestStopDrafts,
        isDeletingAllStopDrafts,
        archiveAllActiveTours,
        isArchivingAllActiveTours,
        createOptimeraTestStops,
        isCreatingOptimeraTestStops,
    };
}

export default useDemoMutations;
