import { forwardRef } from "react";
import Spinner from "../../UI/Spinner";
import "./style.scss";

type Props = {
    onClick?: (event: React.MouseEvent) => void;
    amount?: number;
    color?: string;
    isLoading?: boolean;
    style?: React.CSSProperties;
    large?: boolean;
    dotStyle?: React.CSSProperties;
    title?: string;
};

const DotsButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const dotsButtonClasses = ["dots-button"];
    if (props.large) dotsButtonClasses.push("large");

    return (
        <button
            ref={ref}
            className={dotsButtonClasses.join(" ")}
            onClick={props.onClick}
            type="button"
            style={props.style}
        >
            {props.title && (
                <span className="title text-sm">{props.title}</span>
            )}
            {props.isLoading ? (
                <Spinner />
            ) : (
                Array(props.amount || 3)
                    .fill(0)
                    .map((_, i) => (
                        <div
                            className="dot"
                            key={i}
                            style={{
                                backgroundColor: props.color,
                                ...props.dotStyle,
                            }}
                        />
                    ))
            )}
        </button>
    );
});

export default DotsButton;
