import nodeAxios from "axios";
import environment from "../../environment";
import { getLocale, getVersion } from "../utility/misc";
import { LOCAL_STORAGE_KEY } from "../values/enums";
import store from "../../store";
import { setHasBadVersion } from "../../store/slices/auth";

const axios = nodeAxios.create({
    baseURL: environment.orderApiProtocol + environment.orderApiUrl,
});

axios.interceptors.request.use((config) => {
    const memberToken = localStorage.getItem(
        LOCAL_STORAGE_KEY.MemberstackToken
    );

    config.headers["x-member-token"] = memberToken;
    config.headers["x-user-locale"] = getLocale();
    config.headers["x-user-version"] = getVersion();
    return config;
});

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.status === 8000) {
            store.dispatch(setHasBadVersion(true));
        }
        return Promise.reject(error);
    }
);

export default axios;
