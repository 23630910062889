import environment from "../environment";
import axios from "../shared/services/axios";
import { AssistantChatMessage } from "../shared/types/api";

export function sendChatMessage(message: string) {
    return axios.post<{
        response: AssistantChatMessage;
        function_name?: "create_stop_draft";
    }>(
        `/alrik-assistant-chat-${environment.environment}`,
        {
            message: message,
        },
        {
            baseURL: environment.cloudFunctionUrl,
        }
    );
}

export function getChatHistory() {
    return axios.get<AssistantChatMessage[]>(
        `/alrik-assistant-chat-${environment.environment}`,
        {
            baseURL: environment.cloudFunctionUrl,
        }
    );
}

export function deleteChatHistory() {
    return axios.delete(`/alrik-assistant-chat-${environment.environment}`, {
        baseURL: environment.cloudFunctionUrl,
    });
}
