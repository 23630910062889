import environment from "../environment";
import axios from "../shared/services/axios";

export function uploadFile(file: File) {
    const formData = new FormData();
    formData.append("file", file);

    return axios.post<{ file_name: string }>("/v2/upload", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export function convertFileToOrderDraft(filename: string) {
    return axios.get(`/alrik-gpt-${environment.environment}`, {
        params: {
            file_name: filename,
        },
        baseURL: environment.cloudFunctionUrl,
    });
}
