import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { deleteChatHistory, sendChatMessage } from "../../api/assistant";
import Spinner from "../../components/UI/Spinner";
import IconButton from "../../components/buttons/IconButton";
import TextareaOLD from "../../components/inputs/TextareaOLD";
import AssistantMessage from "../../components/widgets/AssistantMessage";
import Modal from "../../hoc/Modal";
import useAssistantChat from "../../hooks/data/useAssistantChat";
import { AssistantChatMessage } from "../../shared/types/api";
import { generateUUID } from "../../shared/utility/misc";
import "./style.scss";
import alertPrompt from "../../components/widgets/alertPrompt";

type Props = {};

function AssistantChat(props: Props) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const {
        messages,
        isFetching,
        refetch: refetchMessages,
    } = useAssistantChat();

    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [sentMessage, setSentMessage] = useState<AssistantChatMessage | null>(
        null
    );

    const { mutate: sendMessage, isPending: isSending } = useMutation({
        mutationFn: async (message: string) => {
            if (!message) {
                toast.error(t("errorMessage.emptyMessage"));
            }

            setSentMessage({
                message: message,
                role: "user",
                id: generateUUID(),
            });
            setMessage("");

            const res = await sendChatMessage(message);

            return res.data.function_name;
        },
        onSuccess: async (functionName) => {
            if (functionName === "create_stop_draft") {
                queryClient.invalidateQueries({
                    queryKey: ["stop-drafts"],
                    type: "all",
                });
            }
            await refetchMessages();
            setMessage("");
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
            setMessage(sentMessage ? sentMessage.message : "");
        },
        onSettled: () => {
            setSentMessage(null);
        },
    });

    const { mutate: deleteHistory, isPending: isDeleting } = useMutation({
        mutationFn: async () => {
            await deleteChatHistory();
        },
        onSuccess: async () => {
            await refetchMessages();
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    const deleteHistoryHandler = useCallback(async () => {
        if (
            !(await alertPrompt({
                title: t("assistantChat.deleteHistory"),
                message: t("assistantChat.deleteHistoryText"),
            }))
        ) {
            return;
        }

        deleteHistory();
    }, [deleteHistory, t]);

    return (
        <div className="assistant-chat">
            <Modal
                buttonElement={(ref) => (
                    <IconButton
                        ref={ref}
                        icon="comment"
                        onClick={() => setIsOpen((state) => !state)}
                    />
                )}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                align="right"
                topAlign
                width="700px"
            >
                <div className="chat">
                    <div className="content">
                        {isFetching && <Spinner />}
                        {sentMessage && (
                            <AssistantMessage
                                key={sentMessage.id}
                                author={sentMessage.role}
                                message={sentMessage.message}
                            />
                        )}
                        {messages?.map((m) => (
                            <AssistantMessage
                                key={m.id}
                                author={m.role}
                                message={m.message}
                            />
                        ))}
                    </div>
                    <form
                        className="controls"
                        onSubmit={(e) => {
                            e.preventDefault();
                            sendMessage(message);
                        }}
                    >
                        <TextareaOLD
                            value={message}
                            onChange={setMessage}
                            width="100%"
                        />
                        <div className="buttons">
                            <IconButton
                                icon="arrow-up"
                                short
                                isLoading={isSending}
                            />
                            <IconButton
                                icon="trash"
                                short
                                isLoading={isDeleting}
                                onClick={deleteHistoryHandler}
                            />
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    );
}

export default AssistantChat;
