import { motion, Variants } from "framer-motion";
import { useState } from "react";
import IconButton from "../../buttons/IconButton";
import "./style.scss";

type Props = {
    question: string;
    answer: string;
};

function FaqRow(props: Props) {
    const [isOpen, setIsOpen] = useState(false);

    const answerVaraints: Variants = {
        open: {
            height: "unset",
            opacity: 1,
            marginTop: 20,
            transition: {
                duration: 0.2,
            },
        },
        closed: {
            height: 0,
            opacity: 0,
            marginTop: 0,
            transition: {
                duration: 0.2,
            },
        },
    };

    return (
        <div className="faq-row" onClick={() => setIsOpen(!isOpen)}>
            <div className="top">
                <span className="text-sm">{props.question}</span>
                <IconButton
                    icon={"plus"}
                    style={{
                        backgroundColor: "transparent",
                        color: "var(--text-color)",
                        fontSize: "20px",
                        transform: isOpen ? "rotate(45deg)" : "rotate(0deg)",
                    }}
                />
            </div>
            <motion.div
                className="answer"
                variants={answerVaraints}
                animate={isOpen ? "open" : "closed"}
                initial="closed"
            >
                <p className="text-xs" onClick={(e) => e.stopPropagation()}>
                    {props.answer}
                </p>
            </motion.div>
        </div>
    );
}

export default FaqRow;
