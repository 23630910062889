import { DragDropContext, OnDragEndResponder } from "@hello-pangea/dnd";
import ScrollContainer from "react-indiana-drag-scroll";
import useDrivers from "../../hooks/data/useDrivers";
import { StopDraft, StopDraftsTour } from "../../shared/types/api";
import { KeyString } from "../../shared/types/internal";
import { NEW_TOUR_COLUMN_ID } from "../../shared/values/enums";
import TourColumn from "../columns/TourColumn";
import "./style.scss";

type Props = {
    tours: StopDraftsTour[];
    stops: StopDraft[];
    filterDate: Date | null;
    onDragEnd: OnDragEndResponder;
    onCreateNewTour: (data: {
        preferredDriverId: string;
        date: string;
        time: string;
        stopDraftIds: number[];
    }) => Promise<void>;
    onDispatchTour: (tourId: number) => Promise<void>;
    onUpdateTour: (data: {
        tourId: number;
        date: string;
        time: string;
        preferredDriverId: string;
    }) => Promise<void>;
    columnIdsLoading: string[];
    stopIdsLoading: number[];
    tourColorMap?: KeyString<string>;
    onStopCardClick?: ({
        pickup,
        dropoff,
    }: {
        pickup: StopDraft;
        dropoff: StopDraft;
    }) => void;
    onClearStops?: () => void;
};

function ToursDragDrop(props: Props) {
    const { drivers } = useDrivers();

    return (
        <div className="tours-drag-drop">
            <ScrollContainer
                className="content"
                ignoreElements=".stop-card, .dropdown-wrapper-old, .input"
                vertical={false}
                hideScrollbars={false}
            >
                <DragDropContext onDragEnd={props.onDragEnd}>
                    <TourColumn
                        key={NEW_TOUR_COLUMN_ID}
                        id={NEW_TOUR_COLUMN_ID}
                        drivers={drivers || []}
                        stops={props.stops}
                        filterDate={props.filterDate}
                        onCreateNewTour={props.onCreateNewTour}
                        onUpdateTour={props.onUpdateTour}
                        onDispatchTour={props.onDispatchTour}
                        stopIdsLoading={props.stopIdsLoading}
                        isNewTourColumn
                        tourColor={props.tourColorMap?.[NEW_TOUR_COLUMN_ID]}
                        onStopCardClick={props.onStopCardClick}
                        onClearStops={props.onClearStops}
                    />
                    {props.tours.map((tour) => (
                        <TourColumn
                            key={tour.tour_id}
                            id={tour.tour_id.toString()}
                            tour={tour}
                            drivers={drivers || []}
                            stops={tour.stops}
                            filterDate={props.filterDate}
                            onCreateNewTour={props.onCreateNewTour}
                            onUpdateTour={props.onUpdateTour}
                            onDispatchTour={props.onDispatchTour}
                            isLoading={props.columnIdsLoading.includes(
                                tour.tour_id.toString()
                            )}
                            stopIdsLoading={props.stopIdsLoading}
                            tourColor={props.tourColorMap?.[tour.tour_id]}
                            onStopCardClick={props.onStopCardClick}
                        />
                    ))}
                </DragDropContext>
            </ScrollContainer>
        </div>
    );
}

export default ToursDragDrop;
