import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import ButtonOLD from "../../components/buttons/ButtonOLD";
import { OrderMode } from "../../shared/types/internal";
import { ROUTE } from "../../shared/values/enums";
import "./style.scss";

type Props = {
    mode: OrderMode;
};

function NoOrders(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="no-orders">
            <div className="top" />
            <div className="content">
                <h2 className="text-sm">
                    {props.mode === "active"
                        ? t("orderHistory.noActiveOrders")
                        : props.mode === "fulfilled"
                        ? t("orderHistory.noFulfilledOrders")
                        : t("orderHistory.noTours")}
                </h2>
                <p className="text-xs">
                    {t("orderHistory.noOrdersDescription")}
                </p>
                <NavLink to={ROUTE.NewOrder}>
                    <ButtonOLD
                        label={t("orderHistory.createFirstOrder")}
                        color="secondary"
                    />
                </NavLink>
            </div>
        </div>
    );
}

export default NoOrders;
