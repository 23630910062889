import packageJson from "../package.json";
import configJson from "./config.json";
import { AppEnvironment } from "./shared/types/internal";

const environment = {
    orderApiUrl: configJson.ORDER_API_URL,
    orderApiProtocol: configJson.ORDER_API_PROTOCOL,
    cloudFunctionUrl: configJson.CLOUD_FUNCTION_URL,
    memberStackToken: configJson.MEMBERSTACK_TOKEN,
    googlePlaceApiKey: configJson.GOOGLE_PLACE_API_KEY,
    ga4TrackingId: (configJson as any).GA4_TRACKING_ID as string,
    motionToolsTrackingUrl: configJson.MOTION_TOOLS_TRACKING_URL,
    locizeProjectId: configJson.LOCIZE_PROJECT_ID,
    locizeApiKey: configJson.LOCIZE_API_KEY,
    environment: configJson.ENVIRONMENT as AppEnvironment,
    appVersion: packageJson.version,
};

export default environment;
