import { useMutation } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { shareOrderPublic } from "../../../api/order";
import ButtonOLD from "../../../components/buttons/ButtonOLD";
import IconOLD from "../../../components/UI/IconOLD";
import StatusCircle from "../../../components/UI/StatusCircle";
import environment from "../../../environment";
import { Order } from "../../../shared/types/api";
import { formatDateString } from "../../../shared/utility/date";
import { getOrderStatus } from "../../../shared/utility/order";
import { QUERY_PARAM, ROUTE } from "../../../shared/values/enums";
import "./style.scss";

type Props = {
    order: Order;
    onClick: () => void;
};

function OrderRow(props: Props) {
    const { t } = useTranslation();

    const orderStatus = useMemo(() => {
        return getOrderStatus(props.order);
    }, [props.order]);

    const statusText = useMemo(() => {
        let statusText = "Status";
        switch (orderStatus) {
            case "created":
                statusText = t("orderHistory.statusCreated");
                break;
            case "verified":
                statusText = t("orderHistory.statusVerified");
                break;
            case "at-pickup":
                statusText = t("orderHistory.statusAtPickup");
                break;
            case "in-progress":
                statusText = t("orderHistory.statusInProgress");
                break;
            case "next-stop":
                statusText = t("orderHistory.statusNextStop");
                break;
            case "unloading":
                statusText = t("orderHistory.statusUnloading");
                break;
            case "fulfilled":
                statusText = t("orderHistory.statusFulfilled");
                break;
        }
        return statusText;
    }, [orderStatus, t]);

    const { mutate: navigateToTrackingLink, isPending: isNavigating } =
        useMutation({
            mutationFn: async (orderId: number) => {
                const { data } = await shareOrderPublic(orderId);

                return data.public_id;
            },
            onSuccess: (publicId) => {
                window.open(
                    `${window.location.origin}${ROUTE.Tracking}?${QUERY_PARAM.PublicOrderId}=${publicId}`,
                    "_blank"
                );
            },
            onError: () => {
                toast.error(t("errorMessage.unknown"));
            },
        });

    const trackClickHandler = useCallback(
        (order: Order, mode: "pickup" | "dropoff") => {
            if (order.shipping_controller === "Internal") {
                navigateToTrackingLink(order.id);
                return;
            }

            if (mode === "pickup") {
                if (order.pickup_tracking_code) {
                    window
                        .open(
                            `${environment.motionToolsTrackingUrl}${order.pickup_tracking_code}`,
                            "_blank",
                            "rel=noopener noreferrer"
                        )
                        ?.focus();
                    return;
                }
                toast.error(t("errorMessage.noTrackingCode"));
                return;
            }

            if (mode === "dropoff") {
                if (order.dropoff_tracking_code) {
                    window
                        .open(
                            `${environment.motionToolsTrackingUrl}${order.dropoff_tracking_code}`,
                            "_blank",
                            "rel=noopener noreferrer"
                        )
                        ?.focus();
                    return;
                }
                toast.error(t("errorMessage.noTrackingCode"));
                return;
            }
        },
        [navigateToTrackingLink, t]
    );

    return (
        <tr className="order-row" onClick={props.onClick}>
            <td className="text-xs">
                <div className="status-wrapper">
                    <StatusCircle status={orderStatus} />
                    {statusText}
                </div>
            </td>
            <td className="text-xs">{props.order.origin_order_id}</td>
            <td className="text-xs">
                <div className="created-wrapper">
                    <div
                        className="created-by"
                        title={`${props.order.customer?.first_name} ${props.order.customer?.last_name}`}
                    >
                        {`${props.order.customer?.first_name.charAt(
                            0
                        )}${props.order.customer?.last_name.charAt(
                            0
                        )}`.toUpperCase()}
                    </div>
                    {formatDateString(props.order.created_at || "")}
                </div>
            </td>
            <td className="text-xs">
                <div className="location-wrapper">
                    <span
                        className="address"
                        title={props.order.pickup_address}
                    >
                        {props.order.pickup_driver_note && (
                            <IconOLD
                                type="comment"
                                style={{
                                    marginRight: "5px",
                                }}
                            />
                        )}
                        {props.order.pickup_address}
                    </span>
                    {orderStatus !== "fulfilled" && (
                        <ButtonOLD
                            label={t("orderHistory.track")}
                            color="secondary"
                            onClick={(e) => {
                                e.stopPropagation();
                                trackClickHandler(props.order, "pickup");
                            }}
                            short
                            isLoading={isNavigating}
                        />
                    )}
                </div>
            </td>
            <td className="text-xs">
                <div className="location-wrapper">
                    <span
                        className="address"
                        title={props.order.dropoff_address}
                    >
                        {props.order.pickup_driver_note && (
                            <IconOLD
                                type="comment"
                                style={{
                                    marginRight: "5px",
                                }}
                            />
                        )}
                        {props.order.dropoff_address}
                    </span>

                    {orderStatus !== "fulfilled" && (
                        <ButtonOLD
                            label={t("orderHistory.track")}
                            color="secondary"
                            onClick={(e) => {
                                e.stopPropagation();
                                trackClickHandler(props.order, "dropoff");
                            }}
                            short
                            isLoading={isNavigating}
                        />
                    )}
                </div>
            </td>
            {orderStatus === "fulfilled" && props.order.fulfilled_at && (
                <td>
                    <span>{formatDateString(props.order.fulfilled_at)}</span>
                </td>
            )}
        </tr>
    );
}

export default OrderRow;
