import { useQuery } from "@tanstack/react-query";
import { getStopDrafts } from "../../api/stop-draft";

function useStopDrafts({ date, time }: { date?: string; time?: string } = {}) {
    const {
        data: stopDrafts,
        status,
        refetch,
        isFetching,
    } = useQuery({
        queryKey: ["stop-drafts", date, time] as const,
        queryFn: async ({ queryKey: [, date, time] }) => {
            const res = await getStopDrafts({
                date,
                time,
            });

            return res.data.stop_drafts;
        },
    });

    switch (status) {
        case "success":
            return {
                stopDrafts,
                status,
                refetch,
                isFetching,
            };
        case "pending":
            return {
                stopDrafts,
                status,
                refetch,
                isFetching,
            };
        case "error":
            return {
                stopDrafts,
                status,
                refetch,
                isFetching,
            };
    }
}

export default useStopDrafts;
