import { useTranslation } from "react-i18next";
import Select, {
    GroupBase,
    OptionProps,
    SingleValueProps,
    components,
} from "react-select";
import gbFlag from "../../../assets/flags/gb.svg";
import svFlag from "../../../assets/flags/se.svg";
import "./style.scss";

type Props = {
    width?: string;
};

function LanguageSelector(props: Props) {
    const { i18n } = useTranslation();

    const languageOptions = [
        { value: "sv", label: "Svenska", icon: svFlag },
        { value: "en", label: "English", icon: gbFlag },
    ];

    const CustomDropdownOption = (
        props: OptionProps<
            { value: string; label: string; icon: string },
            false,
            GroupBase<{ value: string; label: string; icon: string }>
        >
    ) => (
        <components.Option {...props}>
            <img
                className="language-flag"
                src={props.data.icon}
                alt={props.data.label}
            />
            <span>{props.data.label}</span>
        </components.Option>
    );

    const CustomSingleValue = (
        props: SingleValueProps<
            {
                value: string;
                label: string;
                icon: string;
            },
            false,
            GroupBase<{
                value: string;
                label: string;
                icon: string;
            }>
        >
    ) => (
        <components.SingleValue {...props}>
            <img
                className="language-flag"
                src={props.data.icon}
                alt={props.data.label}
            />
            <span>{props.data.label}</span>
        </components.SingleValue>
    );

    return (
        <Select
            options={languageOptions}
            value={languageOptions.find((lo) => lo.value === i18n.language)}
            onChange={(o) => i18n.changeLanguage(o?.value)}
            components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
                Option: CustomDropdownOption,
                SingleValue: CustomSingleValue,
            }}
            isSearchable={false}
            styles={{
                container: (base) => ({
                    ...base,
                    width: props.width || "100%",
                }),
                valueContainer: (base) => ({
                    ...base,
                    padding: "0",
                }),
                menu: (base) => ({
                    ...base,
                    zIndex: 100000,
                }),
                placeholder: (base) => ({
                    ...base,
                    color: "var(--text-color-light)",
                    fontWeight: 300,
                }),
                control: (base, state) => ({
                    ...base,
                    width: "100%",
                    color: "var(--text-color)",
                    borderRadius: "40px",
                    backgroundColor: "transparent",
                    border: "transparent",
                    minHeight: "40px",
                    cursor: "pointer",
                    boxShadow: "none",
                }),
                option: (base, state) => ({
                    ...base,
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    backgroundColor: state.isFocused
                        ? "var(--highlight-color)"
                        : "transparent",
                    color: "var(--text-color)",
                    cursor: "pointer",
                    ":active": {
                        backgroundColor: "var(--highlight-color)",
                    },
                }),
                singleValue: (base) => ({
                    ...base,
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    color: "var(--text-color)",
                    marginLeft: "0",
                }),
            }}
        />
    );
}

export default LanguageSelector;
