import { CSSProperties, RefObject, useRef } from "react";
import { useClickOutside } from "../../hooks/functionality/useClickOutside";
import "./style.scss";

type Props = {
    buttonElement: (ref: RefObject<HTMLButtonElement>) => React.ReactNode;
    children: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
    width?: string;
    fixedMenu?: boolean;
    modalClass?: string;
    align: "left" | "right";
    topAlign?: boolean;
    style?: CSSProperties;
};

function Modal(props: Props) {
    const modalClasses = ["modal"];
    if (props.fixedMenu) {
        modalClasses.push("fixed-menu");
    }
    if (props.modalClass) {
        modalClasses.push(props.modalClass);
    }

    const modalRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    useClickOutside([modalRef, buttonRef], () => {
        if (!props.isOpen) return;
        props.onClose();
    });

    return (
        <div className="modal-wrapper" style={props.style}>
            {props.buttonElement(buttonRef)}
            {props.isOpen && (
                <div
                    className={modalClasses.join(" ")}
                    ref={modalRef}
                    style={{
                        width: props.width,
                        left: props.align === "left" ? "0" : "auto",
                        right: props.align === "right" ? "0" : "auto",
                        top: !props.topAlign ? "calc(100% + 5px)" : "auto",
                        bottom: props.topAlign ? "calc(100% + 5px)" : "auto",
                    }}
                >
                    {props.children}
                </div>
            )}
        </div>
    );
}

export default Modal;
