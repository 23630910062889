import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import fleetPlannerReducer from "./slices/fleetPlanner";

const rootReducer = combineReducers({
    auth: authReducer,
    fleetPlanner: fleetPlannerReducer,
});

const store = configureStore({
    reducer: rootReducer,
    devTools: true,
});

export default store;
