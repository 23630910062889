import Map from "../Map";
import { useSelector } from "react-redux";
import { StopDraft } from "../../shared/types/api";
import { ReduxState } from "../../shared/types/redux";
import { DEFAULT_CENTER, STOP_DRAFT_TYPE } from "../../shared/values/enums";
import TourMarkersOLD from "../markers/TourMarkersOLD";
import "./style.scss";

type Props = {
    stops: StopDraft[];
    mapId: string;
};

function TourMap(props: Props) {
    const { user } = useSelector((state: ReduxState) => state.auth);

    return (
        <div className="tour-map">
            <Map
                mapId={props.mapId}
                defaultCenter={
                    user?.location_entity?.lat && user?.location_entity?.lng
                        ? {
                              lat: user.location_entity.lat,
                              lng: user.location_entity.lng,
                          }
                        : DEFAULT_CENTER
                }
                defaultZoom={12}
                showTrafficToggle
            >
                <TourMarkersOLD
                    points={props.stops
                        .filter((sd) => sd.lat && sd.lng)
                        .map((sd, i) => ({
                            address: sd.to_location,
                            label: (i + 1).toString(),
                            location: {
                                lat: +sd.lat!,
                                lng: +sd.lng!,
                            },
                            icon:
                                sd.stop_type_id === STOP_DRAFT_TYPE.Pickup
                                    ? "plane-departure"
                                    : "race-flag",
                        }))}
                />
            </Map>
        </div>
    );
}

export default TourMap;
