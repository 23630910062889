import { useQuery } from "@tanstack/react-query";
import { getCalendar } from "../../api/tours";

function useCalendar({
    startDate,
    endDate,
}: {
    startDate: string;
    endDate: string;
}) {
    const {
        data: calendar,
        status,
        refetch,
        isFetching,
    } = useQuery({
        queryKey: ["calendar", startDate, endDate],
        queryFn: async ({ queryKey: [_, startDate, endDate] }) => {
            const res = await getCalendar({ startDate, endDate });
            return res.data;
        },
    });

    switch (status) {
        case "success":
            return {
                calendar,
                status,
                refetch,
                isFetching,
            };
        case "pending":
            return {
                calendar,
                status,
                refetch,
                isFetching,
            };
        case "error":
            return {
                calendar,
                status,
                refetch,
                isFetching,
            };
    }
}

export default useCalendar;
