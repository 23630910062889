import axios from "../shared/services/axios";
import { Favourite, FavouriteType } from "../shared/types/api";

export function createFavourite({
    name,
    description,
    address,
    type,
}: {
    name: string;
    description: string;
    address: string;
    type: FavouriteType;
}) {
    return axios.post<{ id: number }>("/favourite", {
        name,
        description,
        address,
        type,
    });
}

export function updateFavourite({
    id,
    name,
    description,
    address,
    type,
}: {
    id: number;
    name: string;
    description: string;
    address: string;
    type: FavouriteType;
}) {
    return axios.put<{ id: number }>("/favourites", {
        id,
        name,
        description,
        address,
        type,
    });
}

export function deleteFavourite(id: number) {
    return axios.delete<{ id: number }>(`/favourites/${id}`);
}

export function getFavourites() {
    return axios.get<Favourite[]>("/favourites");
}
