import { phonePlusRegex, phoneRegex } from "./regex";

export function formatPhoneNumber(phoneNumber: string) {
    return phoneNumber.replaceAll(" ", "").replaceAll("-", "");
}

export function isValidPhoneNumber(phoneNumber: string) {
    return phoneRegex.test(formatPhoneNumber(phoneNumber));
}

export function isValidPlusPhoneNumber(phoneNumber: string) {
    return phonePlusRegex.test(formatPhoneNumber(phoneNumber));
}
