import "./style.scss";

type Props = {
    className: string;
    content: string;
    position?: {
        top?: string;
        bottom?: string;
        left?: string;
        right?: string;
    };
};

function TooltipModal(props: Props) {
    const tooltipClasses = ["tooltip-modal", "text-xs"];
    tooltipClasses.push(props.className);

    const defaultPosition = {
        bottom: "calc( 100% + 10px )",
        left: "-10px",
    };

    return (
        <div
            className={tooltipClasses.join(" ")}
            style={props.position ? props.position : defaultPosition}
        >
            {props.content}
        </div>
    );
}

export default TooltipModal;
