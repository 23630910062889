import { useTranslation } from "react-i18next";
import { Order } from "../../shared/types/api";
import { OrderMode } from "../../shared/types/internal";
import OrderRow from "./OrderRow";
import "./style.scss";

type Props = {
    orders: Order[];
    onOrderClick: (order: Order) => void;
    mode: OrderMode;
};

function OrdersTable(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="orders-table">
            <table>
                <thead>
                    <tr>
                        <th className="text-xs status">
                            {t("orderHistory.status")}
                        </th>
                        <th className="text-xs order-number">
                            {t("orderHistory.orderNumber")}
                        </th>
                        <th className="text-xs created">
                            {t("orderHistory.created")}
                        </th>
                        <th className="text-xs address">
                            {props.mode === "active"
                                ? t("orderHistory.pickUp")
                                : t("orderHistory.pickedUp")}
                        </th>
                        <th className="text-xs address">
                            {props.mode === "active"
                                ? t("orderHistory.dropOff")
                                : t("orderHistory.droppedOff")}
                        </th>
                        {props.mode === "fulfilled" && (
                            <th className="text-xs fulfilled-time">
                                {t("orderHistory.fulfilledTime")}
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {props.orders.map((o) => (
                        <OrderRow
                            key={o.id}
                            order={o}
                            onClick={() => props.onOrderClick(o)}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default OrdersTable;
