import { useQuery } from "@tanstack/react-query";
import { getOrdersEta } from "../../api/order";

function useOrdersEta(orderIds: number[]) {
    const {
        data: etas,
        status,
        refetch,
        isFetching,
    } = useQuery({
        queryKey: ["orders-eta", orderIds] as const,
        queryFn: async ({ queryKey: [, orderIds] }) => {
            const res = await getOrdersEta(orderIds);
            return res.data;
        },
        retry: false,
    });

    switch (status) {
        case "success":
            return {
                etas,
                status,
                refetch,
                isFetching,
            };
        case "pending":
            return {
                etas,
                status,
                refetch,
                isFetching,
            };
        case "error":
            return {
                etas,
                status,
                refetch,
                isFetching,
            };
    }
}

export default useOrdersEta;
