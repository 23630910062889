import { useTranslation } from "react-i18next";
import { NEW_ORDER_SECTION_ID } from "../../shared/values/enums";
import "./style.scss";

const guideTexts = [
    {
        id: NEW_ORDER_SECTION_ID.Address,
        texts: [
            {
                title: "createOrder.address.title",
                text: "createOrder.address.text",
            },
        ],
    },
    {
        id: NEW_ORDER_SECTION_ID.Weight,
        texts: [
            {
                title: "createOrder.weight.title",
                text: "createOrder.weight.text",
            },
            {
                title: "createOrder.heaviestPackage.title",
                text: "createOrder.heaviestPackage.text",
            },
            {
                title: "createOrder.euPallets.title",
                text: "createOrder.euPallets.text",
            },
        ],
    },
    {
        id: NEW_ORDER_SECTION_ID.Vehicle,
        texts: [
            {
                title: "createOrder.vehicle.title",
                text: "createOrder.vehicle.text",
            },
            {
                title: "createOrder.delivery.title",
                text: "createOrder.delivery.text",
            },
        ],
    },
    {
        id: NEW_ORDER_SECTION_ID.Contact,
        texts: [
            {
                title: "createOrder.contact.title",
                text: "createOrder.contact.text",
            },
        ],
    },
    {
        id: NEW_ORDER_SECTION_ID.CargoContent,
        texts: [
            {
                title: "createOrder.cargoContent.title",
                text: "createOrder.cargoContent.text",
            },
        ],
    },
];

type Props = {};

function NewOrderGuide(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="new-order-guide">
            {guideTexts.map((section) => (
                <section key={section.id}>
                    {section.texts.map((guide, i) => (
                        <div key={i} className="guide">
                            <p className="title">{t(guide.title)}</p>
                            <p>{t(guide.text)}</p>
                        </div>
                    ))}
                </section>
            ))}
        </div>
    );
}

export default NewOrderGuide;
