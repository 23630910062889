import { useEffect } from "react";
import { useParams } from "react-router-dom";
import analytics from "../../shared/services/ga4";
import environment from "../../environment";
import { useSearchQuery } from "../../hooks/functionality/useSearchQuery";
import { QUERY_PARAM } from "../../shared/values/enums";

type Props = {
    shouldUseQueryParams?: boolean;
};

function TrackingRedirect(props: Props) {
    const { trackingCode } = useParams<{ trackingCode: string }>();
    const query = useSearchQuery();

    useEffect(() => {
        if (!trackingCode) return;
        if (props.shouldUseQueryParams) return;

        analytics.smsTrackingLinkUsed();
        window.location.replace(
            `${environment.motionToolsTrackingUrl}${trackingCode}`
        );
    }, [props.shouldUseQueryParams, trackingCode]);

    useEffect(() => {
        if (!props.shouldUseQueryParams) return;
        if (!query) return;

        const trackingCode = query.get(QUERY_PARAM.TrackingCode);

        if (!trackingCode) return;

        analytics.smsTrackingLinkUsed();
        window.location.replace(
            `${environment.motionToolsTrackingUrl}${trackingCode}`
        );
    }, [props.shouldUseQueryParams, query]);

    return null;
}

export default TrackingRedirect;
