import { useQuery } from "@tanstack/react-query";
import { getStats } from "../../api/tours";

function useLocationStats({
    startDate,
    endDate,
}: {
    startDate: string;
    endDate: string;
}) {
    const {
        data: locationStats,
        status,
        refetch,
        isFetching,
    } = useQuery({
        queryKey: ["location-stats", startDate, endDate],
        queryFn: async ({ queryKey: [, startDate, endDate] }) => {
            const res = await getStats({
                startDate,
                endDate,
            });

            return res.data;
        },
    });

    switch (status) {
        case "success":
            return {
                locationStats,
                status,
                refetch,
                isFetching,
            };
        case "pending":
            return {
                locationStats,
                status,
                refetch,
                isFetching,
            };
        case "error":
            return {
                locationStats,
                status,
                refetch,
                isFetching,
            };
    }
}

export default useLocationStats;
