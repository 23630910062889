import { useQuery } from "@tanstack/react-query";
import { getOrderDrafts } from "../../api/stop-draft";
import { OrderDraft } from "../../shared/types/api";

function useOrderDrafts() {
    const {
        data: orderDrafts,
        status,
        refetch,
        isFetching,
    } = useQuery({
        queryKey: ["order-drafts"],
        queryFn: async () => {
            try {
                const res = await getOrderDrafts();
                return res.data;
            } catch (error) {
                return [] as OrderDraft[];
            }
        },
    });

    switch (status) {
        case "success":
            return {
                orderDrafts,
                status,
                refetch,
                isFetching,
            };
        case "pending":
            return {
                orderDrafts,
                status,
                refetch,
                isFetching,
            };
        case "error":
            return {
                orderDrafts,
                status,
                refetch,
                isFetching,
            };
    }
}

export default useOrderDrafts;
