import { z } from "zod";
import { isValidPhoneNumber } from "../shared/utility/phone";

export const setupForm = z
    .object({
        email: z.string().email({
            message: "errorMessage.invalidEmail",
        }),
        password: z
            .string()
            .min(1, {
                message: "errorMessage.missingPassword",
            })
            .min(8, {
                message: "errorMessage.passwordTooShort",
            }),
        repeatPassword: z.string().min(1, {
            message: "errorMessage.missingRepeatPassword",
        }),
        firstName: z.string().min(1, {
            message: "errorMessage.missingFirstName",
        }),
        lastName: z.string().min(1, {
            message: "errorMessage.missingLastName",
        }),
        phone: z
            .string()
            .min(1, {
                message: "errorMessage.missingPhoneNumber",
            })
            .refine(
                (phone) => isValidPhoneNumber(phone),
                "errorMessage.invalidPhoneNumber"
            ),
        activationKey: z.string().min(1, {
            message: "errorMessage.missingAuthCode",
        }),
    })
    .refine(
        (data) => {
            const { password, repeatPassword } = data;

            return password === repeatPassword;
        },
        {
            message: "errorMessage.passwordsNotMatch",
        }
    );

export const loginForm = z.object({
    email: z.string().email({
        message: "errorMessage.invalidEmail",
    }),
    password: z.string().min(1, {
        message: "errorMessage.missingPassword",
    }),
});

export const authCodeForm = z.object({
    activationKey: z.string().min(1, {
        message: "errorMessage.missingAuthCode",
    }),
});

export const registerForm = z.object({
    email: z.string().email({
        message: "errorMessage.invalidEmail",
    }),
});

export const resetPasswordForm = z.object({
    newPassword: z.string().min(8, {
        message: "errorMessage.passwordTooShort",
    }),
    resetToken: z.string().min(1, {
        message: "errorMessage.missingResetToken",
    }),
});

export const changePasswordForm = z.object({
    newPassword: z.string().min(8, {
        message: "errorMessage.passwordTooShort",
    }),
    oldPassword: z.string().min(1, {
        message: "errorMessage.missingOldPassword",
    }),
});

export const inviteColleagueForm = z.object({
    email: z.string().email({
        message: "errorMessage.invalidEmail",
    }),
});

export type SetupForm = z.infer<typeof setupForm>;

export type LoginForm = z.infer<typeof loginForm>;

export type AuthCodeForm = z.infer<typeof authCodeForm>;

export type RegisterForm = z.infer<typeof registerForm>;

export type ResetPasswordForm = z.infer<typeof resetPasswordForm>;

export type ChangePasswordForm = z.infer<typeof changePasswordForm>;

export type InviteColleagueForm = z.infer<typeof inviteColleagueForm>;
