import { Variants, motion } from "framer-motion";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "../../buttons/IconButton";
import "./style.scss";

type Props = {
    pages: ReactNode[];
};

function Carousel(props: Props) {
    const { t } = useTranslation();

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((state) =>
                state === props.pages.length - 1 ? 0 : state + 1
            );
        }, 10000);

        return () => clearInterval(interval);
    }, [props.pages.length]);

    const pageVariants: Variants = {
        initial: {
            x: "100%",
            opacity: 0,
            transition: {
                duration: 0.3,
            },
        },
        animate: {
            x: 0,
            opacity: 1,
            transition: {
                duration: 0.3,
            },
        },
    };

    return (
        <div className="carousel">
            <div className="top">
                <span>{t("popup.orderCreated.question")}</span>
                <a href="tel:010-880 07 84">010-880 07 84</a>
                <a href="mailto:order@alrik.com">order@alrik.com</a>
            </div>
            <motion.div
                key={currentIndex}
                className="content"
                variants={pageVariants}
                initial="initial"
                animate="animate"
            >
                {props.pages[currentIndex]}
            </motion.div>
            <div className="bottom">
                <div className="dots">
                    {props.pages.map((_, index) => (
                        <button
                            key={index}
                            onClick={() => setCurrentIndex(index)}
                            type="button"
                        >
                            <div
                                className={
                                    index === currentIndex ? "active" : ""
                                }
                            />
                        </button>
                    ))}
                </div>
                {currentIndex < props.pages.length - 1 && (
                    <IconButton
                        icon="chevron-right"
                        onClick={() => setCurrentIndex((state) => state + 1)}
                        style={{
                            backgroundColor: "#FFFFFF33",
                            color: "var(--text-color-lighter)",
                            border: "1px solid var(--color-pure-white)",
                        }}
                    />
                )}
            </div>
        </div>
    );
}

export default Carousel;
