import {
    AdvancedMarker,
    InfoWindow,
    useAdvancedMarkerRef,
    useMap,
    useMapsLibrary,
} from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";
import IconOLD from "../../../components/UI/IconOLD";
import { Driver } from "../../../shared/types/api";
import { getDriverDisplayName } from "../../../shared/utility/misc";
import "./style.scss";

type Props = {
    driver: Driver;
};

function DriverMarker(props: Props) {
    const map = useMap();
    const maps = useMapsLibrary("maps");
    const [markerRef, marker] = useAdvancedMarkerRef();

    const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);

    useEffect(() => {
        if (!map || !maps) return;
        if (
            !props.driver.current_lat ||
            !props.driver.current_lng ||
            !props.driver.next_lat ||
            !props.driver.next_lng
        )
            return;
        const polyline = new maps.Polyline({
            path: [
                {
                    lat: +props.driver.current_lat,
                    lng: +props.driver.current_lng,
                },
                {
                    lat: +props.driver.next_lat,
                    lng: +props.driver.next_lng,
                },
            ],
            map,
        });

        return () => polyline.setMap(null);
    }, [
        map,
        maps,
        props.driver.current_lat,
        props.driver.current_lng,
        props.driver.next_lat,
        props.driver.next_lng,
    ]);

    if (!props.driver.current_lat || !props.driver.current_lng) return null;
    return (
        <>
            <AdvancedMarker
                key={props.driver.id}
                ref={markerRef}
                position={{
                    lat: +props.driver.current_lat,
                    lng: +props.driver.current_lng,
                }}
                onClick={() => setIsInfoWindowOpen(!isInfoWindowOpen)}
            >
                {isInfoWindowOpen && (
                    <InfoWindow
                        anchor={marker}
                        onCloseClick={() => setIsInfoWindowOpen(false)}
                    >
                        <p className="text-sm">
                            {getDriverDisplayName(props.driver)}
                        </p>
                    </InfoWindow>
                )}
                <div className="driver-marker">
                    <IconOLD fontSize="18px" type="truck" />
                </div>
            </AdvancedMarker>
            {props.driver.next_lat && props.driver.next_lng && (
                <AdvancedMarker
                    key={props.driver.id + "-next"}
                    position={{
                        lat: +props.driver.next_lat,
                        lng: +props.driver.next_lng,
                    }}
                >
                    <div className="driver-marker">
                        <IconOLD fontSize="18px" type={"race-flag"} />
                    </div>
                </AdvancedMarker>
            )}
        </>
    );
}

export default DriverMarker;
