import {
    faFileCirclePlus,
    faGrid2,
    faRefresh,
    faSearch,
    faSquare,
    faStarHalf,
    faUserPlus,
    faWarning,
} from "@fortawesome/pro-regular-svg-icons";
import { faCircleSmall } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropResult, OnDragEndResponder } from "@hello-pangea/dnd";
import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { bulkDeleteStopDrafts } from "../../api/stop-draft";
import { removeStopFromTourDegrouping } from "../../api/tours";
import Button from "../../components/buttons/Button";
import Checkbox from "../../components/inputs/Checkbox";
import Input from "../../components/inputs/Input";
import WeekDaySelector from "../../components/inputs/WeekDaySelector";
import Badge from "../../components/UI/Badge";
import ProgressBar from "../../components/UI/ProgressBar";
import StopFilterModal from "../../containers/StopFilterModal";
import Table from "../../containers/Table";
import ToursDragDrop from "../../fleet-planner/ToursDragDrop";
import useFleetPlannerData from "../../hooks/data/useFleetPlannerData";
import useFleetPlannerDrag from "../../hooks/functionality/useFleetPlannerDrag";
import useStopAndTourFilterAndSort from "../../hooks/functionality/useStopAndTourFilterAndSort";
import { useFleetRoute } from "../../hooks/route/useFleetRoute";
import ToursMap from "../../maps/ToursMap";
import AddDriverPopup from "../../popups/AddDriverPopup";
import ExternalStopsPopup from "../../popups/ExternalStopsPopup";
import GroupTourStopsPopup from "../../popups/GroupTourStopsPopup";
import NewOrderPopup from "../../popups/NewOrderPopup";
import StopChangeLocationPopup from "../../popups/StopChangeLocationPopup";
import StopFormPopup from "../../popups/StopFormPopup";
import StopsInfoPopup from "../../popups/StopsInfoPopup";
import { StopDraft } from "../../shared/types/api";
import { KeyString } from "../../shared/types/internal";
import { dateToString } from "../../shared/utility/date";
import {
    getCombinedStops,
    getStopLocationText,
} from "../../shared/utility/stop-draft";
import {
    getNextStop,
    getTourEndDate,
    getTourProgress,
} from "../../shared/utility/tour";
import { NEW_TOUR_COLUMN_ID, STOP_DRAFT_TYPE } from "../../shared/values/enums";
import { tourColors } from "../../shared/values/lists";
import "./style.scss";

type Props = {};

function BigVolume(props: Props) {
    const { t } = useTranslation();
    const { filterDate, setFilterDate } = useFleetRoute();

    const {
        tours,
        stopDrafts,
        reloadData,
        isFetchingStopDrafts,
        isFetchingTours,
    } = useFleetPlannerData({
        filterDate: filterDate ? dateToString(filterDate) : undefined,
    });

    const {
        onDragEndStopDraftInTour,
        columnIdsLoading,
        unhandledIdsLoading,
        createNewTour,
        sortTourPickupsFirst,
    } = useFleetPlannerDrag({
        filterDate: dateToString(filterDate),
        tours,
        stopDrafts,
        reloadData,
    });

    const {
        sortCombinedStops,
        sortTours,

        filterCombinedStops,
        filterTours,

        activeStopFilter,
        setActiveStopFilter,
        activeStopSort,
        setActiveStopSort,
        stopSearch,
        setStopSearch,

        tourSearch,
        setTourSearch,
        activeTourSort,
        setActiveTourSort,
    } = useStopAndTourFilterAndSort();

    const [selectedStops, setSelectedStops] = useState<StopDraft[]>([]);
    const [selectedTourIds, setSelectedTourIds] = useState<number[]>([]);

    const [sectionsSize, setSectionsSize] = useState<
        "quarter" | "half" | "full"
    >("quarter");

    //popups
    const [isStopPopupOpen, setIsStopPopupOpen] = useState(false);
    const [isDriverPopupOpen, setIsDriverPopupOpen] = useState(false);

    const [editStops, setEditStops] = useState<{
        pickup: StopDraft;
        dropoff: StopDraft;
    } | null>(null);
    const [stopPopupData, setStopPopupData] = useState<{
        pickup: StopDraft;
        dropoff: StopDraft;
    } | null>(null);
    const [changeLocationStops, setChangeLocationStops] = useState<{
        pickup: StopDraft;
        dropoff: StopDraft;
    } | null>(null);
    const [stopsForExternalMarking, setStopsForExternalMarking] = useState<{
        pickup: StopDraft;
        dropoff: StopDraft;
    } | null>(null);
    const [newOrderStops, setNewOrderStops] = useState<{
        pickup: StopDraft;
        dropoff: StopDraft;
    } | null>(null);
    const [groupTourId, setGroupTourId] = useState<number | null>(null);

    //loading
    const [detachingIds, setDetachingIds] = useState<number[]>([]);

    // START FILTER AND SORT
    const sortedTours = useMemo(() => {
        if (!tours) return [];

        return tours.filter(filterTours).sort(sortTours);
    }, [filterTours, sortTours, tours]);

    const combinedStops = useMemo(() => {
        if (!stopDrafts) return [];

        return getCombinedStops(stopDrafts, {
            noExternalStops: true,
            filterDate: filterDate || undefined,
            noMovedAcceptedStops: true,
        });
    }, [filterDate, stopDrafts]);

    const unhandledStops = useMemo(() => {
        return combinedStops
            .filter(filterCombinedStops)
            .sort(sortCombinedStops);
    }, [combinedStops, filterCombinedStops, sortCombinedStops]);

    const selectedTours = useMemo(
        () => tours?.filter((t) => selectedTourIds.includes(t.tour_id)) || [],
        [selectedTourIds, tours]
    );
    // END FILTER AND SORT

    const isAllUnhandledSelected = useMemo(
        () =>
            unhandledStops.length * 2 === selectedStops.length &&
            selectedStops.length > 0,
        [unhandledStops, selectedStops]
    );

    const groupTour = useMemo(() => {
        if (!tours) return null;

        return tours.find((tour) => tour.tour_id === groupTourId) || null;
    }, [groupTourId, tours]);

    const onReorderNewTour = useCallback(
        (result: DropResult) => {
            if (!result.destination) return;
            const { source, destination } = result;

            setSelectedStops((state) => {
                const newSelectedStops = [...state];

                const [removed] = newSelectedStops.splice(source.index, 1);
                newSelectedStops.splice(destination.index, 0, removed);

                const groupedStopIndex = newSelectedStops.findIndex(
                    (sd) =>
                        sd.group_id === removed?.group_id &&
                        sd.id !== removed.id
                );

                if (
                    removed.stop_type_id === STOP_DRAFT_TYPE.Pickup &&
                    destination.index > groupedStopIndex
                ) {
                    toast.error(t("errorMessage.invalidMovePickup"));
                    return state;
                } else if (
                    removed.stop_type_id === STOP_DRAFT_TYPE.Dropoff &&
                    destination.index < groupedStopIndex
                ) {
                    toast.error(t("errorMessage.invalidMoveDropoff"));
                    return state;
                }

                return newSelectedStops;
            });
        },
        [t]
    );

    const onDragEnd = useCallback<OnDragEndResponder>(
        async (result, provided) => {
            if (!result.destination) return;
            const { source, destination, draggableId } = result;

            //return if the card is dropped in the same place it was picked up
            if (
                destination.droppableId === source.droppableId &&
                destination.index === source.index
            )
                return;

            if (
                source.droppableId === NEW_TOUR_COLUMN_ID &&
                destination.droppableId === NEW_TOUR_COLUMN_ID
            ) {
                onReorderNewTour(result);
                return;
            }

            await onDragEndStopDraftInTour({
                draggableId,
                source,
                destination,
                isFromUnhandledColumn:
                    source.droppableId === NEW_TOUR_COLUMN_ID,
                isDroppedInEmptyColumn: false,
                createdFrom: "big-volume",
            });
        },
        [onDragEndStopDraftInTour, onReorderNewTour]
    );

    const onAlrikOrderCreated = useCallback(async () => {
        toast.success(t("successMessage.orderCreated"));

        try {
            if (
                newOrderStops &&
                newOrderStops.pickup &&
                newOrderStops.dropoff
            ) {
                await bulkDeleteStopDrafts([
                    newOrderStops.pickup.id,
                    newOrderStops.dropoff.id,
                ]);
            }

            await reloadData("fleet-planner-orders", "stop-drafts");
        } catch (error) {
            toast.error(t("errorMessage.stopDraftDeletedOrderCreatedError"));
        } finally {
            setNewOrderStops(null);
        }
    }, [newOrderStops, reloadData, t]);

    const detachStopHandler = useCallback(
        async (stop: StopDraft) => {
            const tour = tours?.find((t) => t.tour_id === stop.tour_id);

            if (!tour) return;

            setDetachingIds((state) => [...state, stop.id]);

            try {
                await removeStopFromTourDegrouping(stop, tour);

                toast.success(t("activeFleet.stopDetached"));

                await reloadData("tours", "stop-drafts");
            } catch (error) {
                setDetachingIds((state) =>
                    state.filter((id) => id !== stop.id)
                );
            }
        },
        [reloadData, t, tours]
    );

    const tourColorMap = useMemo(() => {
        const newMap: KeyString<string> = {};
        newMap[NEW_TOUR_COLUMN_ID] = tourColors[tourColors.length - 1];
        if (!tours) return newMap;

        for (let i = 0; i < tours.length; i++) {
            const tour = tours[i];

            newMap[tour.tour_id] = tourColors[i % tourColors.length];
        }

        return newMap;
    }, [tours]);

    useEffect(() => {
        if (!tours) return;

        setSelectedTourIds((state) =>
            state.filter((id) => tours.some((t) => t.tour_id === id))
        );
    }, [tours]);

    useEffect(() => {
        if (!stopDrafts) return;

        setSelectedStops((state) =>
            state.filter(({ id }) => stopDrafts.some((s) => s.id === id))
        );
    }, [stopDrafts]);

    return (
        <div className="big-volume">
            <div className="controls">
                <WeekDaySelector value={filterDate} onChange={setFilterDate} />

                <div className="buttons">
                    <Button
                        variant="secondary"
                        label={t("activeFleet.updateAllData")}
                        leadingIcon={faRefresh}
                        onClick={() => reloadData("tours", "stop-drafts")}
                        isLoading={isFetchingStopDrafts || isFetchingTours}
                    />

                    <Button
                        variant={
                            sectionsSize === "quarter" ? "primary" : "secondary"
                        }
                        label={t("bigVolume.sectionSize.quarter")}
                        leadingIcon={faGrid2}
                        onClick={() => setSectionsSize("quarter")}
                    />

                    <Button
                        variant={
                            sectionsSize === "half" ? "primary" : "secondary"
                        }
                        label={t("bigVolume.sectionSize.half")}
                        leadingIcon={faStarHalf}
                        onClick={() => setSectionsSize("half")}
                    />

                    <Button
                        variant={
                            sectionsSize === "full" ? "primary" : "secondary"
                        }
                        label={t("bigVolume.sectionSize.full")}
                        leadingIcon={faSquare}
                        onClick={() => setSectionsSize("full")}
                    />
                </div>
            </div>
            <div className={["content", sectionsSize].join(" ")}>
                <section className="table-section">
                    <div className="controls">
                        <div style={{ width: "250px" }}>
                            <Input
                                placeholder={t(
                                    "bigVolume.searchStopPlaceholder"
                                )}
                                leadingIcon={faSearch}
                                type="text"
                                value={stopSearch}
                                onChange={setStopSearch}
                            />
                        </div>
                        <div className="button-group">
                            <Button
                                leadingIcon={faFileCirclePlus}
                                label={t("bigVolume.addStop")}
                                variant={"secondary"}
                                onClick={() => setIsStopPopupOpen(true)}
                            />
                            <StopFilterModal
                                stops={combinedStops.flatMap(
                                    ({ pickup, dropoff }) => [pickup, dropoff]
                                )}
                                activeFilter={activeStopFilter}
                                onFilterChange={(key, value) =>
                                    setActiveStopFilter((state) => ({
                                        ...state,
                                        [key]: value,
                                    }))
                                }
                            />
                        </div>
                    </div>
                    <Table
                        data={unhandledStops}
                        onRowClick={({ pickup, dropoff }) =>
                            setStopPopupData({ pickup, dropoff })
                        }
                        maxHeight={
                            sectionsSize === "quarter"
                                ? "var(--section-height)"
                                : "var(--section-height-full)"
                        }
                        columns={[
                            {
                                key: "id",
                                title: (
                                    <Checkbox
                                        disabled={unhandledStops.length >= 300}
                                        checked={isAllUnhandledSelected}
                                        onChange={(newChecked) =>
                                            setSelectedStops((state) =>
                                                newChecked
                                                    ? [
                                                          ...state,
                                                          ...unhandledStops.flatMap(
                                                              ({
                                                                  pickup,
                                                                  dropoff,
                                                              }) => [
                                                                  pickup,
                                                                  dropoff,
                                                              ]
                                                          ),
                                                      ]
                                                    : []
                                            )
                                        }
                                        style={{
                                            padding: "6px",
                                        }}
                                    />
                                ),
                                render: ({ pickup, dropoff }) => (
                                    <Checkbox
                                        checked={selectedStops.some(
                                            (s) => s.id === pickup.id
                                        )}
                                        onChange={(newChecked) =>
                                            setSelectedStops((state) =>
                                                newChecked
                                                    ? [
                                                          ...state,
                                                          pickup,
                                                          dropoff,
                                                      ]
                                                    : state.filter(
                                                          (stop) =>
                                                              stop.id !==
                                                                  pickup.id &&
                                                              stop.id !==
                                                                  dropoff.id
                                                      )
                                            )
                                        }
                                        style={{
                                            padding: "12px 6px",
                                        }}
                                    />
                                ),
                            },
                            {
                                key: "to_location",
                                title: t("bigVolume.loading"),
                                getValue: ({ pickup }) =>
                                    getStopLocationText(pickup),
                                getHoverTitle: ({ pickup }) =>
                                    pickup.to_location,
                                sortMode:
                                    activeStopSort === "pickup-asc"
                                        ? "asc"
                                        : activeStopSort === "pickup-desc"
                                        ? "desc"
                                        : null,
                                onSort: () =>
                                    setActiveStopSort((state) =>
                                        state === "pickup-asc"
                                            ? "pickup-desc"
                                            : "pickup-asc"
                                    ),
                            },
                            {
                                key: "to_location",
                                title: t("bigVolume.unloading"),
                                getValue: ({ dropoff }) =>
                                    getStopLocationText(dropoff),
                                getHoverTitle: ({ dropoff }) =>
                                    dropoff.to_location,
                                sortMode:
                                    activeStopSort === "dropoff-asc"
                                        ? "asc"
                                        : activeStopSort === "dropoff-desc"
                                        ? "desc"
                                        : null,
                                onSort: () =>
                                    setActiveStopSort((state) =>
                                        state === "dropoff-asc"
                                            ? "dropoff-desc"
                                            : "dropoff-asc"
                                    ),
                            },
                            {
                                key: "order_number",
                                title: t("bigVolume.orderNumber"),
                                getValue: ({ pickup }) => pickup.order_number,
                                sortMode:
                                    activeStopSort === "ordernumber-asc"
                                        ? "asc"
                                        : activeStopSort === "ordernumber-desc"
                                        ? "desc"
                                        : null,
                                onSort: () =>
                                    setActiveStopSort((state) =>
                                        state === "ordernumber-desc"
                                            ? "ordernumber-asc"
                                            : "ordernumber-desc"
                                    ),
                            },
                            {
                                key: "weight_kg",
                                title: t("bigVolume.weight"),
                                getValue: ({ pickup }) =>
                                    pickup.weight_kg
                                        ? `${pickup.weight_kg.toString()} kg`
                                        : "",
                                sortMode:
                                    activeStopSort === "weight-asc"
                                        ? "asc"
                                        : activeStopSort === "weight-desc"
                                        ? "desc"
                                        : null,
                                onSort: () =>
                                    setActiveStopSort((state) =>
                                        state === "weight-desc"
                                            ? "weight-asc"
                                            : "weight-desc"
                                    ),
                            },
                            {
                                key: "tag",
                                title: t("bigVolume.tag"),
                                render: ({ pickup }) =>
                                    pickup.tag ? (
                                        <Badge
                                            title={pickup.tag}
                                            variant="neutral"
                                        />
                                    ) : null,
                                sortMode:
                                    activeStopSort === "tag-asc"
                                        ? "asc"
                                        : activeStopSort === "tag-desc"
                                        ? "desc"
                                        : null,
                                onSort: () =>
                                    setActiveStopSort((state) =>
                                        state === "tag-desc"
                                            ? "tag-asc"
                                            : "tag-desc"
                                    ),
                            },
                        ]}
                    />
                    <div className="stop-table-counters">
                        <p className="text-2xs">{`${t(
                            "bigVolume.combinedStops"
                        )}: ${combinedStops.length}`}</p>
                        <FontAwesomeIcon
                            icon={faCircleSmall}
                            color="var(--color-neutral-400)"
                            size="xs"
                            fixedWidth
                        />
                        <p className="text-2xs">{`${t(
                            "bigVolume.unhandledStops"
                        )}: ${unhandledStops.length}`}</p>
                    </div>
                </section>

                <section>
                    <ToursMap
                        tours={selectedTours}
                        stops={selectedStops}
                        tourColorMap={tourColorMap}
                    />
                </section>

                <section className="table-section">
                    <div className="controls">
                        <div style={{ width: "250px" }}>
                            <Input
                                placeholder={t(
                                    "bigVolume.searchTourPlaceholder"
                                )}
                                leadingIcon={faSearch}
                                type="text"
                                value={tourSearch}
                                onChange={setTourSearch}
                            />
                        </div>
                        <div className="button-group">
                            <Button
                                leadingIcon={faUserPlus}
                                label={t("bigVolume.addDriver")}
                                variant={"secondary"}
                                onClick={() => setIsDriverPopupOpen(true)}
                            />
                        </div>
                    </div>
                    <Table
                        data={sortedTours}
                        renderDotsModalContent={(tour, close) => (
                            <Button
                                variant={"secondary"}
                                label={t("bigVolume.groupStops")}
                                onClick={() => {
                                    setGroupTourId(tour.tour_id);
                                    close();
                                }}
                                style={{ width: "150px" }}
                            />
                        )}
                        maxHeight={
                            sectionsSize === "quarter"
                                ? "var(--section-height)"
                                : "var(--section-height-full)"
                        }
                        columns={[
                            {
                                key: "tour_id",
                                title: "",
                                render: (tour) => (
                                    <Checkbox
                                        checked={selectedTourIds.includes(
                                            tour.tour_id
                                        )}
                                        onChange={(newChecked) =>
                                            setSelectedTourIds((state) =>
                                                newChecked
                                                    ? [...state, tour.tour_id]
                                                    : state.filter(
                                                          (id) =>
                                                              id !==
                                                              tour.tour_id
                                                      )
                                            )
                                        }
                                        style={{
                                            padding: "12px 6px",
                                        }}
                                    />
                                ),
                            },
                            {
                                key: "preferred_driver",
                                title: t("bigVolume.driver"),
                                width: "100px",
                                sortMode:
                                    activeTourSort === "driver-asc"
                                        ? "asc"
                                        : activeTourSort === "driver-desc"
                                        ? "desc"
                                        : null,
                                onSort: () =>
                                    setActiveTourSort((state) =>
                                        state === "driver-desc"
                                            ? "driver-asc"
                                            : "driver-desc"
                                    ),
                            },
                            {
                                key: "status",
                                title: t("bigVolume.tourStatus"),
                                render: (tour) => (
                                    <ProgressBar
                                        progress={
                                            getTourProgress(tour).progress
                                        }
                                        minWidth="70px"
                                    />
                                ),
                                sortMode:
                                    activeTourSort === "progress-asc"
                                        ? "asc"
                                        : activeTourSort === "progress-desc"
                                        ? "desc"
                                        : null,
                                onSort: () =>
                                    setActiveTourSort((state) =>
                                        state === "progress-desc"
                                            ? "progress-asc"
                                            : "progress-desc"
                                    ),
                            },
                            {
                                key: "end_time",
                                title: t("bigVolume.tourEndTime"),
                                getValue: (tour) =>
                                    dateToString(getTourEndDate(tour.stops), {
                                        onlyTime: true,
                                    }) || "",
                                sortMode:
                                    activeTourSort === "endtime-asc"
                                        ? "asc"
                                        : activeTourSort === "endtime-desc"
                                        ? "desc"
                                        : null,
                                onSort: () =>
                                    setActiveTourSort((state) =>
                                        state === "endtime-desc"
                                            ? "endtime-asc"
                                            : "endtime-desc"
                                    ),
                            },
                            {
                                key: "orders",
                                title: t("bigVolume.orders"),
                                getValue: (tour) => tour.stops.length / 2,
                                sortMode:
                                    activeTourSort === "orders-asc"
                                        ? "asc"
                                        : activeTourSort === "orders-desc"
                                        ? "desc"
                                        : null,
                                onSort: () =>
                                    setActiveTourSort((state) =>
                                        state === "orders-desc"
                                            ? "orders-asc"
                                            : "orders-desc"
                                    ),
                            },
                            {
                                key: "risk",
                                title: t("bigVolume.tourRisk"),
                                render: (item) =>
                                    item.preferred_driver.includes("a") ? (
                                        <FontAwesomeIcon
                                            icon={faWarning}
                                            size="lg"
                                            style={
                                                {
                                                    "--fa-primary-color":
                                                        "var(--color-neutral-900)",
                                                    "--fa-primary-opacity": "1",
                                                    "--fa-secondary-color":
                                                        "var(--color-yellow-400)",
                                                    "--fa-secondary-opacity":
                                                        "1",
                                                } as React.CSSProperties
                                            }
                                        />
                                    ) : null,
                            },
                            {
                                key: "next_stop",
                                title: t("bigVolume.nextStop"),
                                getValue: (tour) => {
                                    const nextStop = getNextStop(tour);
                                    if (!nextStop) return "";
                                    return getStopLocationText(nextStop);
                                },
                                sortMode:
                                    activeTourSort === "nextstop-asc"
                                        ? "asc"
                                        : activeTourSort === "nextstop-desc"
                                        ? "desc"
                                        : null,
                                onSort: () =>
                                    setActiveTourSort((state) =>
                                        state === "nextstop-desc"
                                            ? "nextstop-asc"
                                            : "nextstop-desc"
                                    ),
                            },
                        ]}
                    />
                </section>

                <section className="drag-drop-section">
                    <ToursDragDrop
                        tours={selectedTours}
                        stops={selectedStops}
                        filterDate={filterDate}
                        onDragEnd={onDragEnd}
                        onCreateNewTour={async (data) =>
                            await createNewTour({
                                ...data,
                                createdFrom: "big-volume",
                                autoDispatch: true,
                            })
                        }
                        onDispatchTour={async () => {}}
                        onUpdateTour={async () => {}}
                        columnIdsLoading={columnIdsLoading}
                        stopIdsLoading={[
                            ...unhandledIdsLoading,
                            ...detachingIds,
                        ]}
                        tourColorMap={tourColorMap}
                        onStopCardClick={setStopPopupData}
                        onClearStops={() => setSelectedStops([])}
                    />
                </section>
            </div>

            <AddDriverPopup
                showPopup={isDriverPopupOpen}
                onClose={() => setIsDriverPopupOpen(false)}
            />

            <StopFormPopup
                showPopup={isStopPopupOpen}
                onClose={() => setIsStopPopupOpen(false)}
                onCreated={() => {
                    setIsStopPopupOpen(false);
                    reloadData("stop-drafts", "tours");
                }}
                onEdited={() => {
                    setIsStopPopupOpen(false);
                    reloadData("stop-drafts", "tours");
                }}
                onDeleted={() => {
                    setIsStopPopupOpen(false);
                    reloadData("stop-drafts", "tours");
                }}
                editStops={editStops}
                orderDraft={null}
            />

            {stopPopupData && (
                <StopsInfoPopup
                    showPopup={!!stopPopupData}
                    onClose={() => setStopPopupData(null)}
                    stops={stopPopupData}
                    onEditStops={
                        stopPopupData.pickup.tour_id
                            ? undefined
                            : (stops) => {
                                  setEditStops(stops);
                                  setStopPopupData(null);
                                  setIsStopPopupOpen(true);
                              }
                    }
                    onDetachStops={
                        stopPopupData.pickup.tour_id
                            ? (stops) => {
                                  detachStopHandler(stops.pickup);
                                  setStopPopupData(null);
                              }
                            : undefined
                    }
                    onAssignOutsideFleet={
                        stopPopupData.pickup.tour_id
                            ? undefined
                            : (stops) => {
                                  setStopsForExternalMarking(stops);
                                  setStopPopupData(null);
                              }
                    }
                    onAssignAlrik={
                        stopPopupData.pickup.tour_id
                            ? undefined
                            : (stops) => {
                                  setNewOrderStops(stops);
                                  setStopPopupData(null);
                              }
                    }
                    onChangedLocation={
                        stopPopupData.pickup.tour_id
                            ? undefined
                            : (stops) => {
                                  setChangeLocationStops(stops);
                                  setStopPopupData(null);
                              }
                    }
                />
            )}

            {changeLocationStops && (
                <StopChangeLocationPopup
                    showPopup={!!changeLocationStops}
                    onClose={() => setChangeLocationStops(null)}
                    stopIds={[
                        changeLocationStops.pickup.id,
                        changeLocationStops.dropoff.id,
                    ]}
                    onLocationChange={() =>
                        reloadData("stop-drafts", "moved-stops")
                    }
                />
            )}

            {stopsForExternalMarking && (
                <ExternalStopsPopup
                    showPopup={!!stopsForExternalMarking}
                    onClose={() => setStopsForExternalMarking(null)}
                    onSubmit={() => reloadData("stop-drafts")}
                    {...stopsForExternalMarking}
                    preselectedDate={dateToString(filterDate)}
                />
            )}

            <NewOrderPopup
                showPopup={!!newOrderStops}
                onClose={() => setNewOrderStops(null)}
                stopDrafts={newOrderStops || undefined}
                onOrderCreated={onAlrikOrderCreated}
                isFromFleetPlanner
            />

            {!!groupTour && (
                <GroupTourStopsPopup
                    showPopup={!!groupTour}
                    onClose={() => setGroupTourId(null)}
                    droppableId={groupTour.tour_id.toString()}
                    onDragEnd={onDragEnd}
                    isColumnLoading={columnIdsLoading.includes(
                        groupTour.tour_id.toString()
                    )}
                    tour={groupTour}
                    onStopsGrouped={() => reloadData("tours")}
                    onSortClick={() =>
                        sortTourPickupsFirst({
                            tourId: groupTour.tour_id,
                            stops: groupTour.stops,
                        })
                    }
                />
            )}
        </div>
    );
}

export default BigVolume;
