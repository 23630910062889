import { useQuery } from "@tanstack/react-query";
import { getDrivers, getDriversForCompany } from "../../api/driver";
import { Driver } from "../../shared/types/api";

function useDrivers(options?: { driversFromCompany?: boolean }) {
    const {
        data: drivers,
        status,
        refetch,
        isFetching,
    } = useQuery({
        queryKey: ["drivers", options?.driversFromCompany] as const,
        queryFn: async ({ queryKey: [, driversFromCompany] }) => {
            try {
                const res = driversFromCompany
                    ? await getDriversForCompany()
                    : await getDrivers();
                return res.data;
            } catch (error) {
                return [] as Driver[];
            }
        },
    });

    switch (status) {
        case "success":
            return {
                drivers,
                status,
                refetch,
                isFetching,
            };
        case "pending":
            return {
                drivers,
                status,
                refetch,
                isFetching,
            };
        case "error":
            return {
                drivers,
                status,
                refetch,
                isFetching,
            };
    }
}

export default useDrivers;
