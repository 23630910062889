import { ContactForm } from "../schemas/form";
import axios from "../shared/services/axios";
import { Contact } from "../shared/types/api";

export function createContact(data: ContactForm) {
    return axios.post<Contact>("/contact/create", data);
}

export function updateContact(data: Contact) {
    return axios.put<Contact>("/contact", data);
}

export function deleteContact(id: number) {
    return axios.delete<string>(`/contact/${id}`);
}

export function getContacts() {
    return axios.get<Contact[]>("/contacts");
}
