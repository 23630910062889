import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useManualOrderTransportOptions from "../../../hooks/functionality/useManualOrderTransportOptions";
import { dateToString } from "../../../shared/utility/date";
import { CargoForm, DeliveryForm, TransportForm } from "../schemas";
import "./style.scss";

type Props = {
    data: DeliveryForm & CargoForm & TransportForm;
};

function ConfirmManualOrder(props: Props) {
    const { t } = useTranslation();

    const transportOptions = useManualOrderTransportOptions();

    const dataSections = useMemo<
        {
            label: string;
            value: string;
        }[][]
    >(() => {
        return [
            [
                {
                    label: t("fleetManualOrder.confirm.orderNumber"),
                    value: props.data.orderNumber,
                },
                {
                    label: t("fleetManualOrder.confirm.date"),
                    value: dateToString(props.data.date),
                },
            ],
            [
                {
                    label: t("fleetManualOrder.confirm.fromAddress"),
                    value: props.data.fromAddress,
                },
                {
                    label: "​",
                    value: props.data.toAddress,
                },
            ],
            [
                {
                    label: t("fleetManualOrder.confirm.contact"),
                    value: props.data.contactId?.toString() || "",
                },
                {
                    label: t("fleetManualOrder.confirm.dropoffPhone"),
                    value: props.data.dropoffPhone,
                },
            ],
            [
                {
                    label: t("fleetManualOrder.confirm.weight"),
                    value: props.data.weight
                        ? props.data.weight.toString() + " kg"
                        : "",
                },
                {
                    label: t(
                        "fleetManualOrder.confirm.weightOfHeaviestPackage"
                    ),
                    value:
                        props.data.weightOfHeaviestPackage.toString() + " kg",
                },
                {
                    label: t("fleetManualOrder.confirm.numberOfEuPallets"),
                    value: props.data.numberOfEuPallets.toString(),
                },
            ],
            [
                {
                    label: t("fleetManualOrder.confirm.driverInstructions"),
                    value: props.data.driverInstructions,
                },
            ],
            [
                {
                    label: t("fleetManualOrder.confirm.cargoContent"),
                    value: props.data.cargoContent,
                },
            ],
        ];
    }, [
        t,
        props.data.orderNumber,
        props.data.date,
        props.data.fromAddress,
        props.data.toAddress,
        props.data.contactId,
        props.data.dropoffPhone,
        props.data.weight,
        props.data.weightOfHeaviestPackage,
        props.data.numberOfEuPallets,
        props.data.driverInstructions,
        props.data.cargoContent,
    ]);

    const transportOption = useMemo(
        () =>
            transportOptions.find(
                (option) => option.value === props.data.transportOption
            ) || null,
        [props.data.transportOption, transportOptions]
    );

    const transportSections = useMemo<
        {
            label: string;
            value: string;
        }[][]
    >(() => {
        return [
            [
                {
                    label: t("fleetManualOrder.confirm.transportType"),
                    value: transportOption?.label || "-",
                },
            ],
        ];
    }, [t, transportOption?.label]);

    return (
        <div className="fleet-manual-order-form confirm-manual-order">
            <p className="text-sm">{t("fleetManualOrder.confirm.title")}</p>
            {dataSections.map((section, i) => (
                <section key={i}>
                    {section.map((item) => (
                        <div key={item.label}>
                            <p
                                className="text-xs"
                                style={{
                                    color: "var(--text-color-light)",
                                }}
                            >
                                {item.label || "-"}
                            </p>
                            <p>{item.value || "-"}</p>
                        </div>
                    ))}
                </section>
            ))}
            <hr />
            <p className="text-sm">
                {t("fleetManualOrder.confirm.transportTitle")}
            </p>
            {transportSections.map((section, i) => (
                <section key={i}>
                    {section.map((item) => (
                        <div key={item.label}>
                            <p
                                className="text-xs"
                                style={{
                                    color: "var(--text-color-light)",
                                }}
                            >
                                {item.label}
                            </p>
                            <p>{item.value || "-"}</p>
                        </div>
                    ))}
                </section>
            ))}
        </div>
    );
}

export default ConfirmManualOrder;
