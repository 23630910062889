import { CSSProperties, useState } from "react";
import Modal from "../../../hoc/Modal";
import { IconType } from "../../../shared/types/internal";
import DotsButton from "../../buttons/DotsButton";
import IconOLD from "../IconOLD";
import Spinner from "../Spinner";
import "./style.scss";

export type DotsModalContent = {
    title: string;
    onClick?: () => void;
    isLoading?: boolean;
    disabled?: boolean;
    icon?: IconType;
    iconColor?: string;
};

type Props = {
    title?: string;
    content: (DotsModalContent | null)[];
    fixedMenu?: boolean;
    dotsStyle?: React.CSSProperties;
    dotsColor?: string;
    isLoading?: boolean;
    dotsAmount?: number;
    large?: boolean;
    modalWidth?: string;
    style?: CSSProperties;
};

function DotsModal(props: Props) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const isModalLoading =
        props.content.some((c) => (c ? c.isLoading : false)) || props.isLoading;

    return (
        <Modal
            buttonElement={(ref) => (
                <DotsButton
                    ref={ref}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsModalOpen((state) => !state);
                    }}
                    isLoading={isModalLoading}
                    color={props.dotsColor}
                    style={props.dotsStyle}
                    amount={props.dotsAmount}
                    large={props.large}
                />
            )}
            onClose={() => setIsModalOpen(false)}
            isOpen={isModalOpen}
            width={props.modalWidth}
            modalClass="dots-modal"
            fixedMenu={props.fixedMenu}
            align="right"
            style={props.style}
        >
            {props.title && <p className="title text-sm">{props.title}</p>}
            {props.content.map((button) =>
                button ? (
                    <button
                        key={button.title}
                        className="text-sm"
                        type="button"
                        onClick={(e) => {
                            e.stopPropagation();
                            if (button.onClick) button.onClick();
                            setIsModalOpen(false);
                        }}
                        disabled={button.disabled}
                        style={{
                            opacity: button.disabled ? 0.5 : 1,
                        }}
                    >
                        {button.icon && (
                            <IconOLD
                                type={button.icon}
                                style={{ color: button.iconColor }}
                            />
                        )}
                        {button.isLoading ? <Spinner /> : button.title}
                    </button>
                ) : null
            )}
        </Modal>
    );
}

export default DotsModal;
