import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import {
    convertFileToOrderDraft,
    uploadFile,
} from "../../api/file-to-order-draft";
import { useTranslation } from "react-i18next";
import { useRef } from "react";

function useFileToOrderDraft({
    onUploadSuccess,
}: {
    onUploadSuccess?: () => void;
}) {
    const { t } = useTranslation();

    const loadingToastId = useRef<string | null>(null);

    const { mutate: uploadFileHandler, isPending: isUploadingFile } =
        useMutation({
            mutationFn: async (file: File) => {
                const res = await uploadFile(file);
                return res.data.file_name;
            },
            onSuccess: (filename: string) => {
                convertFile(filename);
            },
            onError: () => {
                toast.error(t("errorMessage.fileUploadedError"));
            },
        });

    const { mutate: convertFile } = useMutation({
        mutationFn: async (filename: string) => {
            loadingToastId.current = toast.loading(
                t("activeFleet.fileLoading")
            );
            await convertFileToOrderDraft(filename);
        },
        onSuccess: () => {
            toast.success(t("successMessage.fileProcessed"));
            if (onUploadSuccess) {
                onUploadSuccess();
            }
        },
        onError: () => {
            toast.error(t("errorMessage.fileConvertError"));
        },
        onSettled: () => {
            if (loadingToastId.current) toast.dismiss(loadingToastId.current);
        },
    });

    return { uploadFileHandler, isUploadingFile };
}

export default useFileToOrderDraft;
