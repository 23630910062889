import { useOutletContext } from "react-router-dom";
import { Order } from "../../shared/types/api";

type ContextType = {
    selectedOrder: Order | null;
    setSelectedOrder: (order: Order | null) => void;
};

export function useOrdersRoute() {
    return useOutletContext<ContextType>();
}
