import { useCallback, useLayoutEffect, useState } from "react";

function useHasActiveEllipsis(
    ref: React.RefObject<HTMLElement>,
    text?: string
) {
    const [hasActiveEllipsis, setHasActiveEllipsis] = useState(false);

    const checkForEllipsis = useCallback((el: HTMLElement) => {
        return el.offsetHeight < el.scrollHeight;
    }, []);

    useLayoutEffect(() => {
        if (ref.current) {
            setHasActiveEllipsis(checkForEllipsis(ref.current));
        }
    }, [checkForEllipsis, ref, text]);

    return {
        hasActiveEllipsis,
    };
}

export default useHasActiveEllipsis;
