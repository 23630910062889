import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AddressSearch from "../../../../components/inputs/AddressSearch";
import ContactInput from "../../../../components/inputs/ContactInput";
import DateInputOLD from "../../../../components/inputs/DateInputOLD";
import InputOLD from "../../../../components/inputs/InputOLD";
import { DeliveryForm } from "../../schemas";
import "../../style.scss";

type Props = {
    control: Control<DeliveryForm>;
};

function DeliveryInputForm(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="fleet-manual-order-form">
            <p className="text-sm">{t("fleetManualOrder.delivery.title")}</p>

            <Controller
                control={props.control}
                name="orderNumber"
                render={({ field: { value, onChange } }) => (
                    <InputOLD
                        type="text"
                        value={value}
                        onChange={onChange}
                        label={t("createTour.orderNumber") + "*"}
                        placeholder={t("createTour.orderNumberPlaceholder")}
                        width="100%"
                    />
                )}
            />

            <Controller
                control={props.control}
                name="fromAddress"
                render={({ field: { value, onChange } }) => (
                    <AddressSearch
                        value={value}
                        onChange={onChange}
                        label={t("createOrder.fromAddressLabel")}
                        placeholder={t("createOrder.fromAddressPlaceholder")}
                        width="100%"
                        type="pickup"
                        preselectedAddress={value}
                    />
                )}
            />

            <Controller
                control={props.control}
                name="toAddress"
                render={({ field: { value, onChange } }) => (
                    <AddressSearch
                        value={value}
                        onChange={onChange}
                        label={t("createOrder.toAddressLabel")}
                        placeholder={t("createOrder.toAddressPlaceholder")}
                        width="100%"
                        type="dropoff"
                        preselectedAddress={value}
                    />
                )}
            />

            <Controller
                name="date"
                control={props.control}
                render={({ field: { value, onChange } }) => (
                    <DateInputOLD
                        label={t("fleetManualOrder.delivery.dateLabel") + "*"}
                        value={value}
                        onChange={onChange}
                        showAllDates
                        ignoreUnselectableDates
                        width="100%"
                    />
                )}
            />

            <Controller
                name="contactId"
                control={props.control}
                render={({ field: { value, onChange } }) => (
                    <ContactInput
                        value={value}
                        onSelect={(contact) => onChange(contact?.id)}
                        label={
                            t("fleetManualOrder.delivery.contactLabel") + "*"
                        }
                    />
                )}
            />

            <Controller
                name="dropoffPhone"
                control={props.control}
                render={({ field: { value, onChange } }) => (
                    <InputOLD
                        type="tel"
                        value={value}
                        onChange={onChange}
                        placeholder={t("createOrder.phonePlaceholder")}
                        label={t("createOrder.phoneLabel")}
                        width="100%"
                        name="phone"
                    />
                )}
            />
        </div>
    );
}

export default DeliveryInputForm;
