import { useQuery } from "@tanstack/react-query";
import { getActiveOrders } from "../../api/order";

function useFleetPlannerOrders() {
    const {
        data: orders,
        refetch,
        status,
        isFetching,
    } = useQuery({
        queryKey: ["fleet-planner-orders"] as const,
        queryFn: async () => {
            const res = await getActiveOrders({
                page: 1,
                size: 100,
                created_from_fleet_planner: true,
            });
            return res.data;
        },
    });

    switch (status) {
        case "success":
            return {
                orders,
                status,
                refetch,
                isFetching,
            };
        case "pending":
            return {
                orders,
                status,
                refetch,
                isFetching,
            };
        case "error":
            return {
                orders,
                status,
                refetch,
                isFetching,
            };
    }
}

export default useFleetPlannerOrders;
