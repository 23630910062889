import { addMinutes } from "date-fns";
import { StopDraft, StopDraftsTour } from "../types/api";
import { stringToDate } from "./date";
import { getOrderStatus } from "./order";
import { getStopStatus } from "./stop-draft";

export function getTourProgress(tour: StopDraftsTour) {
    const completedStops = tour.stops.filter(
        (stop) => getStopStatus(stop).isCompleted
    ).length;

    const completedSteps = tour.orders.reduce((acc, order) => {
        const orderStatus = getOrderStatus(order);

        switch (orderStatus) {
            case "verified":
                return acc;
            case "at-pickup":
                return acc + 1;
            case "in-progress":
                return acc + 2;
            case "next-stop":
                return acc + 3;
            case "unloading":
                return acc + 4;
            case "fulfilled":
                return acc + 5;
            default:
                return acc;
        }
    }, 0);

    return {
        completedStops,
        progress: completedSteps / (tour.orders.length * 5),
    };
}

export function getNextStop(tour: StopDraftsTour) {
    const nextStop = tour.stops.find(
        (stop) => !getStopStatus(stop).isCompleted
    );

    return nextStop || null;
}

export function getTourStatus(tour: StopDraftsTour) {
    if (tour.stops.every((stop) => !!stop.completed_at)) {
        return "completed";
    }

    if (tour.stops.every((stop) => !stop.order?.pickup_stop_started_at)) {
        return "planned";
    }

    return "active";
}

export function getTourEndDate(stops: StopDraft[]) {
    let endDate: Date | null = null;

    const lastStop = stops[stops.length - 1];

    if (!lastStop) return null;

    if (lastStop.arrived_at || lastStop.eta_internal) {
        const etaDate = stringToDate(
            lastStop.eta_internal || lastStop.arrived_at || ""
        );
        if (etaDate) {
            endDate = addMinutes(
                etaDate,
                lastStop.extrapolated_estimated_offload_time || 0
            );
        }
    }

    if (lastStop.completed_at) {
        endDate = stringToDate(lastStop.completed_at);
    }

    return endDate;
}
