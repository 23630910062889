import { useQuery } from "@tanstack/react-query";
import { getFuelTypes } from "../../api/driver";

function useFuelTypes() {
    const { data: fuelTypes, status } = useQuery({
        queryKey: ["fuel-types"],
        queryFn: async () => {
            const res = await getFuelTypes();
            return res.data;
        },
        staleTime: 1000 * 60 * 60,
    });

    switch (status) {
        case "success":
            return {
                fuleTypes: fuelTypes,
                status,
            };
        case "pending":
            return {
                fuleTypes: fuelTypes,
                status,
            };
        case "error":
            return {
                fuleTypes: fuelTypes,
                status,
            };
    }
}

export default useFuelTypes;
