import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { useCallback, useEffect, useMemo } from "react";
import { StopDraft } from "../../../shared/types/api";
import { KeyString } from "../../../shared/types/internal";
import { STOP_DRAFT_TYPE } from "../../../shared/values/enums";
import StopDraftMarker from "../StopDraftMarker";

type Props = {
    stopDrafts: StopDraft[];
    onSelectStops?: (
        stops: { pickup: StopDraft; dropoff: StopDraft }[]
    ) => void;
    highligtedStops?: {
        pickup: StopDraft;
        dropoff: StopDraft;
    } | null;
    hoverStopIds?: number[];
    selectedStopIds?: number[];
    onMarkerClick?: (stopDraft: StopDraft) => void;
    panTo?: { lat: number; lng: number } | null;
    panBounds?: { lat: number; lng: number }[];
};

function StopDraftMarkers(props: Props) {
    const map = useMap();
    const maps = useMapsLibrary("maps");
    const { onSelectStops } = props;

    const latLngStopCounts = useMemo(() => {
        const latLngCounts: KeyString<number> = {};
        for (let i = 0; i < props.stopDrafts.length; i++) {
            const stop = props.stopDrafts[i];

            if (stop.lat && stop.lng) {
                const key = `${stop.lat},${stop.lng}`;
                latLngCounts[key] = (latLngCounts[key] || 0) + 1;
            }
        }
        return latLngCounts;
    }, [props.stopDrafts]);

    const onMarkerClickHandler = useCallback(
        (stop: StopDraft) => {
            if (!map || !maps) return;
            if (!stop.lat || !stop.lng) return;

            const circle = new maps.Circle({
                center: {
                    lat: +stop.lat,
                    lng: +stop.lng,
                },
                radius: 5,
                map: map,
            });

            circle.setMap(null);

            const bounds = circle.getBounds();

            const stopsInsideBound = props.stopDrafts.filter((sd) =>
                sd.lat && sd.lng
                    ? bounds?.contains({ lat: +sd.lat, lng: +sd.lng })
                    : false
            );

            const groupIdsInsideBound = Array.from(
                new Set(stopsInsideBound.map((sd) => sd.group_id))
            );

            const newSelectedStops: {
                pickup: StopDraft;
                dropoff: StopDraft;
            }[] = [];

            for (let i = 0; i < groupIdsInsideBound.length; i++) {
                const groupId = groupIdsInsideBound[i];

                const pickup = props.stopDrafts.find(
                    (sd) =>
                        sd.group_id === groupId &&
                        sd.stop_type_id === STOP_DRAFT_TYPE.Pickup
                );

                const dropoff = props.stopDrafts.find(
                    (sd) =>
                        sd.group_id === groupId &&
                        sd.stop_type_id === STOP_DRAFT_TYPE.Dropoff
                );

                if (!pickup || !dropoff) continue;

                newSelectedStops.push({ pickup, dropoff });
            }

            onSelectStops?.(newSelectedStops);
        },
        [map, maps, onSelectStops, props.stopDrafts]
    );

    const highlightedStopIds = useMemo(
        () =>
            props.highligtedStops
                ? [
                      props.highligtedStops.pickup.id,
                      props.highligtedStops.dropoff.id,
                  ]
                : [],
        [props.highligtedStops]
    );

    const getMarkerColor = useCallback(
        (stop: StopDraft) => {
            if (highlightedStopIds.includes(stop.id))
                return "var(--text-color-alt)";
            return undefined;
        },
        [highlightedStopIds]
    );

    const getMarkerLabel = useCallback(
        (stopDraft: StopDraft) => {
            const index = props.selectedStopIds?.findIndex(
                (id) => stopDraft.id === id
            );

            return index !== undefined && index !== -1
                ? (index + 1).toString()
                : undefined;
        },
        [props.selectedStopIds]
    );

    useEffect(() => {
        if (!props.panTo || !map) return;

        map.panTo(props.panTo);
    }, [map, props.panTo]);

    useEffect(() => {
        if (!props.panBounds || !props.panBounds.length || !map) return;

        const bounds = new google.maps.LatLngBounds();
        props.panBounds.forEach((point) => bounds.extend(point));

        map.fitBounds(bounds);
    }, [map, maps, props.panBounds]);

    return (
        <>
            {props.stopDrafts.map((stopDraft) => (
                <StopDraftMarker
                    key={stopDraft.id}
                    stopDraft={stopDraft}
                    onClick={() => {
                        props.onMarkerClick?.(stopDraft);
                        onMarkerClickHandler(stopDraft);
                    }}
                    zIndex={
                        highlightedStopIds.includes(stopDraft.id)
                            ? 2
                            : props.hoverStopIds?.includes(stopDraft.id)
                            ? 1
                            : 0
                    }
                    color={getMarkerColor(stopDraft)}
                    amountAtSameLocation={
                        latLngStopCounts[`${stopDraft.lat},${stopDraft.lng}`]
                    }
                    size={
                        highlightedStopIds.includes(stopDraft.id) ||
                        props.hoverStopIds?.includes(stopDraft.id)
                            ? "large"
                            : "medium"
                    }
                    isSelected={props.selectedStopIds?.includes(stopDraft.id)}
                    label={getMarkerLabel(stopDraft)}
                />
            ))}
        </>
    );
}

export default StopDraftMarkers;
