import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";

function useMapTraffic({ hide = false }: { hide?: boolean }) {
    const map = useMap();
    const mapsLibrary = useMapsLibrary("maps");

    const [trafficLayer, setTrafficLayer] =
        useState<google.maps.TrafficLayer | null>(null);

    useEffect(() => {
        if (!map || !mapsLibrary) return;

        const trafficLayer = new mapsLibrary.TrafficLayer({
            autoRefresh: true,
        });
        setTrafficLayer(trafficLayer);
    }, [map, mapsLibrary]);

    useEffect(() => {
        if (!trafficLayer) return;

        if (hide) {
            trafficLayer.setMap(null);
        } else {
            trafficLayer.setMap(map);
        }

        return () => {
            trafficLayer.setMap(null);
        };
    }, [hide, map, trafficLayer]);

    return null;
}

export default useMapTraffic;
