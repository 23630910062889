import { useEffect } from "react";

export function useClickOutside(
    refs: React.RefObject<HTMLElement>[],
    handler: (event: MouseEvent) => void,
    allowScrollbarClick = false
) {
    useEffect(() => {
        function listener(event: MouseEvent) {
            //Kinda hacky solution to prevent the handler from being called when the user clicks on the scrollbar (-15px to account for the scrollbar width)
            if (window.outerWidth - 15 <= event.clientX && allowScrollbarClick)
                return;

            for (let i = 0; i < refs.length; i++) {
                const ref = refs[i];

                if (ref.current?.contains(event.target as Node)) return;
            }

            handler(event);
        }
        document.addEventListener("mousedown", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
        };
    }, [refs, handler, allowScrollbarClick]);
}
