import { isAfter } from "date-fns";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import IconOLD from "../../../components/UI/IconOLD";
import ButtonOLD from "../../../components/buttons/ButtonOLD";
import TextButtonOLD from "../../../components/buttons/TextButtonOLD";
import ColorPicker from "../../../components/inputs/ColorPicker";
import ContactInput from "../../../components/inputs/ContactInput";
import DateInputOLD from "../../../components/inputs/DateInputOLD";
import DropdownOLD from "../../../components/inputs/DropdownOLD";
import RadioGroup from "../../../components/inputs/RadioGroup";
import Modal from "../../../hoc/Modal";
import useStopTypeToShowOptions from "../../../hooks/functionality/useStopTypeToShowOptions";
import { StopDraft } from "../../../shared/types/api";
import { StopDraftsMapFilterOptions } from "../../../shared/types/internal";
import { tourTimes } from "../../../shared/values/dropdown";
import "./style.scss";

type Props = {
    filter: StopDraftsMapFilterOptions;
    onChange: (filter: StopDraftsMapFilterOptions) => void;
    onUpdateDrivers: () => Promise<any>;
    stops: StopDraft[];
};

function StopDraftsMapFilter(props: Props) {
    const { t } = useTranslation();
    const [isUpdatingDrivers, setIsUpdatingDrivers] = useState(false);
    const [modalsOpen, setModalsOpen] = useState<
        | "stop"
        | "date"
        | "time"
        | "drivers"
        | "locations"
        | "color"
        | "contact"
        | "tag"
        | null
    >(null);

    const stopTypeToShowOptions = useStopTypeToShowOptions();

    function changeHandler(key: keyof StopDraftsMapFilterOptions, value: any) {
        props.onChange({ ...props.filter, [key]: value });
    }

    async function updateDriversHandler() {
        setIsUpdatingDrivers(true);
        await props.onUpdateDrivers();
        setIsUpdatingDrivers(false);
    }

    const tagOptions = useMemo(() => {
        const tagSet = new Set<string>();
        for (let i = 0; i < props.stops.length; i++) {
            const stop = props.stops[i];
            if (stop.tag) {
                tagSet.add(stop.tag);
            }
        }

        const tags = Array.from(tagSet).map((tag) => ({
            label: tag,
            value: tag,
        }));

        if (tags.length > 0) {
            tags.unshift({ label: t("createTour.deselectTag"), value: "" });
            tags.unshift({
                label: t("createTour.withoutTag"),
                value: "without",
            });
        }

        return tags;
    }, [props.stops, t]);

    return (
        <div className="stop-drafts-map-filter">
            <Modal
                buttonElement={(ref) => (
                    <button
                        type="button"
                        ref={ref}
                        onClick={(e) => {
                            e.stopPropagation();
                            setModalsOpen((state) =>
                                state === "stop" ? null : "stop"
                            );
                        }}
                        className="stop-drafts-map-filter-button text-sm"
                    >
                        {props.filter.stopTypeToShow !== "both" && (
                            <div className="active-wrapper">
                                <IconOLD
                                    type="check"
                                    style={{
                                        color: "var(--text-color-lighter)",
                                    }}
                                />
                            </div>
                        )}
                        {t("fleetPlanner.stopDraftsMapFilterStopType")}
                    </button>
                )}
                isOpen={modalsOpen === "stop"}
                onClose={() =>
                    setModalsOpen((state) => (state === "stop" ? null : state))
                }
                width="200px"
                align="left"
                modalClass="stop-drafts-map-filter-modal"
            >
                <DropdownOLD
                    value={props.filter.stopTypeToShow}
                    onSelect={({ value }) =>
                        changeHandler("stopTypeToShow", value)
                    }
                    options={stopTypeToShowOptions}
                    labelColor="var(--text-color)"
                />
            </Modal>

            <Modal
                buttonElement={(ref) => (
                    <button
                        type="button"
                        ref={ref}
                        onClick={(e) => {
                            e.stopPropagation();
                            setModalsOpen((state) =>
                                state === "date" ? null : "date"
                            );
                        }}
                        className="stop-drafts-map-filter-button text-sm"
                    >
                        {(props.filter.date?.from || props.filter.date?.to) && (
                            <div className="active-wrapper">
                                <IconOLD
                                    type="check"
                                    style={{
                                        color: "var(--text-color-lighter)",
                                    }}
                                />
                            </div>
                        )}
                        {t("fleetPlanner.stopDraftsMapFilterDate")}
                    </button>
                )}
                isOpen={modalsOpen === "date"}
                onClose={() =>
                    setModalsOpen((state) => (state === "date" ? null : state))
                }
                width="200px"
                align="left"
                modalClass="stop-drafts-map-filter-modal"
            >
                <DateInputOLD
                    onChange={(value) =>
                        changeHandler("date", {
                            from: value,
                            to: props.filter.date?.to || null,
                        })
                    }
                    value={props.filter.date?.from || null}
                    ignoreUnselectableDates
                    showAllDates
                    label={t("fleetPlanner.stopDraftsMapFilterFromDate")}
                />

                {props.filter.date?.from && (
                    <DateInputOLD
                        onChange={(value) =>
                            changeHandler("date", {
                                from: props.filter.date?.from || null,
                                to: value,
                            })
                        }
                        value={props.filter.date?.to || null}
                        ignoreUnselectableDates
                        showAllDates
                        label={t("fleetPlanner.stopDraftsMapFilterToDate")}
                    />
                )}
            </Modal>

            <Modal
                buttonElement={(ref) => (
                    <button
                        type="button"
                        ref={ref}
                        onClick={(e) => {
                            e.stopPropagation();
                            setModalsOpen((state) =>
                                state === "time" ? null : "time"
                            );
                        }}
                        className="stop-drafts-map-filter-button text-sm"
                    >
                        {(props.filter.time?.from || props.filter.time?.to) && (
                            <div className="active-wrapper">
                                <IconOLD
                                    type="check"
                                    style={{
                                        color: "var(--text-color-lighter)",
                                    }}
                                />
                            </div>
                        )}
                        {t("fleetPlanner.stopDraftsMapTime")}
                    </button>
                )}
                isOpen={modalsOpen === "time"}
                onClose={() =>
                    setModalsOpen((state) => (state === "time" ? null : state))
                }
                width="200px"
                align="left"
                modalClass="stop-drafts-map-filter-modal"
            >
                <DropdownOLD
                    label={t("fleetPlanner.stopDraftsMapFilterFromTime")}
                    value={props.filter.time?.from || null}
                    onSelect={({ value }) =>
                        changeHandler("time", {
                            from: value,
                            to: props.filter.time?.to || null,
                        })
                    }
                    options={[
                        {
                            label: t("createTour.deselectTime"),
                            value: "",
                        },

                        ...tourTimes,
                    ]}
                    isOptionDisabled={(option) =>
                        isAfter(
                            new Date(`2000-01-01T${option.value}`),
                            new Date(`2000-01-01T${props.filter.time?.to}`)
                        )
                    }
                    isSearchable
                />
                <DropdownOLD
                    label={t("fleetPlanner.stopDraftsMapFilterToTime")}
                    value={props.filter.time?.to || null}
                    onSelect={({ value }) =>
                        changeHandler("time", {
                            from: props.filter.time?.from || null,
                            to: value,
                        })
                    }
                    isOptionDisabled={(option) =>
                        isAfter(
                            new Date("2000-01-01T" + props.filter.time?.from),
                            new Date("2000-01-01T" + option.value)
                        )
                    }
                    options={[
                        {
                            label: t("createTour.deselectTime"),
                            value: "",
                        },

                        ...tourTimes,
                    ]}
                    isSearchable
                />
            </Modal>

            <Modal
                buttonElement={(ref) => (
                    <button
                        type="button"
                        ref={ref}
                        onClick={(e) => {
                            e.stopPropagation();
                            setModalsOpen((state) =>
                                state === "drivers" ? null : "drivers"
                            );
                        }}
                        className="stop-drafts-map-filter-button text-sm"
                    >
                        {props.filter.showDrivers && (
                            <div className="active-wrapper">
                                <IconOLD
                                    type="check"
                                    style={{
                                        color: "var(--text-color-lighter)",
                                    }}
                                />
                            </div>
                        )}
                        {t("fleetPlanner.stopDraftsMapFilterShowDrivers")}
                    </button>
                )}
                isOpen={modalsOpen === "drivers"}
                onClose={() =>
                    setModalsOpen((state) =>
                        state === "drivers" ? null : state
                    )
                }
                width="200px"
                align="left"
                modalClass="stop-drafts-map-filter-modal"
            >
                <RadioGroup
                    options={[
                        { label: t("general.yes"), value: "true" },
                        { label: t("general.no"), value: "false" },
                    ]}
                    value={props.filter.showDrivers ? "true" : "false"}
                    onChange={(value) =>
                        changeHandler("showDrivers", value === "true")
                    }
                />
            </Modal>
            {props.filter.showDrivers && (
                <ButtonOLD
                    label={t("fleetPlanner.stopDraftsMapFilterUpdateDrivers")}
                    color="secondary"
                    onClick={updateDriversHandler}
                    isLoading={isUpdatingDrivers}
                />
            )}

            <Modal
                buttonElement={(ref) => (
                    <button
                        type="button"
                        ref={ref}
                        onClick={(e) => {
                            e.stopPropagation();
                            setModalsOpen((state) =>
                                state === "locations" ? null : "locations"
                            );
                        }}
                        className="stop-drafts-map-filter-button text-sm"
                    >
                        {props.filter.showLocations && (
                            <div className="active-wrapper">
                                <IconOLD
                                    type="check"
                                    style={{
                                        color: "var(--text-color-lighter)",
                                    }}
                                />
                            </div>
                        )}
                        {t("fleetPlanner.stopDraftsMapFilterShowLocations")}
                    </button>
                )}
                isOpen={modalsOpen === "locations"}
                onClose={() =>
                    setModalsOpen((state) =>
                        state === "locations" ? null : state
                    )
                }
                width="200px"
                align="left"
                modalClass="stop-drafts-map-filter-modal"
            >
                <RadioGroup
                    options={[
                        { label: t("general.yes"), value: "true" },
                        { label: t("general.no"), value: "false" },
                    ]}
                    value={props.filter.showLocations ? "true" : "false"}
                    onChange={(value) =>
                        changeHandler("showLocations", value === "true")
                    }
                />
            </Modal>

            <Modal
                buttonElement={(ref) => (
                    <button
                        type="button"
                        ref={ref}
                        onClick={(e) => {
                            e.stopPropagation();
                            setModalsOpen((state) =>
                                state === "color" ? null : "color"
                            );
                        }}
                        className="stop-drafts-map-filter-button text-sm"
                    >
                        {props.filter.color && (
                            <div className="active-wrapper">
                                <IconOLD
                                    type="check"
                                    style={{
                                        color: "var(--text-color-lighter)",
                                    }}
                                />
                            </div>
                        )}
                        {t("createTour.color")}
                    </button>
                )}
                isOpen={modalsOpen === "color"}
                onClose={() =>
                    setModalsOpen((state) => (state === "color" ? null : state))
                }
                width="200px"
                align="left"
                modalClass="stop-drafts-map-filter-modal"
            >
                <ColorPicker
                    value={
                        props.filter.color === "without"
                            ? null
                            : props.filter.color
                    }
                    onChange={(value) => changeHandler("color", value)}
                    large
                    width="100%"
                    defaultColor="var(--color-pure-white)"
                    labelColor="var(--text-color)"
                />
                <TextButtonOLD
                    label={t("createTour.withoutColor")}
                    onClick={() => changeHandler("color", "without")}
                    color="var(--text-color)"
                />
            </Modal>

            <Modal
                buttonElement={(ref) => (
                    <button
                        type="button"
                        ref={ref}
                        onClick={(e) => {
                            e.stopPropagation();
                            setModalsOpen((state) =>
                                state === "contact" ? null : "contact"
                            );
                        }}
                        className="stop-drafts-map-filter-button text-sm"
                    >
                        {props.filter.contactId && (
                            <div className="active-wrapper">
                                <IconOLD
                                    type="check"
                                    style={{
                                        color: "var(--text-color-lighter)",
                                    }}
                                />
                            </div>
                        )}
                        {t("createTour.contact")}
                    </button>
                )}
                isOpen={modalsOpen === "contact"}
                onClose={() =>
                    setModalsOpen((state) =>
                        state === "contact" ? null : state
                    )
                }
                width="300px"
                align="left"
                modalClass="stop-drafts-map-filter-modal"
            >
                <ContactInput
                    placeholder={t("createTour.contactPlaceholder")}
                    value={props.filter.contactId}
                    onSelect={(c) => changeHandler("contactId", c?.id || null)}
                    noCreate
                    hasClearButton
                />
            </Modal>

            <Modal
                buttonElement={(ref) => (
                    <button
                        type="button"
                        ref={ref}
                        onClick={(e) => {
                            e.stopPropagation();
                            setModalsOpen((state) =>
                                state === "tag" ? null : "tag"
                            );
                        }}
                        className="stop-drafts-map-filter-button text-sm"
                    >
                        {props.filter.tag && (
                            <div className="active-wrapper">
                                <IconOLD
                                    type="check"
                                    style={{
                                        color: "var(--text-color-lighter)",
                                    }}
                                />
                            </div>
                        )}
                        {t("createTour.tag")}
                    </button>
                )}
                isOpen={modalsOpen === "tag"}
                onClose={() =>
                    setModalsOpen((state) => (state === "tag" ? null : state))
                }
                width="250px"
                align="left"
                modalClass="stop-drafts-map-filter-modal"
            >
                <DropdownOLD
                    value={props.filter.tag}
                    onSelect={(optiion) =>
                        changeHandler("tag", optiion?.value || null)
                    }
                    options={tagOptions}
                    noOptionsMessage={t("createTour.noTags")}
                />
            </Modal>

            <button
                type="button"
                onClick={() => {
                    props.onChange({
                        stopTypeToShow: "both",
                        date: null,
                        time: null,
                        showDrivers: false,
                        showLocations: false,
                        color: null,
                        contactId: null,
                        tag: null,
                    });
                }}
                className="stop-drafts-map-filter-button text-sm"
            >
                {t("fleetPlanner.stopDraftsMapFilterReset")}
            </button>
        </div>
    );
}

export default StopDraftsMapFilter;
