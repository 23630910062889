import { useTranslation } from "react-i18next";
import Select, { MenuPlacement, StylesConfig, components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { DropdownOption } from "../../../shared/types/internal";
import Subtitle from "../../UI/Subtitle";
import Tooltip from "../../UI/Tooltip";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import Spinner from "../../UI/Spinner";

type Props = {
    value: string | DropdownOption | null;
    onSelect: (value: DropdownOption) => void;
    options: DropdownOption[];
    isSearchable?: boolean;
    placeholder?: string | null;
    width?: string;
    label?: string | null;
    tooltip?: string | null;
    fixedMenu?: boolean;
    menuPlacement?: MenuPlacement;
    disabled?: boolean;
    isLoading?: boolean;
    labelColor?: string;
    isOptionDisabled?: (option: DropdownOption) => boolean;
    isCompleted?: boolean;
    isCreatable?: boolean;
    onCreate?: (value: string) => void;
    noOptionsMessage?: string;
};

function Dropdown(props: Props) {
    const { t } = useTranslation();

    const CustomDropdownIndicator = (props: any) => (
        <components.DropdownIndicator {...props}>
            <FontAwesomeIcon
                icon={faChevronDown}
                size="lg"
                color="var(--color-neutral-400)"
            />
        </components.DropdownIndicator>
    );

    let value = props.value;

    if (typeof props.value === "string" && props.value !== "") {
        value = props.options.find((o) => o.value === props.value) || null;

        if (!value && props.isCreatable) {
            value = {
                value: props.value,
                label: props.value,
            };
        }
    }

    const styles: StylesConfig<DropdownOption> = {
        container: (base) => ({
            ...base,
            width: props.width || "100%",
            maxWidth: props.width || "100%",
        }),
        menuList: (base) => ({
            ...base,
        }),
        menu: (base) => ({
            ...base,
            zIndex: 100000,
            borderRadius: "8px",
            boxShadow: "var(--shadow)",
            border: "var(--border-primary)",
        }),
        placeholder: (base) => ({
            ...base,
            color: "var(--text-color-light)",
        }),
        control: (base, state) => ({
            ...base,
            color: "var(--color-neutral-600)",
            borderRadius: "8px",
            maxHeight: "10px",
            border: state.isFocused
                ? "var(--border-primary)"
                : props.isCompleted
                ? "var(--border-primary)"
                : "var(--border-primary)",
            "&:hover": {
                border: "var(--border-primary)",
            },
            cursor: "pointer",
            boxShadow: "none",
            backgroundColor: props.isCompleted
                ? "var(--color-primary-50)"
                : "var(--color-pure-white)",
        }),
        option: (base, state) => ({
            ...base,
            margin: "8px",
            borderRadius: "4px",
            width: "auto",
            backgroundColor: state.isFocused
                ? "var(--color-neutral-100)"
                : "transparent",
            color: state.isFocused
                ? "var(--color-neutral-900)"
                : "var(--color-neutral-600)",
            cursor: "pointer",
            ":active": {
                backgroundColor: "transparent",
            },
            opacity: state.isDisabled ? 0.5 : 1,
        }),
    };

    return (
        <label className="dropdown-wrapper">
            {(props.tooltip || props.label) && (
                <div
                    className="dropdown-label"
                    style={{ color: props.labelColor }}
                >
                    {props.tooltip && <Tooltip content={props.tooltip} />}
                    {props.label && (
                        <span className="text-xs">{props.label}</span>
                    )}
                </div>
            )}
            {!props.isCreatable ? (
                <Select
                    value={value as DropdownOption | null}
                    onChange={(o: any) => props.onSelect(o as DropdownOption)}
                    options={props.options as any}
                    isSearchable={!!props.isSearchable}
                    isLoading={props.isLoading}
                    placeholder={props.placeholder || ""}
                    menuPosition={props.fixedMenu ? "fixed" : "absolute"}
                    noOptionsMessage={() =>
                        props.noOptionsMessage ||
                        t("general.dropdown.noResults")
                    }
                    components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: props.isLoading
                            ? null
                            : CustomDropdownIndicator,
                        LoadingIndicator: () => (
                            <Spinner padding="0 10px 0 0" />
                        ),
                    }}
                    menuPlacement={props.menuPlacement}
                    isOptionDisabled={(option) =>
                        !!option.disabled || !!props.isOptionDisabled?.(option)
                    }
                    isDisabled={props.disabled}
                    styles={styles}
                />
            ) : (
                <CreatableSelect
                    value={value as DropdownOption | null}
                    onChange={(o: any) => props.onSelect(o as DropdownOption)}
                    options={props.options as any}
                    isSearchable={true}
                    isLoading={props.isLoading}
                    placeholder={props.placeholder || ""}
                    menuPosition={props.fixedMenu ? "fixed" : "absolute"}
                    noOptionsMessage={() =>
                        props.noOptionsMessage ||
                        t("general.dropdown.noResults")
                    }
                    formatCreateLabel={(inputValue) =>
                        `${t("general.dropdown.create")} ${inputValue}`
                    }
                    components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: props.isLoading
                            ? null
                            : CustomDropdownIndicator,
                        LoadingIndicator: () => (
                            <Spinner padding="0 10px 0 0" />
                        ),
                    }}
                    menuPlacement={props.menuPlacement}
                    isOptionDisabled={(option) =>
                        !!option.disabled || !!props.isOptionDisabled?.(option)
                    }
                    isDisabled={props.disabled}
                    onCreateOption={props.onCreate}
                />
            )}
        </label>
    );
}

export default Dropdown;
