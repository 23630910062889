import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "../../shared/types/api";
import { AuthState } from "../../shared/types/redux";

const initialState: AuthState = {
    user: null,
    hasBadVersion: false,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<User | null>) => {
            state.user = action.payload;
        },
        setHasBadVersion: (state, action: PayloadAction<boolean>) => {
            state.hasBadVersion = action.payload;
        },
    },
});

export const { setUser, setHasBadVersion } = authSlice.actions;

export default authSlice.reducer;
