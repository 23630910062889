import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect, useMemo } from "react";

function usePolylinePath({
    hide = false,
    path,
    polylineColor,
    zIndex,
}: {
    hide?: boolean;
    path: google.maps.LatLngLiteral[];
    polylineColor?: string;
    zIndex?: number;
}) {
    const map = useMap();
    const mapsLibrary = useMapsLibrary("maps");

    const arrowIcon = useMemo(
        () => ({
            path: google.maps.SymbolPath?.FORWARD_OPEN_ARROW || "",
            strokeOpacity: 1,
            scale: 3,
            strokeColor: polylineColor || "#0f0f0f",
        }),
        [polylineColor]
    );

    const polylineOptions = useMemo<google.maps.PolylineOptions>(() => {
        return {
            strokeColor: polylineColor || "#0f0f0f80",
            strokeOpacity: 1,
            strokeWeight: 2,
            icons: [
                {
                    icon: arrowIcon,
                    offset: "50%",
                },
            ],
            zIndex,
        };
    }, [arrowIcon, polylineColor, zIndex]);

    useEffect(() => {
        if (!map || !mapsLibrary || hide) return;

        const polyline = new mapsLibrary.Polyline({
            path,
            map,
            ...polylineOptions,
        });

        return () => polyline.setMap(null);
    }, [hide, map, mapsLibrary, path, polylineOptions]);
}

export default usePolylinePath;
