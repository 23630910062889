import { useTranslation } from "react-i18next";
import ButtonOLD from "../../components/buttons/ButtonOLD";
import DropdownOLD from "../../components/inputs/DropdownOLD";
import InputOLD from "../../components/inputs/InputOLD";
import useOrderSearchOptions from "../../hooks/functionality/useOrderSearchOptions";
import { OrderType } from "../../shared/types/internal";
import "./style.scss";

type Props = {
    searchString: string;
    searchType: string;
    onSearchStringChange: (s: string) => void;
    onSearchTypeChange: (s: string) => void;
    orderType: OrderType;
};

function OrdersSearch(props: Props) {
    const { t } = useTranslation();
    const orderSearchOptions = useOrderSearchOptions(props.orderType);

    return (
        <div className="order-search">
            <InputOLD
                type="text"
                value={props.searchString}
                onChange={props.onSearchStringChange}
                placeholder={t("orderHistory.searchPlaceholder")}
                width="30%"
                icon="search"
            />
            <DropdownOLD
                width="250px"
                placeholder={t("orderHistory.filterBy")}
                value={props.searchType}
                onSelect={(option) => props.onSearchTypeChange(option.value)}
                options={orderSearchOptions}
            />
            <ButtonOLD
                color="secondary"
                onClick={() => props.onSearchStringChange("")}
                label={t("orderHistory.clearFilter")}
                disabled={!props.searchString}
            />
        </div>
    );
}

export default OrdersSearch;
