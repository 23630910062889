import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getMe, resetCache, updateProfile } from "../../api/auth";
import ButtonOLD from "../../components/buttons/ButtonOLD";
import IconButton from "../../components/buttons/IconButton";
import DropdownOLD from "../../components/inputs/DropdownOLD";
import InputOLD from "../../components/inputs/InputOLD";
import LanguageSelector from "../../components/widgets/LanguageSelector";
import { ChangePasswordForm, changePasswordForm } from "../../schemas/auth";
import { ProfileForm, profileForm } from "../../schemas/form";
import memberstack from "../../shared/services/memberstack";
import { ReduxState } from "../../shared/types/redux";
import { onFormError } from "../../shared/utility/misc";
import { formatPhoneNumber } from "../../shared/utility/phone";
import { setUser } from "../../store/slices/auth";
import "./style.scss";

type Props = {};
interface CustomError extends Error {
    code: string;
}

function Settings(props: Props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useSelector((state: ReduxState) => state.auth);
    const queryClient = useQueryClient();

    const [isEditingPassword, setIsEditingPassword] = useState(false);

    const defaultValues = useMemo(() => {
        return {
            firstName: user?.first_name || "",
            lastName: user?.last_name || "",
            phone: user?.customer_phone || "",
            locationId: user?.location_id || null,
        };
    }, [
        user?.customer_phone,
        user?.first_name,
        user?.last_name,
        user?.location_id,
    ]);

    const { handleSubmit, control, reset } = useForm<ProfileForm>({
        resolver: zodResolver(profileForm),
        defaultValues,
    });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    const {
        control: changePasswordControl,
        handleSubmit: handleChangePasswordSubmit,
    } = useForm<ChangePasswordForm>({
        resolver: zodResolver(changePasswordForm),
        defaultValues: {
            oldPassword: "",
            newPassword: "",
        },
    });

    const { mutate: saveInfoHandler, isPending: isSavingInfo } = useMutation({
        mutationFn: async (data: ProfileForm) => {
            await updateProfile({
                firstName: data.firstName,
                lastName: data.lastName,
                phone: formatPhoneNumber(data.phone),
                locationId: data.locationId,
            });
        },
        onSuccess: async () => {
            const res = await getMe();

            dispatch(setUser(res.data));

            queryClient.removeQueries({
                type: "all",
            });

            toast.success(t("successMessage.infoUpdated"));
        },
        onError: () => {
            toast.error(t("errorMessage.updateInfoFailed"));
        },
    });

    const { mutate: savePasswordHandler, isPending: isSavingPassword } =
        useMutation({
            mutationFn: async (data: ChangePasswordForm) => {
                await memberstack.updateMemberAuth(data);
            },
            onSuccess: () => {
                toast.success(t("successMessage.passwordUpdated"));
                setIsEditingPassword(false);
            },
            onError: (error) => {
                console.error(error);
                if ((error as CustomError).code === "invalid-password") {
                    toast.error((error as CustomError).message);
                } else {
                    toast.error(t("errorMessage.updatePasswordFailed"));
                }
            },
        });

    async function logoutHandler() {
        await resetCache();
        await memberstack.logout();
    }

    return (
        <div className="settings">
            <div className="placeholder text-sm">
                <h2 className="text-l-r">Välkommen!</h2>
                <p>
                    Här kommer du snart se dina butiker, kollegor och
                    logistikcenter.
                </p>
                <p>Stay tuned!</p>
            </div>
            <div className="account">
                <header>
                    <span>{t("settings.accountInfo")}</span>
                    <LanguageSelector width="120px" />
                </header>
                <div className="content">
                    <Controller
                        name="firstName"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <InputOLD
                                type="text"
                                value={value}
                                onChange={onChange}
                                width="100%"
                                placeholder={t("settings.firstNamePlaceholder")}
                                label={t("settings.firstNameLabel")}
                            />
                        )}
                    />
                    <Controller
                        name="lastName"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <InputOLD
                                type="text"
                                value={value}
                                onChange={onChange}
                                width="100%"
                                placeholder={t("settings.lastNamePlaceholder")}
                                label={t("settings.lastNameLabel")}
                            />
                        )}
                    />
                    <InputOLD
                        type="email"
                        value={user?.email || ""}
                        onChange={() => null}
                        width="100%"
                        placeholder={t("settings.emailPlaceholder")}
                        label={t("settings.emailLabel")}
                        tooltip={t("settings.emailTooltip")}
                        disabled
                    />
                    <InputOLD
                        type="text"
                        value={user?.company_entity?.name || ""}
                        onChange={() => null}
                        width="100%"
                        placeholder={t("settings.companyPlaceholder")}
                        label={t("settings.companyLabel")}
                        tooltip={t("settings.companyTooltip")}
                        disabled
                    />
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <InputOLD
                                type="text"
                                value={value}
                                onChange={onChange}
                                width="100%"
                                placeholder={t("settings.phonePlaceholder")}
                                label={t("settings.phoneLabel")}
                            />
                        )}
                    />
                    <Controller
                        name="locationId"
                        control={control}
                        disabled={!!user?.company_entity?.hidden_locations}
                        render={({ field: { value, onChange } }) => (
                            <DropdownOLD
                                label={t("settings.locationLabel")}
                                value={value?.toString() || ""}
                                placeholder=""
                                onSelect={({ value }) => onChange(+value)}
                                disabled={
                                    !!user?.company_entity?.hidden_locations
                                }
                                options={
                                    user?.company_entity?.locations
                                        .map((l) => ({
                                            label: l.name,
                                            value: l.id.toString(),
                                        }))
                                        .sort((a, b) =>
                                            a.label.localeCompare(b.label)
                                        ) || []
                                }
                                isSearchable
                            />
                        )}
                    />

                    {isEditingPassword ? (
                        <>
                            <Controller
                                name="oldPassword"
                                control={changePasswordControl}
                                render={({ field: { value, onChange } }) => (
                                    <InputOLD
                                        type="password"
                                        value={value}
                                        onChange={onChange}
                                        width="100%"
                                        placeholder={t(
                                            "settings.oldPasswordPlaceholder"
                                        )}
                                        label={t("settings.oldPasswordLabel")}
                                        autoComplete="new-password"
                                    />
                                )}
                            />
                            <Controller
                                name="newPassword"
                                control={changePasswordControl}
                                render={({ field: { value, onChange } }) => (
                                    <InputOLD
                                        type="password"
                                        value={value}
                                        onChange={onChange}
                                        width="100%"
                                        placeholder={t(
                                            "settings.newPasswordPlaceholder"
                                        )}
                                        label={t("settings.newPasswordLabel")}
                                        autoComplete="new-password"
                                    />
                                )}
                            />
                        </>
                    ) : (
                        <InputOLD
                            type="password"
                            value="********"
                            onChange={() => null}
                            width="100%"
                            label={t("settings.passwordLabel")}
                            placeholder={t("settings.passwordPlaceholder")}
                            disabled
                            autoComplete="current-password"
                        />
                    )}
                    <div className="submit-wrapper">
                        {isEditingPassword ? (
                            <ButtonOLD
                                label={t("settings.savePasswordLabel")}
                                color="secondary"
                                onClick={handleChangePasswordSubmit(
                                    (data) => savePasswordHandler(data),
                                    onFormError
                                )}
                                isLoading={isSavingPassword}
                            />
                        ) : (
                            <ButtonOLD
                                label={t("settings.changePasswordLabel")}
                                color="primary"
                                onClick={() => setIsEditingPassword(true)}
                            />
                        )}
                        <ButtonOLD
                            label={t("settings.saveLabel")}
                            color="secondary"
                            isLoading={isSavingInfo}
                            onClick={handleSubmit(
                                (data) => saveInfoHandler(data),
                                onFormError
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="logout-wrapper">
                <IconButton
                    icon={"logout"}
                    label={t("settings.logout")}
                    style={{
                        background: "transparent",
                        color: "var(--text-color)",
                        marginRight: "-10px",
                    }}
                    labelColor="var(--text-color)"
                    onClick={logoutHandler}
                />
            </div>
        </div>
    );
}

export default Settings;
